<app-menu-common url="sales"></app-menu-common>

<div class="pageheader">
	<div class="breadcrumb" style="display: flex; justify-content: space-between;">
		<span class="mat-headline-1 grey" >Tarif Ventes / </span><span class="mat-headline-1 grey">#{{id}}</span>
	</div>
</div>	

<div class="pagecontent" *ngIf="dataSource?.length">	
	<table>
		<ng-container *ngFor="let l of dataSource; let i = index">
			<tr>
				<td>
					{{l.lnk_product.p_name}}
				</td>
				<td>
					{{l.purchaseprice}}
				</td>
				<td>
					{{l.strategy}}
				</td>						
				<td>
					{{l.param}}
				</td>				
				<td>
					{{l.price}}
				</td>				
			</tr>
		</ng-container>
	</table>	
</div>	
