// Common
export const jsonheader = { 'content-type': 'application/json' };
export const iso8601format = 'YYYY-MM-DD';
// Sales
// Orders
// Order State
export const SALES_ORDER_STATE_DELETED = 0;
export const SALES_ORDER_STATE_CREATED = 1;
export const SALES_ORDER_STATE_QUOTE = 2;
export const SALES_ORDER_STATE_PREPARATION = 3;
export const SALES_ORDER_STATE_DELIVERED = 4;
export const SALES_ORDER_STATE_INVOICED = 5;
export const SALES_ORDER_STATE_PAYED = 6;

export const sales_order_state = new Map();	// status: 0: DELETED, 1: In progress, 2: Valid, 3: Delivered, 4: Invoiced, 5: Payed
sales_order_state.set(SALES_ORDER_STATE_DELETED, "Supprimé");
sales_order_state.set(SALES_ORDER_STATE_CREATED, "En création");
sales_order_state.set(SALES_ORDER_STATE_QUOTE, "Devis");
sales_order_state.set(SALES_ORDER_STATE_PREPARATION, "En préparation");
sales_order_state.set(SALES_ORDER_STATE_DELIVERED, "Livré");
sales_order_state.set(SALES_ORDER_STATE_INVOICED, "Facturé");
sales_order_state.set(SALES_ORDER_STATE_PAYED, "Soldé");

export const SALES_ORDER_MODE_TAKE_AWAY = 1;
export const SALES_ORDER_MODE_SHOP_PICK_UP = 2;
export const SALES_ORDER_MODE_ASAP_DELIVERY = 3;
export const SALES_ORDER_MODE_DATE_DELIVERY = 4;
export const sales_order_mode = new Map();	// mode
sales_order_mode.set(SALES_ORDER_MODE_TAKE_AWAY, "Emportée");
sales_order_mode.set(SALES_ORDER_MODE_SHOP_PICK_UP, "Retrait magasin");
sales_order_mode.set(SALES_ORDER_MODE_ASAP_DELIVERY, "Livraison dès que possible");
sales_order_mode.set(SALES_ORDER_MODE_DATE_DELIVERY, "Livraison programmée");

export const EVENTTYP_INSERT = 1;
export const EVENTTYP_UPDATE = 2;
export const EVENTTYP_DELETE = 3;
export const EVENTTYP_PRINT = 4;

export const EVENTTYP_CHANGEORDERSTATE = 100;
export const EVENTTYP_INVOICING = 101;            // Process invoicing
export const EVENTTYP_USER_AUTHENTICATE = 102;    // User authentication request

export const OBJECTTYP_PARTNER = 1;
export const OBJECTTYP_QUOTATION = 2;
export const OBJECTTYP_ORDER = 3;
export const OBJECTTYP_DELIVERY = 4;
export const OBJECTTYP_INVOICE = 5;
export const OBJECTTYP_PRODUCT = 6;
export const OBJECTTYP_TEMPLATE = 7;
export const OBJECTTYP_USER = 8;
export const OBJECTTYP_PINVCL = 9;			// Physical Inventory Line
export const OBJECTTYP_HCCARD = 10;			// Health Care Card

// C_VALUES
export const C_VALUES_UOM = 1;
export const C_VALUES_PARTNER_ROLE = 2;
export const C_VALUES_SELLING_PRICE_STRATEGY = 3;
export const C_VALUES_VAT_TYP = 4;
export const C_VALUES_CUSTOMER_ACT = 5;
export const C_OBJECT_TYPE = 6;
export const C_PAYMENT_METHOD = 7;
export const C_VALUES_DELIVERY_SLOT = 8;
export const C_VALUES_DELIVERY_OPERATOR = 9;
export const C_VALUES_DELIVERY_MODE = 10;

export const C_VALUES_FSE_QUALITY = 1000;		// FSE Feature
