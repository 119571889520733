
<ng-container [formGroup]="formGroup" formGroupName="lnk_delivery">
		<mat-form-field>
			<mat-select [formControlName]="targetControlName" (ngModelChange)="valueChanged($event)">
				<mat-option *ngFor="let option of options" [value]="option.id">
					{{ option.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	<br/>{{ formGroup.value.lnk_delivery | json}}
</ng-container>