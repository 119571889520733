import { Component, Input, Injectable } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, ControlContainer, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';

import { ValuesService } from '@app/common/values/values.service';
import { ProductService } from '@app/sales/product/product.service';
import { ProductPackagingVal } from '@app/shared/_models/product';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    selector: 'app-variantpackaging',
    templateUrl: './variantpackaging.component.html',
    styleUrls: ['./variantpackaging.component.scss', '../../../styles-custom.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, MatCheckboxModule, MatButtonModule, MatIconModule, MatDividerModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})

export class VariantpackagingComponent {
	@Input() template = 0;
	@Input() product = 0;

	public id!: number;
	public salesUnit: any;
	public selectedSalesUnit: any;
	public gformGroup: any;
	public templatepackagings!: any;

	public newbarcode!: FormControl;
	public newpackaging!: FormControl;

	constructor(private route: ActivatedRoute, private location: Location, private productService: ProductService, private fb: FormBuilder, private valuesService: ValuesService, public controlContainer: ControlContainer, private router: Router) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => {
    		return false;
  		};
	 }
	ngOnInit(): void {
		this.gformGroup = this.controlContainer.control;	// formGroup comes from parent
		this.productService.getPackagingValsByProductAsFormArray(this.product)
			.subscribe((data: FormArray) => {
				this.gformGroup.addControl('lnk_packagings', data);
				//this.gformGroup.addControl('newpackaging', '');
			})

		this.productService.getPackagingsByTemplate(this.template)
			.subscribe((data: any) => {
				this.templatepackagings = data;
			});

		this.productService.getPackagingsByTemplateAsFormArray(this.template)
			.subscribe((data: FormArray) => {
				this.gformGroup.addControl('templatePackagings', data);
			});

		this.newbarcode = this.fb.control('');
		this.newpackaging = this.fb.control('');
	};

	post_packagingval(): void {
		let packaging = + this.newpackaging.value;
		if (!isNaN(packaging)) {
			let productPackagingVal: ProductPackagingVal = {
				product: this.product,
				packaging: packaging,
				barcode: this.newbarcode.value,
			}
			this.productService.postPackagingVal(productPackagingVal)
				.subscribe((response: any) => {
					console.log("Server response: " + JSON.stringify(response));
					this.router.navigateByUrl('sales/variant/' + this.template + '/' + this.product + '?tab=1');
				});
		} else {	// Should NOT happen, packaging is comming from a listbox
			
		}
	}

	delete_packagingval(a:AbstractControl): void {
		console.log("AbstractControl id: " + a.get("id")?.value + ", packaging: " + a.get("packaging")?.value);
		this.productService.deletePackagingVal(a.get("id")?.value, a.get("packaging")?.value, this.product)
			.subscribe((response: any) => {
				console.log("Server response: " + JSON.stringify(response));
				this.router.navigateByUrl('sales/variant/' + this.template + '/' + this.product + '?tab=1');
			});
	}
		
	get templatePackagings(): FormArray {
		return this.gformGroup.get('templatePackagings') as FormArray;
	}

	get lnk_packagings(): FormArray {
		return this.gformGroup.get('lnk_packagings') as FormArray;
	}

	get packagingsControl(): FormGroup[] {		// This is used to comply to TS type checking on formGroup
		if (this.gformGroup && this.gformGroup.controls["lnk_packagings"]) {
			return (this.gformGroup.controls["lnk_packagings"] as FormArray).controls as FormGroup[];
		}
		else return [this.fb.group({})];
	}
}
