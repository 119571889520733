import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
	name: 'fc',
	standalone: true
})
export class FormatCurrencyPipe implements PipeTransform {

	transform(value: any, ...args: unknown[]): string {
		if (value !== undefined && value !== null && Number(value) == value) {
			value = formatNumber(value, 'fr', '1.2-2');	// First apply fr format => add thousand separator
			return value.replace(/,/g, ".");						// Use comma (.) as decimal point
		} else {
			return "NaN";
		}
	}

}
