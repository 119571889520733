import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, merge } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Event } from '@app/shared/_models/common';

import { Pinvc, PinvcL } from '@app/shared/_models/stock';

import * as constants from "@app/shared/constants";
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class PinvcsService {
	constructor(private http: HttpClient) { }

	getPinvcsByFilter(typ: number): Observable<any> {
		return this.http.get<void>(environment.server_url + "/stock/pinvcs?typ=" + typ);
	}

	getPinvcByIdZone(id: number, zone: number): Observable<any> {
		return this.http.get<void>(environment.server_url + `/stock/pinvc/${id}?zone=${zone}`);
	}
	
	getZonesByStore(store: number): Observable<any> {
		return this.http.get<void>(environment.server_url + "/stock/zones?store=" + store);
	}	
	
	getPinvcAsFormGroup(id: number, zone: number): Observable<FormGroup> {
		return this.getPinvcByIdZone(id, zone).pipe(
			map ((pinvc:Pinvc) => {
				return Pinvc.asFormGroup(pinvc);
			}))
	}
	
	postPinvcL(eventtyp: number, pinvcL: PinvcL): Observable<any> {
		// let data = { "product": product, "user": user, "qty": qty };
		let event: Event = {
			id: 0,
			typ: eventtyp,			// 1: Insert, 2: Update, 3: Delete
			objectid: 0,
			objecttyp: constants.OBJECTTYP_PINVCL,		// Physical Inventory Line
			data: pinvcL,
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}	
	
	deletePinvcL(eventtyp: number, pinvcL: PinvcL): Observable<any> {
		// let data = { "product": product, "user": user, "qty": qty };
		let event: Event = {
			id: 0,
			typ: eventtyp,			// 1: Insert, 2: Update, 3: Delete
			objectid: 0,
			objecttyp: constants.OBJECTTYP_PINVCL,		// Physical Inventory Line
			data: pinvcL,
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}		
}