<app-menu-common url="sales" *ngIf="!dialogData"></app-menu-common>
<mat-dialog-actions align="end" *ngIf="dialogData">
	<button mat-button [mat-dialog-close]=""><mat-icon>close</mat-icon></button>
</mat-dialog-actions>

<mat-dialog-content>
<div class="pageheader flexcolumn" (keydown)="onKeydown($event)">
  <div class="flexgrid" >
  	<section class="col1">
			<div class="pagetitle grey" >Recherche Produits</div>
			<button mat-flat-button color="primary" type="button"  style="margin-top: 8px;" [routerLink] = "['/sales/product']">Nouveau Produit</button>
		</section>
  	<section class="col2">
			<mat-form-field id="nameinputgroup">
			  <input  #searchNameInput matInput [(ngModel)]="searchname" placeholder="Rechercher par nom / Id"  autofocus [matAutocomplete]="nameauto" >
			  <button *ngIf="searchname" matSuffix mat-icon-button aria-label="Clear" (click)="searchname=''">
			    <mat-icon>close</mat-icon>
			  </button>	  
		      <mat-autocomplete #nameauto="matAutocomplete">
		        <mat-option  *ngFor="let option of nameApiResponse" [value]="option">
		          {{option}}
				</mat-option>
			  </mat-autocomplete>
			  <mat-hint align="start">{{ bcApiResponse }}</mat-hint>
			</mat-form-field>
		</section>
		<section class="col3">
			<mat-form-field id="manufinputgroup">
			  <input matNativeControl #searchManufInput matInput [(ngModel)]="searchmanuf" placeholder="Rechercher par fabricant" [matAutocomplete]="manufauto" >
			  <button *ngIf="searchmanuf" matSuffix mat-icon-button aria-label="Clear" (click)="searchmanuf=''">
			    <mat-icon>close</mat-icon>
			  </button>		  
		      <mat-autocomplete #manufauto="matAutocomplete" [displayWith]="displayFn">
		        <mat-option  *ngFor="let option of manufApiResponse" [value]="option">
		          {{option.name}}
				</mat-option>
			  </mat-autocomplete>
			</mat-form-field>    		
		</section>
  </div>	
  <div class="searchline">
    <span class="spacer"></span>
    <button class="center largebutton" mat-flat-button color="primary" aria-label="Rechercher" (click)="onSearch()">
   	<mat-icon>search</mat-icon>
    </button>
    &nbsp;&nbsp;
    <button class="center largebutton" mat-flat-button color="basic" aria-label="Effacer" (click)="onCancel()">
   <mat-icon>clear</mat-icon>
    </button>      
    <span class="spacer"></span>
  </div>
</div>

		<div class="pagecontent" *ngIf="dataSource" >
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">
	
			  <!-- id Column -->
			  <ng-container matColumnDef="id">
			    <th mat-header-cell *matHeaderCellDef> # </th>
			    <td mat-cell *matCellDef="let p"> {{ (p.lnk_products.length===1 ? p.lnk_products[0].id : '') }}</td>
			  </ng-container>

			  <ng-container matColumnDef="manuf">
			    <th mat-header-cell *matHeaderCellDef> Fab. </th>
			    <td mat-cell *matCellDef="let p" > {{ p?.lnk_partner_manuf?.name }}</td>
			  </ng-container>
			  			  
			  <!-- Name column -->
			  <ng-container matColumnDef="name">
			    <th mat-header-cell *matHeaderCellDef> Nom </th>
			    <td mat-cell *matCellDef="let p" [class.bold] = "p.lnk_products.length>1"> {{ p.name }}</td>
			  </ng-container>
			  
			  <ng-container matColumnDef="price">
			    <th mat-header-cell *matHeaderCellDef> PV. TTC </th>
			    <td mat-cell *matCellDef="let p" > {{ p.lnk_products[0]?.price_taxi }}</td>
			  </ng-container>				  			  
			  
			  <ng-container matColumnDef="secondary" >	<!-- Inner table for variants of a generic -->
			    <td mat-cell *matCellDef="let tpl" [attr.colspan]="displayedColumns.length" class="secondary-column"> 
		 		  <table mat-table [dataSource]="tpl.lnk_products" *ngIf="tpl.lnk_products.length>1">
		            <ng-container matColumnDef="pid" >
		              <td mat-cell *matCellDef="let p"> {{ p.id }}</td>
		            </ng-container>
		            <ng-container matColumnDef="pname" >
		              <td mat-cell *matCellDef="let p"> {{ p.name }}</td>
		            </ng-container>
		            <ng-container matColumnDef="attributestring" >
		              <td mat-cell *matCellDef="let p"> {{ p.attributestring }}</td>
		            </ng-container>
		            <ng-container matColumnDef="price_taxi" >
		              <td mat-cell *matCellDef="let p"> {{ p.price_taxi }}</td>
		            </ng-container>		            		            
		            <tr mat-row *matRowDef="let row; columns: ['pid', 'pname', 'attributestring', 'price_taxi']" (click)="onInnerClickRow(row, $event)"></tr>
		          </table>
          		</td>
			  </ng-container>			  
		
			  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClickRow(row, $event)" ></tr> <!-- [style.height.px]="20" -->
			  <tr mat-row *matRowDef="let row; columns: ['secondary'];" class="secondary-row"></tr>
	  	</table>
		</div>
</mat-dialog-content>
