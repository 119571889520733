import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location, NgIf } from '@angular/common';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subject, BehaviorSubject } from 'rxjs';
import { ProductService } from '@app/sales/product/product.service';
import { CommonService } from '@app/common/common.service';
import { SpinnerService } from '@app/shared/spinner.service';
import * as constants from "@app/shared/constants";
import { ProductpackagingComponent } from './productpackaging/productpackaging.component';
import { AttributesComponent } from './attributes/attributes.component';
import { GeneralComponent } from './general/general.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { VariantsButtonComponent } from '../../shared/action-bar/variants-button/variants-button.component';
import { ProductButtonComponent } from '../../shared/action-bar/product-button/product-button.component';
import { ActionBarComponent } from '@app/shared/action-bar/action-bar.component';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, NgIf, FormsModule, ReactiveFormsModule, ActionBarComponent, VariantsButtonComponent, ProductButtonComponent, MatFormFieldModule, MatInputModule, MatTabsModule, GeneralComponent, AttributesComponent, ProductpackagingComponent]
})
export class ProductComponent {
	constructor(private route: ActivatedRoute, private location: Location, private commonService: CommonService, private productService: ProductService, private router: Router, private fb: FormBuilder, private spinner: SpinnerService) { }

	private routerevents$:any;
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public template_id:number = 0;
	public formGroup!: FormGroup;
	public oldformGroup!: FormGroup;	// Initial values to store in event 
	public eventtyp:number = constants.EVENTTYP_INSERT;			// Insert event by default
	
	loadEventsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);	// Warning. BehaviorSubject needs to emit an initial value. A flag indicated a true reloading

	ngOnInit(): void {
		this.template_id = Number(this.route.snapshot.paramMap.get('id'));
		
		if (this.template_id === 0) {	// New template (no URL param)
			console.log("New template. template_id: {}", this.template_id);
			this.formGroup = this.fb.group({
				id: new FormControl<number>(+0),
				name: new FormControl<String>(""),
				manuf: new FormControl<number|undefined>(undefined),
				baseuom: new FormControl<any>(undefined),
				unitpriceuom: new FormControl<any>(undefined),
				vat: new FormControl<number>(+1),
				has_variants: new FormControl<boolean>(false),
				unique_variant: new FormControl<number|undefined>(undefined),
			});
			this.oldformGroup = this.fb.group({
				id: new FormControl<number>(+0),
				name: new FormControl<String>(""),
				manuf: new FormControl<number|undefined>(undefined),
				baseuom: new FormControl<any>(undefined),
				unitpriceuom: new FormControl<any>(undefined),
				vat: new FormControl<number>(+1),
				has_variants: new FormControl<boolean>(false),
				unique_variant: new FormControl<number|undefined>(undefined),
			});			
		} else {	// Load existing template
			console.log("Load existing template");
			this.loadTemplate();
		}
		
		this.routerevents$ = this.router.events.subscribe((event) => {
		    if (event instanceof NavigationEnd && this.template_id !==0) {	// Real reload, not a F5 on same page
		    	console.log("NavigationEnd, reloading");
		    	this.loadTemplate();
		    }
		  });			
	}
	
	private loadTemplate() {
		this.spinner.show();
		this.eventtyp = constants.EVENTTYP_UPDATE; 										// Update mode. Will issue an update event
		this.productService.getTemplateAsFormGroup(this.template_id)
			.subscribe((data: FormGroup) => {
				if (this.formGroup) {			// After submit, formgroup exists => patch
					this.formGroup.patchValue(data);
					console.log("Patching data: " + JSON.stringify(data.getRawValue()));
				} else {	// First formgroup setting. No previous value
					this.formGroup = data;
					console.log("[loadTemplate] data: " + JSON.stringify(data.getRawValue()));
				}
				this.oldformGroup = data;						// Save loaded value
				this.loadEventsSubject.next(true);	// Emit a load event to child component
				this.spinner.hide();
			});
	}

	// TODO Why do we use same call as variant? FIXME old_formgroup param
	public onSubmit(e:any) {
		console.log("OnSubmit");
		this.productService.postProductEvent(this.eventtyp, constants.OBJECTTYP_TEMPLATE, this.oldformGroup, this.formGroup).subscribe((response: any) => {
			console.log("Server response: " + JSON.stringify(response));
			this.router.navigate(['/sales/product/' + response.data.template_id]);	// Reload Template
		});				
	}
	
	public updateVariants(hasVariants:boolean) {
		this.formGroup.get("has_variants")?.patchValue(hasVariants);
	}	
}
