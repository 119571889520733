<app-menu-common url="sales"></app-menu-common>

<div class="flexrow buttonbar">
	<button mat-flat-button color="primary" type="button" aria-label="Facturer" (click)="onInvoiceAllClick()">Facturer</button>
</div>

<div class="pagecontent flexrow" *ngIf="dataSource">
	<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
		<ng-container matColumnDef="id_order">
			<th mat-header-cell *matHeaderCellDef>Commande</th>
			<td mat-cell *matCellDef="let p">{{ p.id_order }}</td>
		</ng-container>
		<ng-container matColumnDef="assure">
			<th mat-header-cell *matHeaderCellDef>Assuré</th>
			<td mat-cell *matCellDef="let p">{{ p.data.empreinteCV.numSecu }}</td>
		</ng-container>		
		<ng-container matColumnDef="benef">
			<th mat-header-cell *matHeaderCellDef>Bénéficiaire</th>
			<td mat-cell *matCellDef="let p">{{ p.data.empreinteCV.beneficiaire[p.data.activeRecipientChecked].prenom }}&nbsp;{{ p.data.empreinteCV.beneficiaire[p.data.activeRecipientChecked].nomUsuel }}&nbsp;-&nbsp;{{ p.data.empreinteCV.beneficiaire[p.data.activeRecipientChecked].dateNaissance }}</td>
		</ng-container>		
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" (click)="onInvoiceClick(row, $event)" class="invoicerow"></tr>
	</table>
</div>