import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as constants from "@app/shared/constants";
import { ValuesService } from '@app/common/values/values.service';
import { DatePipe, JsonPipe } from '@angular/common';


@Component({
  selector: 'app-thumb-delivery-type',
  standalone: true,
  imports: [JsonPipe],
  templateUrl: './thumb-delivery-type.component.html',
  styleUrl: './thumb-delivery-type.component.scss'
})
export class ThumbDeliveryTypeComponent implements OnInit{

  @Input() formGroup! : FormGroup  ;
  public deliveryModes: any = null;
  private deliverySlots: any = null;
  
  constructor (private valuesService: ValuesService) {}
  
  ngOnInit(): void {
    this.valuesService.getValues$(constants.C_VALUES_DELIVERY_MODE) // Get Delivery modes
      .subscribe((data: any) => { this.deliveryModes = data;  })

    this.valuesService.getValues$(constants.C_VALUES_DELIVERY_SLOT) // Get Delivery slots & add default value
      .subscribe((data: any) => { this.deliverySlots = data;  })
	}

  getModeDelivery(): number {	// ?? Could be simpler
    if ((this.formGroup.get("lnk_delivery")?.get("mode")?.value??0)!==0) {
      return this.formGroup.get("lnk_delivery")?.get("mode")?.value
    }
    return 0;
   }

  getAskdate():string {
    // console.log('this.formGroup.get("lnk_delivery")?.get("mode")?.value',this.formGroup.get("lnk_delivery")?.get("mode")?.value);
    if (this.formGroup.get("lnk_delivery")?.get("mode")?.value && this.formGroup.get("lnk_delivery")?.get("mode")?.value < 4){
      return this.deliveryModes.find((deliveryType : any) => { deliveryType.id == this.formGroup.get("lnk_delivery")?.get("mode")?.value.toString(); })?.name;	// TODO could crash if not found
    } else{
      return this.getAskDeliveryDateAndSlot(this.formGroup.get("lnk_delivery")); // ?? mode >= 4 ?? OR no mode OR no value (WRONG)
    }
  }

  getAskDeliveryDateAndSlot(formControl_lnkDelivery: AbstractControl <any, any> | null): string {
    let retour ='';
    
    if(formControl_lnkDelivery?.get('mode')?.value === 4 && formControl_lnkDelivery?.get('askslot')?.value >0  && formControl_lnkDelivery?.get('askdatdel')?.value){
        retour ='Livraison : ';
        retour += new DatePipe  (navigator.language || 'fr-FR').transform(formControl_lnkDelivery?.get('askdatdel')?.value,'dd/MM') +' '
        
        if (+formControl_lnkDelivery?.get('askslot')?.value <3 && +formControl_lnkDelivery?.get('askslotoperator')?.value <4) {   // morning or afternoon case
            if(+formControl_lnkDelivery?.get('askslotoperator')?.value >0){ // debut milieu fin case
                 switch (+formControl_lnkDelivery?.get('askslotoperator')?.value ) {
                    case 1 : retour +='début ' ; break;
                    case 2 : retour +='milieu '; break;
                    case 3 : retour +='fin '; break;
                }
            }
            switch (+formControl_lnkDelivery?.get('askslot')?.value ) {
                case 1 : retour +='Matinée' ; break;
                case 2 : retour +='Après Midi'; break;
            } 
            return retour;

        } else  if (+formControl_lnkDelivery?.get('askslot')?.value >2  &&  +formControl_lnkDelivery?.get('askslotoperator')?.value >3  ){  // slot is an hour we need operator
             
                switch (+formControl_lnkDelivery?.get('askslotoperator')?.value ) {
                    case 4 : retour +='Avant  ' ; break;
                    case 5 : retour +='Après '; break;
                    case 6 : retour +='Heure précise '; break;
                }
            
            // retour += this.slots.find(slot => slot.id ===formControl_lnkDelivery?.get('askslot')?.value ).name 
            retour += this.deliverySlots.find((slot : any)=> slot.id === formControl_lnkDelivery?.get('askslot')?.value  ).name 
            // return this.deliveryType.get(this.formGroup.get("lnk_delivery")?.get("mode")?.value)
            return retour;
        } 
    } 
    return 'Information Livraison incomplètes';
   }
   
  getClassModeDelivery():string {
    switch (this.formGroup.get("lnk_delivery")?.get("mode")?.value ) {
      case 1:
        return 'takeaway'
      case 2:
        return 'pickup'
      case 3:
        return 'deliveryAsap'
      case 4:
        if(this.getAskdate() ==='Information Livraison incomplètes' ){
          return 'deliveryInfoNotCompleted'
        }else {
          return 'delivery'
        }	
      default:
        return '';
    }
  }   
 }
  