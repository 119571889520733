import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SalesService } from '../sales.service';
import { NgIf, NgFor } from '@angular/common';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
    selector: 'app-sales-pricelist',
    templateUrl: './sales-pricelist.component.html',
    styleUrls: ['./sales-pricelist.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, NgIf, NgFor]
})
export class SalesPricelistComponent {
	constructor(private salesService: SalesService, private router: Router, private route: ActivatedRoute) { }
	
	public id: number | undefined = undefined;	// Productlist id
	public dataSource: any = undefined;
	
	ngOnInit(): void {
		this.id = Number(this.route.snapshot.paramMap.get('id'));

		this.salesService.getPricelist(this.id)
			.subscribe((data: any) => {
				this.dataSource = data;
			});
	}
}
