<form class="tabcontent" [formGroup]="formGroup">
	<div class="nolines" *ngIf="!addresses.length">Aucune adresse</div>
	<ng-container formArrayName="addresses">
		<div class="inputzone greenback" [class.greyback]="$any(line).get('action').value==='DELETE'" contentEditable tabindex="0" *ngFor="let line of addresses.controls; let i=index" >
			<section class="flexrow">
				<label for="istreet1" class="label">Appart./&Eacute;tage/Esc..</label>
				<input type="text" id="istreet1" [formControl]="$any(line).get('street1')" autocomplete="address-line1">
				<span class="spacer"></span>
				<span>
					<input id="isdefault" type="radio" class="cb-default" value=i [checked]="checked_line==i" (click)="checkLine(i)" />
					<label for="isdefault">Par défaut</label>
				</span>
			</section>
			<section>	
				<label for="istreet2" class="label">Entrée/Bât./Résidence</label>
				<input type="text" id="istreet2" [formControl]="$any(line).get('street2')" autocomplete="address-line1">
			</section>
			<section>	
				<label for="istreet3" class="label">N° et Rue</label>
				<div class="row">
					<input type="text" id="istreet3" [formControl]="$any(line).get('street3')" autocomplete="address-line2" (blur)="setAction(i)">
					<label for="icp">Code postal</label>
					<input type="text" id="icp" [formControl]="$any(line).get('zip')" autocomplete="postal-code">
					<label for="icity">Ville</label>
					<input type="text" id="icity" [formControl]="$any(line).get('city')" autocomplete="address-level2">
				</div>
			</section>
			<section class="flexrow">
				<label for="icountry" class="label">Pays</label>
				<input type="text" id="icountry" value="France"  autocomplete="country">
				<span class="spacer"></span>
				<button type="button" mat-icon-button color="primary" matTooltip="Supprimer" aria-label="Supprimer" (click)="deleteLine(i)"><mat-icon>delete</mat-icon></button>
			</section>
		</div>		
	</ng-container>
</form>	

					<div class="inputzone">
						<section>
							<label for="istreet1" class="label">Appart./&Eacute;tage/Esc..</label>
							<input type="text" id="istreet1" [(ngModel)] = "istreet1" autocomplete="address-line1">
						</section>
						<section>	
							<label for="istreet2" class="label">Entrée/Bât./Résidence</label>
							<input type="text" id="istreet2" [(ngModel)] = "istreet2" autocomplete="address-line1">
						</section>
						<section>	
							<label for="istreet3" class="label">N° et Rue</label>
							<div class="row">
								<input type="text" id="istreet3" [(ngModel)] = "istreet3" autocomplete="address-line2">
								<label for="icp">Code postal</label>
								<input type="text" id="icp" [(ngModel)] = "izip" autocomplete="postal-code">
								<label for="icity">Ville</label>
								<input type="text" id="icity" [(ngModel)] = "icity" autocomplete="address-level2">
							</div>
						</section>
						<section>
							<label for="icountry" class="label">Pays</label>
							<input type="text" id="icountry" value="France" [(ngModel)] = "icountry" autocomplete="country">
						</section>
						<div class="action">
							<button class="clear" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clear()"> <mat-icon>close</mat-icon> </button>
							<button type="button" mat-flat-button color="primary" matTooltip="Ajouter" aria-label="Ajouter adresse" (click)="addLine()" >Ajouter</button>
						</div>						
					</div>		