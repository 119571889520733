<table style="width: 100%; table-layout:auto;" *ngIf="true">
		<tr>
			<th class="mdc-data-table__header-cell col0">#</th>
			<th class="mdc-data-table__header-cell col1">Type de doc.</th>
			<th class="mdc-data-table__header-cell col2">Action</th>
		</tr>
		
		<tr *ngFor="let doc of docs; let i=index">
			<td>{{doc.id}}</td>
			<td>{{map_ojbecttypes.get(doc.objecttyp)}}</td>
			<td></td>
		</tr>
