import { Component, Input } from '@angular/core';
import { Subject, BehaviorSubject, Observable, pipe, Subscription, zip, } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter, takeUntil, takeLast, merge } from "rxjs/operators";
import { NgFor, NgIf, DatePipe, KeyValuePipe } from '@angular/common';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';

import { QuotationsService } from '@app/sales/quotations/quotations.service';
import * as constants from "@app/shared/constants";

@Component({
	selector: 'app-quotationdetails',
	standalone: true,
	imports: [NgFor, NgIf, MatTableModule,],
	templateUrl: './quotationdetails.component.html',
	styleUrl: './quotationdetails.component.scss'
})
export class QuotationdetailsComponent {

	@Input() mouseover$!: Observable<number>;								// Mouse over a quotation line
	@Input() mouseout$!: Observable<boolean>;								// Mouse out of a quotation line

	private mouseoversubscription!: Subscription;
	private mouseoutsubscription!: Subscription;

	private lastmouseout: boolean = false;

	public dataSource?: MatTableDataSource<AbstractControl>;
	public displayedColumns: string[] = ['name', 'qty', 'uprice'];


	constructor(private quotationsService: QuotationsService) { }

	ngOnInit(): void {
		this.mouseoutsubscription = this.mouseout$.subscribe({
			next: (val) => {
				//console.log("mouseout: " + val);
				this.lastmouseout = val;
			}
		});

		this.mouseoversubscription = this.mouseover$.pipe(
			debounceTime(500),
			filter(() => this.lastmouseout),
		)
			.subscribe({
				next: (id) => {
					//console.log("Over row: " + id);
					this.quotationsService.getQuotationById(id).subscribe({
						next: (items: any) => {
							this.dataSource = new MatTableDataSource(items[0].lines);
							//console.log("dataSource: ", JSON.stringify(this.dataSource));
						},
						error: (e) => {
							console.log("ERREUR Serveur : ", e.error);
						}
					});
				},
				error: (e) => {
					console.log("ERREUR Serveur : ", e.error);
				}
			});
	}

	ngOnDestroy() {
		this.mouseoversubscription.unsubscribe();
		this.mouseoutsubscription.unsubscribe();
	}
}
