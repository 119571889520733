import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge, EMPTY } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';

import { TemplateAttribute, Attribute, Product, ProductTpl, TplPackaging, Packaging, ProductPackagingVal, ProductPackaging } from '@app/shared/_models/product';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  constructor(private http: HttpClient) { }

	// Get all attributes from product_attribute
  getAttributes(): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(environment.server_url + "/product_attributes");
  }

	// Get tpl_attributes = template's attributes
	getTemplateAttributesAsFormArray(template: any): Observable<{f:FormArray, seq:number}> {
		let maxseq = 0;
		return this.getTemplateAttributes(template)
			.pipe(map((ta: TemplateAttribute[]) => {
				if (ta.length) console.log("last seq: " + ta[ta.length-1].seq);
				const fgs: FormArray = new FormArray(ta.map(TemplateAttribute.asFormGroup));
				return {f:fgs, seq:(ta.length==0?0:ta[ta.length-1].seq)};
		}))

	}
	
  getTemplateAttributes(template:any): Observable<any>{
    return this.http.get<void>(environment.server_url + "/template_attributes/" + template);
  }

/* UNUSED?
  getProduct_attribute_vals(attribute:any) {
    return this.http.get<void>(environment.server_url + "/product_attribute_vals/" + attribute);
  }
*/
  get_all_attributes_vals(): Observable<any> {
    return this.http.get<void>(environment.server_url + "/attribute_vals");
  }

	getProducts_attributes(typ: string, q: string): Observable<any> {
		return this.http.get<void>(environment.server_url + "/products?typ=" + typ + "&q=" + q);
	}

  postAttributeaction(attributes: any): Observable<any> {
    const headers = { 'content-type': 'application/json'};
    return this.http.post<any>(environment.server_url + "/product_attributes", attributes, {'headers':headers});
  }  
  
	postTplattribute(ta:TemplateAttribute): Observable<any> {
		const headers = { 'content-type': 'application/json' };
		return this.http.post<any>(environment.server_url + "/tplattribute", ta, { 'headers': headers });
	}  
	
	postTplattributeaction(ta:any): Observable<any> {
		const headers = { 'content-type': 'application/json' };
		return this.http.post<any>(environment.server_url + "/tplattributeaction", ta, { 'headers': headers });
	}	
	
	deletetTplattribute(template:number, attribute: number): Observable<any> {
		return this.http.delete<any>(environment.server_url + "/tplattribute/"+ template + "/" + attribute);
	}		
}
