import { Component } from '@angular/core';
import { ValuesService } from '@app/common/values/values.service';
import { C_value } from "@app/shared/_models/common";
import { QuotationService } from '@app/sales/quotation/quotation.service';
import { NgIf, NgFor } from '@angular/common';
import * as constants from "@app/shared/constants";

@Component({
    selector: 'app-quotationdocs',
    templateUrl: './quotationdocs.component.html',
    styleUrls: ['./quotationdocs.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})

export class QuotationdocsComponent {
	public ojbecttypes:C_value[]=[];
	public map_ojbecttypes = new Map();
	public docs!:any[];
		
	constructor(private valuesService: ValuesService, private quotationService: QuotationService,) { }
	ngOnInit(): void {
		this.valuesService.getValues$(constants.C_OBJECT_TYPE)	// Get object types
			.subscribe((data: any) => {
				this.ojbecttypes = data;
				this.ojbecttypes.map(a => {
					this.map_ojbecttypes.set(a.id, a.name);
				})
			});
		this.quotationService.get_docs_by_object_id(constants.OBJECTTYP_ORDER)
			.subscribe((data: any) => {
				this.docs=data;
				});
	}

}
