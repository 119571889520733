<app-menu-common url="sales"></app-menu-common>

<ng-container *ngIf="formGroup">
	<div class="pageheader flexcolumn" [formGroup] = "formGroup">
		<section class="flexrow">
			<div *ngIf="!formGroup.get('has_variants')?.value" class="grey pagetitle"><span *ngIf="template_id == 0">Nouveau&nbsp;</span>Modèle Produit&nbsp;{{formGroup.get("name")?.value}}</div>
			<div *ngIf="formGroup.get('has_variants')?.value" class="grey pagetitle">Modèle Produit&nbsp;générique&nbsp;{{formGroup.get("name")?.value}}</div>
			<span class="spacer"></span>
			<div *ngIf="template_id !==0" class="grey pagetitle">#&nbsp;{{template_id}}</div>					
			<div *ngIf="template_id ==0" class="init">En création</div>
		</section>
	</div>
	
	<app-action-bar form="mainform" (submitEmitter)="onSubmit($event)">
		<ng-container *ngIf="formGroup.value.has_variants"> 
			<app-variants-button [id]="template_id"></app-variants-button>
		</ng-container>
		<ng-container *ngIf="!formGroup?.value.has_variants"> 
			<app-product-button [template_id]="template_id" [id]="formGroup.value.unique_variant"></app-product-button>
		</ng-container>		
	</app-action-bar>
	
	<div class="pagecontent">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit($event)" id="mainform">
		<div class="maincontent">
				<mat-form-field [style.width.%]=50>
					<mat-label>Input</mat-label>
					<input matInput type="text" formControlName="name">
				</mat-form-field>
				<mat-tab-group >
					<mat-tab label="Général" *ngIf="true">
						<app-general></app-general>
					</mat-tab>
					<mat-tab label="Attributs" [disabled]="template_id == 0 ? 'true' : null">
						<app-attributes [loadEventsSubject$]="loadEventsSubject.asObservable()" [template_id]="template_id" (updateVariantsEvent)="updateVariants($event)"></app-attributes>
					</mat-tab>
					<mat-tab label="Condt." [disabled]="template_id == 0 ? 'true' : null">
						<app-productpackaging></app-productpackaging>
					</mat-tab>
				</mat-tab-group>
			</div>
		</form>
	</div>
</ng-container>
