import { Component, ViewChild, ElementRef, OnInit, Inject, Optional } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable, of, fromEvent, concat, forkJoin } from "rxjs";

import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { ProductsService } from '@app/sales/products/products.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss', '../../styles-custom.scss'],
    standalone: true,
    imports: [NgIf, MenuCommonComponent, MatDialogModule, MatButtonModule, MatIconModule, RouterLink, MatFormFieldModule, MatInputModule, FormsModule, MatAutocompleteModule, NgFor, MatOptionModule, MatTableModule]
})

export class ProductsComponent implements OnInit {
	@ViewChild('searchNameInput', { static: true }) searchNameInput: ElementRef<HTMLInputElement> = {} as ElementRef;
	@ViewChild('searchManufInput', { static: true }) searchManufInput: ElementRef<HTMLInputElement> = {} as ElementRef;

	public displayedColumns: string[] = ['id', 'manuf', 'name', 'price'];
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public formGroup: any;
	public searchname: string = "tena pants";	// Name Search field
	public searchmanuf: any = "";	// Manuf Search field
	public isSearching: boolean = false;
	public manufApiResponse: any;
	public nameApiResponse: any;
	public bcApiResponse: number | undefined;

	constructor(private productsService: ProductsService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private spinner: SpinnerService, @Optional() public dialogRef: MatDialogRef<ProductsComponent>, @Optional()  @Inject(MAT_DIALOG_DATA) public dialogData: any,) { }

	ngOnInit() {
		this.formGroup = this.fb.group({
			items: this.fb.array([])
		});

		if(this.dialogData && this.dialogData.searchname) {
			this.searchname = this.dialogData.searchname
			this.onSearch()
		}
		

		fromEvent(this.searchNameInput.nativeElement, 'keyup').pipe( // Attach search function to name field
			// get value
			map((event: any) => {
				return event.target.value.trim();
			})
			// if character length greater then 2
			, filter(res => res.length > 2)

			// Time in milliseconds between key events
			, debounceTime(500)

			// If previous query is different from current   
			, distinctUntilChanged()

			// subscription for response
		).subscribe((text: string) => {									// FIXME DO NOT subscribe in subscribe. Use switchmap (see quotation.component.ts)
			this.isSearching = true;
			this.searchGetCallName(text).subscribe({
				next: (res) => {
				console.log('Response from searchGetCallName: ', JSON.stringify(res));
				this.isSearching = false;
				this.nameApiResponse = res.i;
				this.bcApiResponse = res.bc;
			}, 
			error: (err: any) => {
				this.isSearching = false;
				console.log('error', err);
			}});
		});

		fromEvent(this.searchManufInput.nativeElement, 'keyup').pipe(	// Attach search function to manuf field
			// get value
			map((event: any) => {
				return event.target.value;
			})
			// if character length greater then 2
			, filter(res => res.length > 2)

			// Time in milliseconds between key events
			, debounceTime(500)

			// If previous query is diffent from current   
			, distinctUntilChanged()

			// subscription for response
		).subscribe((text: string) => {
			this.isSearching = true;
			this.searchGetCallPartner(text).subscribe((res) => {
				this.isSearching = false;
				this.manufApiResponse = res;
			}, (err: any) => {
				this.isSearching = false;
				console.log('error', err);
			});
		});
	}

	searchGetCallName(text: string): Observable<any> {
		text = text.trim();
		
		if (text === '') { return of([]); }	// Don't bother w/ empty strings
		
		let q = text;
		let split = text.split(" ");
		if (split.length > 1) {
			q=split[0];
			for (let i=1; i<split.length; i++) {
				q += " AND " + split[i]; // .replace("'", "\\'");
			}
		}

		let $search_i = this.productsService.search_i('*' + q + '*');
		let $search_bc = this.productsService.search_bc(text);

		const $fjoin = forkJoin({
			i: $search_i,
			bc: $search_bc
		});
		const observable = Observable.create(function subscribe(observer: any) {

			let subscription = $fjoin.subscribe((data) => {
				console.log("back from fjoin: " + JSON.stringify(data));
				observer.next(data);
				observer.complete();
				subscription.unsubscribe();
			});
		})

		return observable;
	}

	searchGetCallPartner(term: string): Observable<any> {
		if (term === '') {
			return of([]);
		}
		return this.productsService.search_manuf('*' + term + '*');
	}

	onSearch(): void {
		console.log("onSearch (name - manuf): (" + this.searchname + " - " + this.searchmanuf.id + ")");
		if (this.searchname || this.searchmanuf) {
			// this.spinner.show();
			this.productsService.getProductsTplByFilter(this.searchname, this.searchmanuf.id, this.bcApiResponse, undefined)
				.subscribe((items: any) => {
					//this.formGroup.setControl('items', items);
					this.dataSource = new MatTableDataSource(items);
					this.spinner.hide();
				});
		}
	}

	onCancel(): void {
		this.searchname = "";
		this.searchmanuf = "";
		this.manufApiResponse = [];
		this.nameApiResponse = [];
		this.bcApiResponse = undefined;
		this.dataSource = new MatTableDataSource();
	}

	onClickRow(row: any, e: Event): void {
		console.log("onClickRow");
		if (row.has_variants || row.lnk_products.length === 0) {	// This is a template OR orphan template (no variant linked) 
			if (!this.dialogRef) this.router.navigate(['/sales/product/' + row.id]);	// Jump to template only on standalone mode (NOT dialog)
			this.dialogRef.close(row.id);
		} else {	// Template with unique variant (or product variant) => jump to variant
			let template = row.lnk_products[0].template; 
			if (this.dialogRef) {	// Dialog mode, return custom object
				// let ret = {product:row.lnk_products[0].id, product_name:row.lnk_products[0].name, template:template, price_taxi:row.lnk_products[0].price_taxi}
				//let ret = row.lnk_products[0].id;						// Return product id
				this.dialogRef.close(row.id);
			} else {
				this.router.navigate(['/sales/variant/' + template + '/' + row.id]);	
			}
		}
	}

	onInnerClickRow(row: any, e: Event): void {
		e.stopPropagation;
		console.log('row',row);
		if (this.dialogRef) {	// Dialog mode, return custom object
			console.log("onInnerClickRow: " + JSON.stringify(row));
			// let ret = {product:row.id, product_name:row.name, template:row.template, price_taxi:row.price_taxi}
			// this.dialogRef.close(ret);
		} else {
			// this.router.navigate(['/sales/variant/' + row.template + '/' + row.id]);	
		}
	}

	onKeydown(event: any) {
		if (event.key === "Enter") {
			console.log(event);
			this.onSearch();
		}
	}

	displayFn(o: any) {
		if (o) return o.name
		else return null;
	}
}
