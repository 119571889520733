import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgFor, NgIf, DatePipe, KeyValuePipe } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { NavbarDirective } from '@app/shared/directive/navbar.directive'
import { navComponent } from '../nav.component';
import { NavItem } from '../nav-item';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { LoginService } from '@app/common/login.service';

@Component({
	standalone: true,
	selector: 'app-menu-common',
	templateUrl: './menu-common.component.html',
	styleUrls: ['./menu-common.component.scss'],
	imports: [ MatToolbarModule, MatMenuModule, MatIconModule, NavbarDirective, MatButtonModule, RouterLink, NgIf ]
})
export class MenuCommonComponent implements OnInit, OnDestroy {
	@Input() url = '';
	@ViewChild(NavbarDirective, { static: true }) navHost!: NavbarDirective;
	
	public currentUrl:String = '';

	constructor(private router: Router, public loginService: LoginService) {
		// router.events.subscribe((url: any) => console.log(url));
		/*
		router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				// console.log("MenuCommonComponent URL: " + e.url)
				// this.currentUrl = e.url;				// Why?
			}
		});
		*/
	}
	
	public onClick(store:number) {
		this.loginService.currentstore = store;
	}
	
	public logout() {
		this.loginService.logout();
	}

	ngOnInit(): void {
	}
	ngOnDestroy() { }
	
	islogged():boolean {
		return this.loginService.loggeduser != undefined;
	}

/*	loadComponent() {
		console.log("navs: " + JSON.stringify(this.navs));
		console.log("url: " + JSON.stringify(this.url));
		const navItem = this.navs[0];
		const viewContainerRef = this.navHost.viewContainerRef;
		console.dir("viewContainerRef: " + JSON.stringify(viewContainerRef.element));
		const componentRef = viewContainerRef.createComponent<navComponent>(navItem.component);
		//componentRef.instance.data = navItem.data;
	}*/

}
