import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { AbstractControl, FormArray, FormGroup, ControlContainer, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';
import { BehaviorSubject, Observable, pipe, Subscription } from 'rxjs';

import { AttributesService } from './attributes.service';
import { TemplateAttribute, Attribute, Product, ProductTpl, TplPackaging, Packaging, ProductPackagingVal, ProductPackaging } from '@app/shared/_models/product';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-attributes',
    templateUrl: './attributes.component.html',
    styleUrls: ['./attributes.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, MatButtonModule, MatIconModule]
})
export class AttributesComponent implements OnInit, OnDestroy {
	constructor(private route: ActivatedRoute, private location: Location, private attributesService: AttributesService, public controlContainer: ControlContainer, private router: Router, private fb: FormBuilder) { }
	
	@Input() template_id!:number;	// template_id comes from parent	
	@Input() loadEventsSubject$!: Observable<boolean>;
	@Output() updateVariantsEvent = new EventEmitter<boolean>();
	private eventsSubscription!: Subscription;
	
	public formGroup: FormGroup = this.fb.group({
		tplAttributes: this.fb.array([])
	});
	public attributes?: any[];
	public newattribute: FormControl=new FormControl('');
	private lastseq=0;						// Attribute sequence
	public tab: number=0;					// Active tab index

	ngOnInit(): void {
		console.log("AttributesComponent ngOnInit template_id: " + this.template_id);
		
  	this.route.queryParams.subscribe(params => {	// 	Is there a tab number in URL? 
			if (params && params['tab'] != null) {
				this.tab=Number(params['tab']);
			} 
		});						
		
		this.eventsSubscription = this.loadEventsSubject$.subscribe((flag) => { 	// Triggered on Order loading. Flag is unused
			if (flag) {	// Flag indicates a real loading. Init value is false 
				console.log("Received loadEventsSubject");
				this.load();
			} 
		});	
	}
	
	private load() {	// Load template attributes & filter listbox (this.attributes) to remove unwanted values
		console.log("AttributesComponent in load");
		this.attributesService.getTemplateAttributesAsFormArray(this.template_id)	// Get template attributes
			.subscribe((tas: {f:FormArray, seq:number}) => {
				this.formGroup.removeControl("tplAttributes");
				this.formGroup.addControl("tplAttributes", tas.f);
				this.lastseq = tas.seq;
				this.updateVariantsEvent.emit(tas.f.length > 0);	// Send event to parent, to update variants button
				this.attributesService.getAttributes()	// Get all attributes (for listbox)
					.subscribe((ats: Attribute[]) => {
						this.attributes = ats;
						// Filter attribute lisbox against attributes already in template
						tas.f.controls.map((ta: AbstractControl)=> {		// Loop attributes in template
							let attribute = ta.get("attribute")?.value;
							this.attributes = this.attributes?.filter((a:Attribute) => {
								return a.id !== attribute;
							});
						});
				});
			});
	}
	
	public deleteLine(attribute_id: number){
		console.log("Delete attribute_id: " + attribute_id);
		this.attributesService.deletetTplattribute(this.template_id, attribute_id)
			.subscribe((response: any) => {
				console.log("Server response: " + JSON.stringify(response));
				// this.router.navigateByUrl('sales/product/' + this.template_id + '?tab=1');
				this.load();
			});
	}
	
	public moveLine(attribute_id: number, seq: number, action: number){
		console.log("Move attribute_id: " + attribute_id + " - " + action);
		this.attributesService.postTplattributeaction({"template_id":this.template_id, "attribute_id":attribute_id, "seq":seq, "action":action})
			.subscribe((response: any) => {
				console.log("Server response: " + JSON.stringify(response));
				// this.router.navigateByUrl('sales/product/' + this.template_id + '?tab=1');
				this.load();
			});
	}	
	
	onAddattribute() {
		console.log("onAddattribute");
		if (!isNaN(this.newattribute.value)) {
		let templateAttribute: TemplateAttribute = {
			template: +this.template_id,
			attribute: +this.newattribute.value,
			seq: ++this.lastseq,
			lnk_product_attribute:undefined,
		}
		this.attributesService.postTplattribute(templateAttribute)
			.subscribe((response: any) => {
				console.log("Server response: " + JSON.stringify(response));
				//this.router.navigateByUrl('sales/product/' + this.template_id + '?tab=1');
				this.load();
			});
	} else {	// Should NOT happen, packaging is comming from a listbox
		
	}
		this.newattribute.setValue('');
	}
	
	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
	}	

	get tplAttributes(): FormGroup[] {
		return (this.formGroup.get('tplAttributes') as FormArray).controls as FormGroup[];
	}
	
}
