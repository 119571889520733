import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { first } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginService } from '@app/common/login.service'
import { AlertService } from '@app/shared/alert.service'

@Component({
	standalone: true,
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	imports: [MenuCommonComponent, MatCardModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule]
})
export class LoginComponent {
	form!: FormGroup;
	loading = false;
	submitted = false;

	constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private loginService: LoginService, private alertService: AlertService) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});
	}

	onSubmit() {
		this.submitted = true;
		this.alertService.clear();	// reset alerts on submit
		if (this.form.invalid) {	// stop here if form is invalid
			return;
		}

		this.loading = true;
		this.loginService.login(this.f['username'].value, this.f['password'].value)
			.pipe(first())
			.subscribe({
				next: () => {
					const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';	// get return url from query parameters or default to home page
					this.router.navigateByUrl(returnUrl);
				},
				error: error => {
					console.error('LoginComponent ERROR: ' + error);
					this.alertService.error(error);
				}
			});
	}

	onKeydown(event: any) {	// Useless. Submit button is automatically binded to enter key
		if (event.key === "Enter") {
			console.log(event);
			this.onSubmit();
		}
	}

	// convenience getter for easy access to form fields
	get f() { return this.form.controls; }
}

