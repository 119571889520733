<div class="flexrow">
	<div class="flexcolumn">
		<div class="inputzone">
			Article<input autofocus [(ngModel)]="searchinput" (keydown)="onItemKeydown($event)">
			<button type="button" mat-icon-button color="primary" matTooltip="Chercher" aria-label="Chercher" (click)="openProductDialog()"><mat-icon>search</mat-icon></button>
		</div>
		<div class="inputzone">
			Famille
			<select [(ngModel)]="faminput">
				<option>-</option>
				<option value="Alimentation">Alimentation</option>
				<option value="Orthopédie">Orthopédie</option>
				<option value="Pansements">Pansements</option>
				<option value="Consommables">Consommables</option>
				<option value="Chaussures">Chaussures</option>
				<option value="Literie">Literie</option>
			</select>
		</div>
		<div class="inputzone">
			Fournisseur
				<mat-form-field id="manufinputgroup">
				  <input matNativeControl #searchManufInput matInput [(ngModel)]="searchmanuf" placeholder="" [matAutocomplete]="manufauto" >
				  <button *ngIf="searchmanuf" matSuffix mat-icon-button aria-label="Clear" (click)="searchmanuf=''">
				    <mat-icon>close</mat-icon>
				  </button>		  
			    <mat-autocomplete #manufauto="matAutocomplete" [displayWith]="displayFn">
			      <mat-option  *ngFor="let option of manufApiResponse" [value]="option">
			          {{option.name}}
					  </mat-option>
				  </mat-autocomplete>
			</mat-form-field>    		
		</div>
		<div class="inputzone">
			Ensemble existant
			<select [(ngModel)]="setinput">
				<option>-</option>
				<option value="1">Savons liquides</option>
				<option value="2">Chaussures col. 2023</option>
			</select>
		</div>		
	</div>
	<div class="action">
		<button mat-flat-button color="primary" (click)="addtoset('IN')">Ajouter</button>
		<button mat-flat-button color="accent" (click)="addtoset('EX')">Exclure</button>
	</div>
	<form class="flexcolumn" [formGroup]="formGroup">
		<div class="zonelabel bold">Articles inclus</div>
		<div class="included">
			<table formArrayName="included">
				<tr *ngFor="let item of included.controls; let i=index;">
					<td class="col1">
						{{item.value.name}}
					</td>
					<td class="col2">
						<button mat-icon-button color="primary" matTooltip="Supprimer" aria-label="Supprimer" style="padding: 0px; margin-left:6px; max-height: 20px; max-width: 20px;" (click)="deleteLine(i)"><mat-icon style="font-size: 16px; margin-top: -6px; max-height: 20px; max-width: 20px;">delete</mat-icon></button>
					</td>					
				</tr>
			</table>
		</div>
		<div class="zonelabel bold">Articles exclus</div>
		<div class="excluded">
			<table formArrayName="excluded">
				<tr *ngFor="let item of excluded.controls; let i=index;">
					<td class="col1">
						{{item.value.name}}
					</td>
					<td class="col2">
						<button mat-icon-button color="primary" matTooltip="Supprimer" aria-label="Supprimer" style="padding: 0px; margin-left:6px; max-height: 20px; max-width: 20px;" (click)="deleteLine(i)"><mat-icon style="font-size: 16px; margin-top: -6px; max-height: 20px; max-width: 20px;">delete</mat-icon></button>
					</td>					
				</tr>
			</table>	
		</div>		
		<div class="flexrow saveset"><div><span class="setlabel">Nom</span><input class="nameset"></div><button mat-flat-button color="primary">Enregistrer l'ensemble</button></div>
	</form>
</div>
