import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { Pinvc, PinvcL } from '@app/shared/_models/stock';

@Component({
	selector: 'app-dialog-bcexists',
	standalone: true,
	imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, FormsModule, ReactiveFormsModule],
	templateUrl: './dialog-bcexists.component.html',
	styleUrl: './dialog-bcexists.component.scss'
})
export class DialogBcexistsComponent {
	public qty: FormControl<number|null> = new FormControl(null, { nonNullable: false });
	readonly dialogRef = inject(MatDialogRef<DialogBcexistsComponent>);
	data: {pinvcl: PinvcL} = inject(MAT_DIALOG_DATA);
	
	constructor() { }
	
	onCancel() {
		this.dialogRef.close(undefined);							// Cancel => Do not return a qty
	}
	
	onReplace() {
		this.dialogRef.close({action:"REPLACE", qty: this.qty.value});		// Replace
	}
	
	onAdd() {
		this.dialogRef.close({action:"ADD", qty: this.qty.value});				// Add
	}	
}
