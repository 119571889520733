import { Component } from '@angular/core';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';
import { MenuHomeComponent } from '@app/shared/menu-home/menu-home.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MenuHomeComponent, MenuCommonComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

}
