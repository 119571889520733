import { Directive,  HostListener } from '@angular/core';

@Directive({
	selector: '[onlyFloat]',
    standalone: true,
})

export class OnlyFloat {

    constructor(){
    }


    @HostListener('keydown',['$event'])
    onKeyDown(event : KeyboardEvent){
        if((event.key=='.' ||event.key==',' ) && (event.target as HTMLInputElement).value.match('[.,]+')   ){  // empeche de pouvoir mettre 2 separateur decimaux
            return false
        }
        if(event.key==','){  // la virgule est transforme en .
            let t= (event.target as HTMLInputElement);
            var start :number|null = t.selectionStart;
            var end  :number|null= t.selectionEnd;
            var oldValue = t.value;
            var newValue = oldValue.slice(0, start??0) + '.' + oldValue.slice(end??0) ;
            t.value =  newValue;
            t.selectionStart = t.selectionEnd = (start??0) + 1;
            return false
        }
        else{ 
            return true
        }
    }
    
    @HostListener('paste',['$event'])
    blockPaste(event : ClipboardEvent){
        if(event.isTrusted){  // sert dans le cas d un evenement crer a la main pour ne pas faire de boucle infini
            let pasteText = (event.clipboardData?.getData('text/plain'))??''
            pasteText =  pasteText.replace(',','.')
    
            if(pasteText.split('.')[0].length >1 ){
                pasteText = pasteText.split('.')[0]+ '.' + pasteText.split('.')[1].replaceAll(',','');
            }
     
            (event.target as HTMLInputElement).value = pasteText;  // fonctinne mais ne met pas à jour le model il suffirait ici de forcer la pise a jour du formulaire
            
            let newevent = new ClipboardEvent('paste');
            newevent.clipboardData?.setData('text/plain',pasteText,); // essai de creer un nouveau copier coller
            event.target?.dispatchEvent(newevent)  // ne fait rien

            return false;
        } else {
            return true;
        }
    }


}
 