import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Product } from '@app/shared/_models/product';

export class PurchasePriceHist {
	id?: number;
	price: number =0;
	product?: number;

	lnk_product: Product = new Product;	// Linked data
	
	static asFormGroup(purchasePriceHist: PurchasePriceHist): FormGroup {
		const fg = new FormGroup({
			id: new FormControl(purchasePriceHist.id),
			price: new FormControl(+purchasePriceHist.price),
			
			lnk_product: Product.asFormGroup(purchasePriceHist.lnk_product),
		});		
		return fg;
	}
}