import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';
import { PrintService } from '@app/shared/print.service';

import { PartnersComponent } from '@app/common/partners/partners.component';

import { QuotationService } from '@app/sales/quotation/quotation.service';
import { PartnersService } from '@app/common/partners/partners.service';
import { Subject, BehaviorSubject, filter, debounceTime, distinctUntilChanged, Subscription, switchAll, map, switchMap, tap, Observable, of } from 'rxjs';

import { environment } from '@app/../environments/environment';
import * as constants from "@app/shared/constants";
import { QuotationdocsComponent } from './quotationdocs/quotationdocs.component';
import { QuotationoptionsComponent } from './quotationoptions/quotationoptions.component';
import { QuotationdeliveryComponent } from './quotationdelivery/quotationdelivery.component';
import { QuotationgeneralComponent } from './quotationgeneral/quotationgeneral.component';
import { MatTabsModule } from '@angular/material/tabs';
import { QuotationActionComponent } from '../../shared/action-bar/quotation-action/quotation-action.component';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';
import { SalehistoryComponent } from './salehistory/salehistory.component';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { A11yModule } from '@angular/cdk/a11y';
import * as xml2js from 'xml2js';

import { ThumbDeliveryTypeComponent } from './thumb-delivery-type/thumb-delivery-type.component';
import { C_value } from "@app/shared/_models/common";
import * as util from "@app/shared/util";
import { LoginService } from '@app/common/login.service';
import { ValuesService } from '@app/common/values/values.service';
import { MercalinkFselibService, LireCVHTTPResponse, BsDialogComponent } from 'mercalink-fselib';

@Component({
	selector: 'app-quotation',
	templateUrl: './quotation.component.html',
	styleUrls: ['./quotation.component.scss', '../../styles-custom.scss'],
	standalone: true,
	imports: [MenuCommonComponent, A11yModule, ThumbDeliveryTypeComponent, NgIf, FormsModule, ReactiveFormsModule, MatButtonModule, MatTooltipModule, NgFor, NgClass, ActionBarComponent, QuotationActionComponent, MatTabsModule, QuotationgeneralComponent, QuotationdeliveryComponent, QuotationoptionsComponent, QuotationdocsComponent, DatePipe, SalehistoryComponent, MatFormField, MatIcon, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatButtonModule]
})

export class QuotationComponent {

	constructor(public loginService: LoginService, private valuesService: ValuesService, private partnersService: PartnersService, private route: ActivatedRoute, private router: Router, private spinner: SpinnerService, private alertService: AlertService, public dialog: MatDialog, private fb: FormBuilder, private quotationService: QuotationService, private printService: PrintService, public mercalinkFselibService: MercalinkFselibService) { }
	public sales_order_status = constants.sales_order_state;
	public sales_order_mode = constants.sales_order_mode;
	public constants = constants;
	public apiResponseClients: any;

	private routerevents$: any;
	public loaded = false;				// Flag OK for children loading
	public id: number = 0;
	public eventtyp: number = constants.EVENTTYP_INSERT;			// Insert event by default	
	public formGroup!: FormGroup;
	public customer: any | undefined;		// Linked customer. Easier to deal w/ compared to formGroup.lnk_customer
	public addr_liv_index: any;
	public selectedtab: number = 0;
	public searchnamefocus = false;
	public cvlookup: boolean = false;

	qualities: C_value[] = [];
	map_qualities = new Map();

	loadOrderEventsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);	// To emit load order event
	submitEventSubject: Subject<number> = new Subject<number>();			// To emit submit event	
	private openbssubscription!: Subscription;
	private opencustomersubscription!: Subscription;

	xmlparser: xml2js.Parser = new xml2js.Parser({ strict: true, trim: false, explicitArray: false });

	ngOnInit(): void {
		this.id = Number(this.route.snapshot.paramMap.get('id'));
		// console.log("ngOnInit, id: " + this.id);

		if (this.id === 0) { // New order (no URL param)

			this.formGroup = this.fb.group({
				id: new FormControl<number>(+0),
				searchnameClient: new FormControl<string>(''),
				customer: new FormControl<number>(+0),
				store: new FormControl<number|undefined>(this.loginService.currentstore),	// Will be updated on submit
				state: new FormControl<number>(+1),			// Status = 1: In progress. WARNING status property is reserved => rename to order_status
				addr_deliv: [],
				addr_bill: [],
				amount_taxi: new FormControl<number>(+0.0),
				amount_taxx: new FormControl<number>(+0.0),
				lines: this.fb.array([]),
				payments: this.fb.array([]),
				lnk_customer: null,			// This is useless on server side
				lnk_delivery: null,			// On creation no delivery available
			});
			this.loaded = true;

			const obs$ = this.formGroup.controls['searchnameClient'].valueChanges.pipe(
				distinctUntilChanged(), 		// If previous query is different from current 
				filter((res: string) => {  	// if character length greater or less then 3 OR search string is a number => no search and suppress old search
					if (res.length > 3 && isNaN(+res)) { return true } 	// Not a NIR => go ahead, search client
					if (res.length > 3 && !isNaN(+res)) {								// Got a potential NIR => skip 
						this.apiResponseClients = undefined;
						return false;
					}
					else {
						this.apiResponseClients = undefined;
						return false
					}
				}),
				debounceTime(300),  // Time in milliseconds between key events
				switchMap((searchclientName: string) => {
					return this.partnersService.getPartnersByFilter(util.postgressearchstring(searchclientName));
				}));

			obs$.subscribe({													// NB: No need to unsubscribe on destroy. Switchmap does that
				next: (items: any) => {
					this.apiResponseClients = items;
					console.log("items : ", items);
				},
				error: (e) => {
					console.error("ERREUR Serveur : ", e.error);
					this.spinner.hide();
					this.alertService.error("ERREUR Serveur : " + e.error);	// TEMPLATE subscribe call
				}
			});

			this.valuesService.getValues$(constants.C_VALUES_FSE_QUALITY)	// Get Qualité (lien avec l'assuré) TODO change subscribe params
				.subscribe((data: any) => {
					this.qualities = data;
					this.qualities.map(a => {
						this.map_qualities.set(a.id, a.name);
					})
				});

		} else {				// Load existing order
			this.loadOrder();
			this.loaded = true;
		}

		this.routerevents$ = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd && this.id !== 0) {	// Real reload, not a F5 on same page 
				console.log("NavigationEnd, reloading");
				this.loadOrder();
			}
		});
	}

	ngAfterViewInit() {
		// console.log('on after view init');
	}

	onKeyDownInputClient(e: KeyboardEvent) {
		if (e.key === "Enter") {
			this.cvlookup = false;	// Switch to name lookup
			this.onClickonclientOption(this.formGroup.controls['searchnameClient'].value);
		}
	}

	onClickonclientOption(client: any) {		// Click on autocomplete option, from name OR CV lookup
		if (!this.cvlookup) {									// General case, name lookup => client exists select it
			this.selectCustomer(client);
		} else {															// Search by CV => Open client lookup dialog. Init w/CV name
			this.openCustomerDialog(this.displayClientName(client, false));
		}
	}

	getBenefiaires(cv: any): Observable<any> {
		return of({});
	}

	displayClientName(o: any, addquality: boolean = true): string {
		let ret = '';
		if (o && this.map_qualities) {
			if (!o.hasOwnProperty('name')) { return o; }
			if (addquality && o.hasOwnProperty("quality")) ret += this.map_qualities.get(+o.quality);
			if (o.name) ret = ret + (ret ? ' ' : '') + o.name;
			if (o.firstname) ret += ' ' + o.firstname;

			// if(o.addresses && o.addresses && o.addresses[0] && o.addresses [0].zip  ) retour+= ' '+o.addresses [0].zip
		}
		// console.log("[displayClientName] o - ret", o, ret);
		return ret;
	}

	loadOrder() {
		// this.spinner.show();
		this.eventtyp = constants.EVENTTYP_UPDATE; 										// Update mode. Will issue an update event
		this.quotationService.getQuotationAsFormGroup(this.id)
			.subscribe((data: FormGroup) => {
				if (data.controls['id'] === undefined) {
					console.log("No data from server. Document does not exist");
					this.id = -1;	// This is the flag for a non existent doc
					return;
				}
				if (this.formGroup) {			// After submit, formgroup exists => patch
					this.formGroup.patchValue(data);
					// this.formGroup.get('payments')?.setValue(new FormArray([]));			// FIXME get array from API
					console.log("Patching data: " + JSON.stringify(data.getRawValue()));
					this.loadOrderEventsSubject.next(this.id);	// Emit a loadorder event to child components
				} else {						// First formgroup setting. No previous value
					//console.log('data in loadorder', data);
					console.log("Setting formgroup: " + JSON.stringify(data.getRawValue(), null, 2));
					this.formGroup = data;
					this.customer = data.get('lnk_customer')?.value;
					//this.formGroup.addControl("payments", new FormArray([]));		// FIXME get array from API
					this.loadOrderEventsSubject.next(this.id);	// Emit a loadorder event to child components
				}
			});
	}

	onSubmit(cb?: Function) {
		this.submitEventSubject.next(this.selectedtab);					// Emit a submit event to child components
		if (this.selectedtab === 0) {	// FIXME Save only on tab 0 
			if (this.customer) this.formGroup.get('customer')?.setValue(this.customer.id);
			this.formGroup.get('addr_deliv')?.setValue(parseInt(this.formGroup.get('addr_deliv')?.value));			// Select options need casting to int
			this.formGroup.get('addr_bill')?.setValue(parseInt(this.formGroup.get('addr_bill')?.value));
			this.formGroup.get('store')?.patchValue(this.loginService.currentstore);
			console.log("OnSubmit: " + JSON.stringify(this.formGroup.value));
			this.quotationService.postQuotationEvent(this.eventtyp, this.formGroup).subscribe((response: any) => {
				console.log("Server response: " + JSON.stringify(response));
				this.id = response.data.order_id;
				this.router.navigate(['/sales/quotation/' + response.data.order_id]);	// Reload Order
				if (typeof cb !== 'undefined') cb(response.data.order_id);
			});
		}
	}

	onActionBarSubmit(formName: String) {
		console.log("onActionBarSubmit [form/tab]: " + formName + "/" + this.selectedtab);
		this.onSubmit(() => { });
	}

	onActionBarAction(action: String) {
		console.log("onActionBarAction: " + action);
		switch (action) {
			case "PRINT_ORDER":
				this.printService.postPrintEvent(constants.OBJECTTYP_ORDER, this.formGroup.get("id")?.value, {}).subscribe((response: any) => {
					console.log("Server response: " + JSON.stringify(response));
					let doc_id = response.data.doc_id;
					let url = environment.server_url + "/doccontent/" + doc_id;
					window.open(url, "_blank");
				});
				break;
			case "DEVALIDATE":
				this.quotationService.postOrderChangeStateEvent(constants.EVENTTYP_CHANGEORDERSTATE, this.id, constants.SALES_ORDER_STATE_CREATED).subscribe((data => {
					this.formGroup.get('state')?.setValue(constants.SALES_ORDER_STATE_CREATED);	// Set state back to "En création"
				}));
				break;
			case "QUOTE":
				this.quotationService.postOrderChangeStateEvent(constants.EVENTTYP_CHANGEORDERSTATE, this.id, constants.SALES_ORDER_STATE_QUOTE).subscribe((data => {
					this.formGroup.get('state')?.setValue(constants.SALES_ORDER_STATE_QUOTE);	// Set state to "QUOTE"
				}));
				break;
			case "PREPARATION":
				this.quotationService.postOrderChangeStateEvent(constants.EVENTTYP_CHANGEORDERSTATE, this.id, constants.SALES_ORDER_STATE_PREPARATION).subscribe((data => {
					this.formGroup.get('state')?.setValue(constants.SALES_ORDER_STATE_PREPARATION);	// Set state to "PREPARATION"
				}));
				break;
			default:
				console.error("[onActionBarAction] Unknown action");
		}
	}

	openCustomerDialog(inputtext: string | undefined = undefined) {
		const searchcriteria = (inputtext ? inputtext : this.formGroup.controls['searchnameClient'].value);
		const dialogRef = this.dialog.open(PartnersComponent, {
			width: '80%',
			height: '80%',
			data: { mode: "DIALOG", "searchcriteria": searchcriteria },
		});
		this.opencustomersubscription = dialogRef.afterClosed().subscribe(newcustomer => {	// result could be undefined 
			// console.log('The dialog was closed: ' + JSON.stringify(newcustomer));
			this.selectCustomer(newcustomer);
		})
	}

	selectCustomer(newcustomer: any) {
		if (newcustomer) {
			this.customer = newcustomer;
			// console.log('this.customer: ' + JSON.stringify(this.customer));
			this.formGroup.get('customer')?.patchValue(newcustomer.id);
			if (this.customer.addresses.length) {
				this.formGroup.get('addr_deliv')?.patchValue(this.customer.addresses[0].id);	// Delivery add. is first one by default
				this.formGroup.get('addr_bill')?.patchValue(this.customer.addresses[0].id);		// Billing add. is first one by default	
			}
		}
	}

	onbsclick() {
		if (this.id == 0) {
			this.onSubmit((order_id: number) => {	// Save quotation. We need an id
				this.openbsdialog(order_id);
			});
		} else {
			this.openbsdialog(this.id);
		}
	}

	openbsdialog(order_id: number) {
		const dialogRef = this.dialog.open(BsDialogComponent, {
			//width: '1280px',
			//height: '900px',
			height: 'unset',
			maxWidth: 'unset',
			data: {
				server_url: environment.server_url,
				order_id: order_id
			},
		});
		this.openbssubscription = dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			if (result !== undefined) {
				console.log("Dialog closing result:\n", JSON.stringify(result));
			}
		});
	}

	/* FIXME DEPRECATED. Now the called dialog is in charge of CV reading */
	onLireCV() {	// Read healthcare card => persist data ; Build apiResponseClients array w/ clients
		return;
		this.mercalinkFselibService.lireCV().then((cvresponse: LireCVHTTPResponse | undefined) => {
			console.log("[onLireCV] cvresponse: ", cvresponse);
			this.spinner.show();
			if (cvresponse?.codeRetour == 0) {
				//console.dir("jsono", JSON.stringify(jsono));
				this.quotationService.postHCCardEvent(constants.EVENTTYP_INSERT, cvresponse.empreinteCV).subscribe({		// Trigger HCCardEvent to create 
					next: (_data: any) => {
						this.formGroup.get('searchnameClient')?.setValue(cvresponse?.empreinteCV.numSecu);
						this.apiResponseClients = [];
						if (cvresponse?.empreinteCV?.beneficiaire) for (const b of cvresponse?.empreinteCV?.beneficiaire) {
							this.apiResponseClients.push({ name: b.nomUsuel, "firstname": b.prenom, quality: b.qualite, datbirth: b.dateNaissance });
						}
						// this.apiResponseClients = [{name: "benefname1", firstname: "beneffirstname1"}];
						this.searchnamefocus = true;	// Set focus on seachname to show apiResponseClients list
						this.cvlookup = true;					// Switch to CV mode
						this.spinner.hide();
					},
					error: (e) => {
						console.error("ERREUR Serveur : ", e.error);
						this.alertService.error("ERREUR Serveur : " + e.error);	// TEMPLATE subscribe call
						this.spinner.hide();
					}
				});
			} else {
				console.log("ERROR response from AreaFSE");
				this.alertService.error("ERROR response from AreaFSE" + "\n" + cvresponse?.libRetour);
				this.spinner.hide();
			}
		})
			.catch(error => {
				console.log(error);
				this.alertService.error(error.message + "\n" + error.error);
				this.spinner.hide();
			});
		this.spinner.hide();
	}


	resetCustomer() {
		this.customer = undefined;
		this.formGroup.get('customer')?.setValue(0);
		this.formGroup.get('searchnameClient')?.setValue('');
		this.cvlookup = false;
	}

	ngOnDestroy() {
		this.routerevents$.unsubscribe();	// This prevents an event triggering on futur page loading
		if (this.openbssubscription) this.openbssubscription.unsubscribe();
		if (this.opencustomersubscription) this.opencustomersubscription.unsubscribe();
	}

	datState(state: number): Date | undefined {	// Get formatted date for current status
		if (state !== undefined && this.formGroup.controls["dates"] as FormArray && (this.formGroup.controls["dates"] as FormArray).at(state) !== undefined) {
			return (this.formGroup.controls["dates"] as FormArray).at(state).value;
		}
		else return undefined;
	}

	selectedIndexChange(e: number) {
		this.selectedtab = e;
	}

	get datCurrentState(): Date | undefined {	// Get formatted date for current status
		let state = this.formGroup.get('state')?.value;
		if (state !== undefined && this.formGroup.controls["dates"] as FormArray && (this.formGroup.controls["dates"] as FormArray).at(state) !== undefined) {
			return (this.formGroup.controls["dates"] as FormArray).at(state).value;
		}
		else return undefined;
	}

	get customer_label(): String {
		let ret = "Non renseigné";
		let name = (this.customer && this.customer.name ? this.customer.name : '');
		let firstname = (this.customer && this.customer.firstname ? this.customer.firstname : '');
		if (firstname + name !== '') ret = firstname + ' ' + name;
		return ret;
	}
}


