<ng-container *ngIf="packagingsControl && packagingsControl.length">
	<form id="mainForm" [formGroup]="gformGroup">
		<div class="pagecontent">
			
			<table class="mat-elevation-z1" style="width: 100%;" *ngIf="lnk_packagings" >
				<tr>
					<th class="mdc-data-table__header-cell">Conditionnement</th> 
					<th class="mdc-data-table__header-cell">Multiple de l'UVC</th>			
					<th class="mdc-data-table__header-cell">Code Barre</th>
					<th class="mdc-data-table__header-cell">Principal</th>
					<th class="mdc-data-table__header-cell">&nbsp;</th>
				</tr>
				<ng-container formArrayName="lnk_packagings">
					<ng-container *ngFor="let varform of lnk_packagings.controls; let i = index" >
						<tr class="form-row">
							<td>
								{{ $any(varform).get("lnk_packaging")?.get('name')?.value }}
							</td>
							<td>
								{{ $any(varform).get("lnk_packaging")?.get('qty')?.value }}
							</td>						
							<td>
			                    <input type = "text" [formControl]="$any(varform).get('barcode')"  >
			                </td>							
			                <td>
								<mat-checkbox [formControl]="$any(varform).get('is_default')" color="primary"></mat-checkbox>
							</td>
							<td>
								<button mat-button color="primary" aria-label="Supprimer un code barre" (click)="delete_packagingval(varform)">
                        			<mat-icon>delete</mat-icon>
      				  			</button>
							</td>			
						</tr>
					</ng-container>
				</ng-container>		
				
				<!-- Add a BC -->
				<tr>
					<td colspan="4"><mat-divider></mat-divider></td>
				</tr>
				<tr>
					<td>
					  <mat-form-field *ngIf="true">
						<mat-select [formControl]="newpackaging">
							<mat-option *ngFor="let p of templatepackagings" value="{{ p.packaging }}">
								{{ p.lnk_packaging.name }}
							</mat-option>
						</mat-select>
					  </mat-form-field>
				 	</td>
					<td>&nbsp;</td>
					<td>
					    <input type = "text" [formControl]="newbarcode" >
					</td>	
					<td>
					  <button mat-button color="primary" aria-label="Ajouter un code barre" (click)="post_packagingval()">
					    <mat-icon>add</mat-icon>
					  </button>
					</td>
				</tr>	                				  
			</table>
		</div>
	</form>
</ng-container>