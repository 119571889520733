import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductComponent } from '@app/sales/product/product.component';
import { ProductsComponent } from '@app/sales/products/products.component';
import { VariantsComponent } from '@app/sales/variants/variants.component';
import { VariantComponent } from '@app/sales/variant/variant.component';
import { SalesPricelistComponent} from '@app/sales/sales-pricelist/sales-pricelist.component';
import { SalesPricelistsComponent} from '@app/sales/sales-pricelists/sales-pricelists.component';
import { SalesPricesComponent} from '@app/sales/sales-prices/sales-prices.component';
import { InvoiceCreationComponent} from '@app/sales/invoice-creation/invoice-creation.component';
import { QuotationsComponent } from '@app/sales/quotations/quotations.component';
import { QuotationComponent } from '@app/sales/quotation/quotation.component';
import { PartnerComponent } from '@app/common/partner/partner.component';
import { PartnersComponent } from '@app/common/partners/partners.component';
import { OfferComponent } from '@app/sales/offer/offer.component';
import { OffersComponent } from '@app/sales/offers/offers.component';
import { StockHomeComponent } from '@app/stock/stock-home/stock-home.component';
import { PinvcsComponent } from '@app/stock/pinvcs/pinvcs.component';
import { PinvcComponent } from '@app/stock/pinvc/pinvc.component';
import { FsestobillComponent } from '@app/fse/fsestobill/fsestobill.component';
import { HomeComponent } from '@app/home/home.component';

import { PricelistComponent} from '@app/purchase/pricelist/pricelist.component';
import { PricelistsComponent} from '@app/purchase/pricelists/pricelists.component';

import { LoginComponent } from '@app/common/login/login.component';
import { AuthGuard } from '@app/shared/authguard';

const routes: Routes = [
	{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] }, 	
	{ path: 'sales/products', component: ProductsComponent, canActivate: [AuthGuard] },
	{ path: 'sales/product', component: ProductComponent, canActivate: [AuthGuard] },	
	{ path: 'sales/product/:id', component: ProductComponent, canActivate: [AuthGuard] },	
	{ path: 'sales/variants/:template', component: VariantsComponent, canActivate: [AuthGuard] },
	{ path: 'sales/variant/:template/:id', component: VariantComponent, canActivate: [AuthGuard] },
	{ path: 'sales/prices', component: SalesPricesComponent, canActivate: [AuthGuard] },	
	{ path: 'sales/pricelists', component: SalesPricelistsComponent, canActivate: [AuthGuard] },
	{ path: 'sales/pricelist/:id', component: SalesPricelistComponent, canActivate: [AuthGuard] },
	{ path: 'sales/quotations', component: QuotationsComponent, canActivate: [AuthGuard] },
	{ path: 'sales/quotation', component: QuotationComponent, canActivate: [AuthGuard] },
	{ path: 'sales/quotation/:id', component: QuotationComponent, canActivate: [AuthGuard] },
	{ path: 'sales/invoice-creation', component: InvoiceCreationComponent, canActivate: [AuthGuard] },
	{ path: 'sales/offer', component: OfferComponent, canActivate: [AuthGuard] },
	{ path: 'sales/offers', component: OffersComponent, canActivate: [AuthGuard] },	
	{ path: 'common/partners', component: PartnersComponent, canActivate: [AuthGuard] },
	{ path: 'common/partner', component: PartnerComponent, canActivate: [AuthGuard] },	
	{ path: 'common/partner/:id', component: PartnerComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
	{ path: 'purchase/pricelists', component: PricelistsComponent, canActivate: [AuthGuard] },
	{ path: 'purchase/pricelist/:id', component: PricelistComponent, canActivate: [AuthGuard] },
	{ path: 'stock/home', component: StockHomeComponent, canActivate: [AuthGuard] },
	{ path: 'stock/pinvcs', component: PinvcsComponent, canActivate: [AuthGuard] },
	{ path: 'stock/pinvc/:id/:store', component: PinvcComponent, canActivate: [AuthGuard] },	
	{ path: 'fse/fsestobill', component: FsestobillComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: LoginComponent },
	//{ path: '', redirectTo: '/home', pathMatch: 'full' },
	//{ path: '', component: HomeComponent },
	{ path: '**', component: HomeComponent, canActivate: [AuthGuard] },	// Catch all	
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
