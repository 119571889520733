<app-menu-common url="sales"></app-menu-common>
<div class="flexcolumn lightborder">
	<div class="pageheader flexcolumn" (keydown)="onKeydown($event)">
		<div class="flexgrid" >
			<div class="col1">
				<div class="grey">Recherche Offres</div>
				<button mat-flat-button color="primary" type="button"  style="margin-top: 8px;" [routerLink] = "['/sales/offer']">Nouvelle Offre</button>		
			</div>
			<div class="col2">
				<div>
					<mat-form-field id="nameinputgroup">
					  <input  #searchNameInput matInput [(ngModel)]="searchname" placeholder="Rechercher par nom"  autofocus >
					  <button matSuffix mat-icon-button aria-label="Clear" (click)="searchname=''">
					    <mat-icon>close</mat-icon>
					  </button>	  
					</mat-form-field>
					</div>
			</div>
		</div>
		<div class="actionline">
	      <span class="spacer"></span>
	      <button class="center largebutton" mat-flat-button color="primary" aria-label="Rechercher" (click)="onSearch()">
		    <mat-icon>search</mat-icon>
	      </button>
	      &nbsp;&nbsp;
	      <button class="center largebutton" mat-flat-button color="basic" aria-label="Effacer" (click)="onCancel()">
		    <mat-icon>clear</mat-icon>
	      </button>      
	      <span class="spacer"></span>
	    </div>	
	</div>
</div>
