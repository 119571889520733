import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public isLoading: boolean = false;
  private timeoutRef: any;

  constructor(private alert: AlertService) { }

  show() {
    this.isLoading = true;

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }

    this.timeoutRef = setTimeout(() => {
      this.hide();
      this.alert.error("Timeout");
    }, 5000);
    }

  hide() {
    clearTimeout(this.timeoutRef);
    this.isLoading = false;
  }
}
