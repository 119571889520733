import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTabsModule, MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';
import { Subject, BehaviorSubject, Observable, pipe, Subscription } from 'rxjs';

import { ProductService } from '@app/sales/product/product.service';
import { PartnerService } from '@app/common/partner/partner.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';
import { ValuesService } from '@app/common/values/values.service';
import { AddressesComponent } from "@app/common/partner/addresses/addresses.component";
import { ContactsComponent } from './contacts/contacts.component';
import { AddressesComponent as AddressesComponent_1 } from './addresses/addresses.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActionBarComponent } from '@app/shared/action-bar/action-bar.component';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';
import * as constants from "@app/shared/constants";

@Component({
	selector: 'app-partner',
	templateUrl: './partner.component.html',
	styleUrls: ['./partner.component.scss', '../../styles-custom.scss'],
	standalone: true,
	imports: [MenuCommonComponent, NgIf, ActionBarComponent, FormsModule, ReactiveFormsModule, MatCheckboxModule, NgFor, MatRadioModule, MatFormFieldModule, MatInputModule, MatTabsModule, AddressesComponent_1, ContactsComponent]
})
export class PartnerComponent {

	constructor(private route: ActivatedRoute, private valuesService: ValuesService, private location: Location, private partnerService: PartnerService, private productService: ProductService, private router: Router, private fb: FormBuilder, private spinner: SpinnerService, private alert: AlertService, @Optional() public dialogRef: MatDialogRef<PartnerComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

	public id: number = 0;
	public eventtyp: number = 1;			// Insert event by default
	public activities: any;

	public formGroup!: FormGroup;

	@ViewChild(AddressesComponent)
	private addressesComponent!: AddressesComponent;

	private routerEventSubscription!: Subscription;
	private getvaluesSubscription!: Subscription;

	ngOnInit(): void {
		this.id = Number(this.route.snapshot.paramMap.get('id'));

		if (this.id === 0) { // New partner
			this.formGroup = this.fb.group({
				id: new FormControl(0),			// Fake id to post a valid integer 
				civility: new FormControl('0'),
				firstname: new FormControl(''),
				name: new FormControl(''),
				typ: new FormControl(1),			// Default to individual
				typpay: new FormControl(0),		// Default to "comptant"
				roles: this.fb.array([3]),		// Default to role customer only
				email: new FormControl(''),
				tel1: new FormControl(''),
				tel2: new FormControl(''),

				street1: new FormControl(''),	// Will be send null. Data is in addresses
				street2: new FormControl(''),
				street3: new FormControl(''),
				zip: new FormControl(''),
				city: new FormControl(''),
				country: new FormControl(''),

				addresses: this.fb.array([]),
			});
		} else {	// Get partner from service, deserialize into formGroup
			this.loadPartner()
		}

		this.getvaluesSubscription = this.valuesService.getValues$(constants.C_VALUES_CUSTOMER_ACT)	// Get activities
			.subscribe((data: any) => {
				this.activities = data;
			});

		this.routerEventSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				console.log("NavigationEnd, reloading");
				this.loadPartner();
			}
		});
	}

	loadPartner() {				// Reload this.formGroup from API call
		this.spinner.show();
		this.eventtyp = 2; 										// Update mode. Will issue an update event
		// if (this.formGroup) this.formGroup.reset();	// Does nor work!
		this.partnerService.getPartnerAsFormGroup(this.id)
			.subscribe({
				next: (data: FormGroup) => {
					if (this.formGroup) {			// After submit, formgroup exists
						// this.formGroup.setValue(data);	// This should work! Crashes w/ error "no control with name: 'id'"
						this.formGroup.patchValue(data);
						console.log("Patching data: " + JSON.stringify(data.getRawValue()));

						// Processing addresses. Clear formarray then push from data					
						let fgaddrs: FormArray = this.formGroup.controls["addresses"] as FormArray;
						fgaddrs.clear();
						let addrs: FormArray = data.controls["addresses"] as FormArray;
						if (addrs.length) {
							addrs.controls.forEach((line) => {
								let linefg: FormGroup = line as FormGroup;
								console.log("Line id - is_default: " + linefg.get("id")?.value + " + " + linefg.get("is_default")?.value);
								fgaddrs.push(line);
							});
						}
						this.formGroup.controls["civility"].patchValue(String(this.formGroup.controls["civility"].value));	// Cast civility to String. Needed by radio button. WARNING: PatchValue forces an int!
						this.formGroup.controls["typpay"].patchValue(String(this.formGroup.controls["typpay"].value));	// Cast civility to String. Needed by radio button. WARNING: PatchValue forces an int!
						console.log("this.formGroup: " + JSON.stringify(this.formGroup.getRawValue()));
						this.addressesComponent.loadPartner();	// After submit, reset child properties
					} else {	// No formgroup, first loading
						console.log("Setting data: " + JSON.stringify(data.getRawValue(), null, 2));
						this.formGroup = data;
						this.formGroup.controls["civility"].patchValue(String(this.formGroup.controls["civility"].value));	// Cast civility to String. Needed by radio button. WARNING: PatchValue forces an int!					
						this.formGroup.controls["typpay"].patchValue(String(this.formGroup.controls["typpay"].value));	// Cast civility to String. Needed by radio button. WARNING: PatchValue forces an int!					
					}
					this.spinner.hide();
					// this.addressesComponent.loadPartner();
				},
				error: (e) => {
					console.log("ERREUR Serveur : ", e.error);
					this.spinner.hide();
					this.alert.error("ERREUR Serveur : " + e.error);
				}
			});
	}

	onActionBarSubmit(formName: String) {
		console.log("onActionBarSubmit: " + formName);
		this.onSubmit();
	}

	onSubmit() {
		this.addressesComponent.onSubmit(this.formGroup);
		this.formGroup.controls["civility"].setValue(parseInt(this.formGroup.controls["civility"].value));	// Cast civility back to int
		this.formGroup.controls["typpay"].setValue(parseInt(this.formGroup.controls["typpay"].value));	// Cast typpay back to int
		console.log("OnSubmit: " + JSON.stringify(this.formGroup.value));
		this.partnerService.postPartnerEvent(this.eventtyp, this.formGroup).subscribe((response: any) => {
			console.log("Server response: " + JSON.stringify(response));
			// this.formGroup.controls["civility"].setValue('' + this.formGroup.controls["civility"].value);	// Cast civility back to string
			this.addressesComponent.postSubmit();
			this.id = response.data.partner_id;		// Update partner id(?)
			this.router.navigate(['/common/partner/' + response.data.partner_id]);	// Reload partner
		});
	}

	ngOnDestroy() {
		this.routerEventSubscription.unsubscribe();
		this.getvaluesSubscription.unsubscribe();
	}
}
