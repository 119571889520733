<app-menu-common url="stock"></app-menu-common>
<div class="flexcolumn lightborder">
	<div class="pageheader flexcolumn">
		<div class="flexgrid" >
			<div class="col1">
				<div class="grey">Recherche Inventaires</div>
			</div>
			<div class="col2">
			</div>
		</div>
		<div class="actionline">
	      <span class="spacer"></span>
	      <button class="center largebutton" mat-flat-button color="primary" aria-label="Rechercher" (click)="onSearch()">
		    <mat-icon>search</mat-icon>
	      </button>
	      &nbsp;&nbsp;
	      <button class="center largebutton" mat-flat-button color="basic" aria-label="Effacer" (click)="onCancel()">
		    <mat-icon>clear</mat-icon>
	      </button>      
	      <span class="spacer"></span>
		</div>		
	</div>
	
		<div class="pagecontent flexrow" *ngIf="dataSource" >
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
				<ng-container matColumnDef="store">
					<th mat-header-cell *matHeaderCellDef>Site</th>
					<td mat-cell *matCellDef="let p" >{{ p.lnk_store.name }}</td>
				</ng-container>			
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Nom</th>
					<td mat-cell *matCellDef="let p" >{{ p.name }}</td>
				</ng-container>
				<ng-container matColumnDef="datstart">
					<th mat-header-cell *matHeaderCellDef>Dat. Début</th>
					<td mat-cell *matCellDef="let p" >{{ p.datstart  | date:'dd/MM/yy':'Local'}}</td>
				</ng-container>
				<ng-container matColumnDef="datend">
					<th mat-header-cell *matHeaderCellDef>Dat. fin</th>
					<td mat-cell *matCellDef="let p" >{{ p.datend  | date:'dd/MM/yy':'Local'}}</td>
				</ng-container>				
				<ng-container matColumnDef="scan">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let row">
			      <button class="" mat-flat-button color="primary" aria-label="Scan" (click)="onScan(row.id, row.store)">
				    	<mat-icon>barcode_reader</mat-icon>
			      </button>			
		      </mat-cell>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClickRow(row, $event)" class="row"></tr>			
			</table>
		</div>	
</div>
