import { Component } from '@angular/core';

import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';

@Component({
  selector: 'app-stock-home',
  standalone: true,
  imports: [MenuCommonComponent],
  templateUrl: './stock-home.component.html',
  styleUrl: './stock-home.component.scss'
})
export class StockHomeComponent {

}
