import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-invoice-creation-action',
    templateUrl: './invoice-creation-action.component.html',
    styleUrls: ['./invoice-creation-action.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule]
})

export class InvoiceCreationActionComponent {

	@Output() submitEmitter = new EventEmitter<String>();	// Emits the triggered action

	onActionBarAction(action: String) {
		console.log("InvoiceCreationActionComponent - onActionBarAction: " + action);
		this.submitEmitter.emit(action);
	}

}