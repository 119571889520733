import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProductsService } from '../products/products.service';
import { SalesService } from '../sales.service';
import { MatTableModule } from '@angular/material/table';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
    selector: 'app-sales-pricelists',
    templateUrl: './sales-pricelists.component.html',
    styleUrls: ['./sales-pricelists.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule, NgIf, MatTableModule]
})
export class SalesPricelistsComponent {

	constructor(private salesService:SalesService, private productsService: ProductsService, private router: Router, private route: ActivatedRoute) { }
	displayedColumns: string[] = ['id', 'name', 'pname'];
	dataSource: any[] = [];
	search: string = "tena";	// Search field
	
	ngOnInit(): void {
	}

	onSearch(): void {
		console.log("onSearch: " + this.search);
		
		this.salesService.getPriceLists(this.search)
			.subscribe((data: any) => {
				this.dataSource = data;
			});
	}
	
	onKeydown(event:any) {
		if (event.key === "Enter") {
			console.log(event);
			this.onSearch();
		}
	}
		
	onClickRow(row: any): void {
		console.log("clicked: " + row.id);
		this.router.navigate(['/sales/pricelist/' + row.id]);
	}
	
}
