import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Event, Partner, User } from '@app/shared/_models/common';
import * as moment from "moment";																		// FIXME replace w/days.js
import * as constants from "@app/shared/constants";
import { jwtDecode } from "jwt-decode";

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginService {
	private userSubject: BehaviorSubject<User | undefined>;			// Contains logged user OR undefined 
	private storeSubject: BehaviorSubject<number | undefined>;	// Contains current selected store (among user stores) OR undefined
	public user$: Observable<User | undefined>;									// Observable (and public) version of userSubject
	public store$: Observable<number | undefined>;							// Observable (and public) version of storeSubject

	constructor(private router: Router, private http: HttpClient) {
		//this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
		this.userSubject = new BehaviorSubject(this.currentuser());
		this.storeSubject = new BehaviorSubject(this.currentuser()?.store);
		this.user$ = this.userSubject.asObservable();
		this.store$ = this.storeSubject.asObservable();
	}

	private currentuser(): User | undefined {	// Get user from storage. Only used by constructor
		// return this.loggeduser;
		if (moment().isBefore(this.getExpiration()) && localStorage.getItem("token") != null) {
			let user = new User(jwtDecode(localStorage.getItem("token")!));
			return user;
		}
		return undefined;
	}

	public get loggeduser(): User | undefined {	// Public method to get user. Returns current value of userSubject
		return this.userSubject.value;
	}

	public set currentstore(store: number) {
		if (this.loggeduser) this.loggeduser.currentstore = store;
		this.storeSubject.next(store);
	}

	public get currentstore(): number | undefined {									// Can be replaced by store$
		if (this.loggeduser) return this.loggeduser.currentstore;
		return undefined;	// Should not happen
	}

	public login(login: string, password: string): Observable<boolean> {
		console.log('login: ' + login + ' - ' + password);
		console.log('pwd hash: ' + this.hashCode(password));

		/*
				// DEV Only backdoor to skip server validation
				if (this.hashCode(password) === 41899) {
					const u: User = { id: ' ', username: 'admin' };
					localStorage.setItem('user', JSON.stringify(u));
					this.userSubject.next(u);
					return this.user$;
				}
		*/
		let event: Event = {
			id: 0,
			typ: constants.EVENTTYP_USER_AUTHENTICATE,			// 1: Insert, 2: Update, 3: Delete
			objectid: 0,
			objecttyp: constants.OBJECTTYP_USER,						// User
			data: { 'login': login, 'password': password }
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader })
			.pipe(
				tap(res => this.setSession(res)),
				shareReplay());
	}

	private setSession(authResult: any) {
		const expiresAt = moment().add(authResult.data.exp, 'second');
		localStorage.setItem('token', authResult.data.token);
		localStorage.setItem("exp", JSON.stringify(expiresAt.valueOf()));
		console.log("Token: " + authResult.data.token);
		console.log("Exp: " + this.getExpiration()?.format());
		const decoded = jwtDecode(authResult.data.token);
		console.log("decoded: " + JSON.stringify(decoded));
		const currentuser: User = new User(decoded);
		this.userSubject.next(currentuser);
		this.storeSubject.next(currentuser.store);
	}

	public logout() {
		// remove token from local storage and emit next subject
		localStorage.removeItem('token');
		localStorage.removeItem('exp');
		this.userSubject.next(undefined);
		this.storeSubject.next(undefined);
		this.router.navigate(['/login']);
	}

	private getExpiration() {
		let expiration = localStorage.getItem("exp");
		if (expiration) return moment(JSON.parse(expiration));
		else return null;
	}

	private hashCode(s: String): number {	// Helper function to valid pwd locally
		let ret = 0;
		if (s.length === 0) return ret;
		for (let i = 0; i < s.length; i++) {
			const chr = s.charCodeAt(i);
			ret = ((ret << 5) - ret) + chr;
			ret |= 0; // Convert to 32bit integer
		}
		return ret;
	}
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const idToken = localStorage.getItem("token");
		if (idToken) {
			const cloned = req.clone({ headers: req.headers.set("Authorization", "Bearer " + idToken) });
			return next.handle(cloned);
		}
		else {
			return next.handle(req);
		}
	}
}

export const authInterceptorProvider: Provider =
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true };

