<app-menu-common url="sales"></app-menu-common>
<div class="flexcolumn lightborder">
	<div class="pageheader flexcolumn" (keydown)="onKeydown($event)">
		<div class="flexgrid" >
			<div class="col1">
				<div class="grey">Recherche Commandes</div>
				<button mat-flat-button color="primary" type="button"  style="margin-top: 8px;" [routerLink] = "['/sales/quotation']">Nouvelle commande</button>		
			</div>
			<div class="col2">
				<div>
					<mat-form-field id="nameinputgroup">
					  <input  #searchNameInput matInput [(ngModel)]="searchname" placeholder="Rechercher par nom / prénom"  autofocus >
					  <button matSuffix mat-icon-button aria-label="Clear" (click)="searchname=''">
					    <mat-icon>close</mat-icon>
					  </button>	  
					</mat-form-field>
					</div>
					<div>
						<label class="weight-500">Statut&nbsp;</label>
						<select [(ngModel)]="searchstate">
							<option *ngFor="let su of sales_order_state | keyvalue" [value]="su.key">{{su.value}}</option>
						</select>
					</div>				
			</div>
		</div>
		<div class="actionline">
	      <span class="spacer"></span>
	      <button class="center largebutton" mat-flat-button color="primary" aria-label="Rechercher" (click)="onSearch()">
		    <mat-icon>search</mat-icon>
	      </button>
	      &nbsp;&nbsp;
	      <button class="center largebutton" mat-flat-button color="basic" aria-label="Effacer" (click)="onCancel()">
		    <mat-icon>clear</mat-icon>
	      </button>      
	      <span class="spacer"></span>
		</div>
	</div>
		<div class="pagecontent flexrow" *ngIf="dataSource" >
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef>Id</th>
					<td mat-cell *matCellDef="let p" >{{ p.id }}</td>
				</ng-container>
				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef>Dat. Cré</th>
					<td mat-cell *matCellDef="let p" >{{ p.dates[1]  | date:'dd/MM/yy':'Local'}}</td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef>Status</th>
					<td mat-cell *matCellDef="let p" >{{ sales_order_state.get(p.state) }}</td>
				</ng-container>
				<ng-container matColumnDef="customer">
					<th mat-header-cell *matHeaderCellDef>Client</th>
					<td mat-cell *matCellDef="let p" >{{ p.lnk_customer?.name }}&nbsp;{{ p.lnk_customer?.firstname }}</td>
				</ng-container>						  
				<ng-container matColumnDef="amount_taxi">
					<th mat-header-cell *matHeaderCellDef>Montant TTC</th>
					<td mat-cell *matCellDef="let p" >{{ p.amount_taxi }}</td>
				</ng-container>			
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClickRow(row, $event)" (mouseover)="onMouseOver(row)" (mouseout)="onMouseOut(row)" class="row"></tr>			
			</table>
			<div class="rightcontainer">
				<app-quotationdetails [mouseover$]="mouseoverSubject.asObservable()" [mouseout$]="mouseoutSubject.asObservable()"></app-quotationdetails>
			</div>			
		</div>
</div>

