import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Partner } from '@app/shared/_models/common'

let fb = new FormBuilder();

export class Product {
	id?: number;
	name?: string;
	template?: number;
	partnbloc?: string;
	partnbsup?: string;
	partnbman?: string;
	is_sold?: string;
	is_purchased?: string;

	attributestring?: string;
	seq?: number;
	price_taxx?: number;
	price_taxi?: number;

	lnk_template?: ProductTpl = new ProductTpl;
	lnk_purchase_price_hist?: PurchasePriceHist = new PurchasePriceHist;

	static asFormGroup(p?: Product): FormGroup {
		const lnk_template: AbstractControl<any> = (p?.lnk_template ? ProductTpl.asFormGroup(p?.lnk_template) : new FormControl(undefined));
		const lnk_purchase_price_hist: AbstractControl<any> = (p?.lnk_purchase_price_hist ? PurchasePriceHist.asFormGroup(p.lnk_purchase_price_hist) : new FormControl(undefined));
		const fg = new FormGroup({
			id: new FormControl(p?.id),
			name: new FormControl(p?.name),
			attributestring: new FormControl(p?.attributestring),
			is_sold: new FormControl(p?.is_sold),
			is_purchased: new FormControl(p?.is_purchased),
			template: new FormControl(p?.template),
			seq: new FormControl(p?.seq),
			partnbloc: new FormControl(p?.partnbloc),
			partnbman: new FormControl(p?.partnbman),
			partnbsup: new FormControl(p?.partnbsup),

			price_taxx: new FormControl(p?.price_taxx),
			price_taxi: new FormControl(p?.price_taxi),

			lnk_template: lnk_template,
			lnk_purchase_price_hist: lnk_purchase_price_hist,
		});
		return fg;
	}
	
	static asLinkedProduct(p?: Product): LinkedProduct {
		const lp: LinkedProduct = {
			id:p?.id,
			template:p?.template,	// ... Could implements more properties
			name:p?.name,
			price_taxi:p?.price_taxi
		}
		return lp;
	}
}

export class ProductTpl {
	id?: number;
	name?: string;
	vat?: number;               // VAT code
	vat_rate?: number;					// vat rate
	baseuom?: number;           // Base UOM = Unité de Vente Conso.
	unitpriceuom?: number;      // Unité de mesure du px unitaite
	manuf?: number;
	data?: any;								// Jsonb column

	lnk_partner_manuf?: Partner;
	lnk_products: LinkedProduct[] = new Array;   // Products
	lnk_lpp?: FseLpp;

	attributes?: any;
	has_variants?: boolean;
	unique_variant?: number;

	static asFormGroup(productTpl: ProductTpl): FormGroup {
		const fg = new FormGroup({
			id: new FormControl(productTpl.id),
			name: new FormControl({ value: productTpl.name, disabled: false }),
			vat: new FormControl({ value: productTpl.vat, disabled: false }),
			vat_rate: new FormControl({ value: productTpl.vat_rate, disabled: false }),
			baseuom: new FormControl({ value: productTpl.baseuom, disabled: false }),
			unitpriceuom: new FormControl({ value: productTpl.baseuom, disabled: false }),
			data: new FormControl({ value: productTpl.data, disabled: false }),

			lnk_partner_manuf: Partner.asFormGroup(productTpl.lnk_partner_manuf),
			lnk_products: (productTpl?.lnk_products ? new FormArray(productTpl?.lnk_products?.map(LinkedProduct.asFormGroup)) : fb.array([])),
			lnk_lpp: FseLpp.asFormGroup(productTpl.lnk_lpp),

			has_variants: new FormControl({ value: productTpl.has_variants, disabled: false }),
			unique_variant: new FormControl({ value: productTpl.unique_variant, disabled: false }),
			attributes: fb.array([]),
		});
		return fg;
	}
}

export class LinkedProduct {
	id?: number;
	name?: string;
	attributestring?: string;
	price_taxi?: number;
	price_taxx?: number;
	is_sold?: boolean;
	is_purchased?: boolean;
	template?: number;
	seq?: number;
	partnbloc?: string;
	partnbman?: string;
	partnbsup?: string;

	static asFormGroup(p?: LinkedProduct): FormGroup {
		const fg = new FormGroup({
			id: new FormControl(p?.id),
			name: new FormControl(p?.name),
			attributestring: new FormControl(p?.attributestring),
			price_taxi: new FormControl(p?.price_taxi),
			is_sold: new FormControl(p?.is_sold),
			is_purchased: new FormControl(p?.is_purchased),
			template: new FormControl(p?.template),
			seq: new FormControl(p?.seq),
			partnbloc: new FormControl(p?.partnbloc),
			partnbman: new FormControl(p?.partnbman),
			partnbsup: new FormControl(p?.partnbsup),
		});
		return fg;
	}
}

export class TemplateAttribute {
	template!: number;
	attribute!: number;
	seq!: number;

	lnk_product_attribute: Attribute | undefined;

	static asFormGroup(ta?: TemplateAttribute): FormGroup {
		if (!ta) return new FormGroup({});
		const fg = new FormGroup<{
			template: FormControl<number | null>,
			attribute: FormControl<number | null>,
			seq: FormControl<number | null>,

			lnk_product_attribute: FormGroup,
		}>
			({
				template: new FormControl(ta?.template),
				attribute: new FormControl(ta?.attribute),
				seq: new FormControl(ta?.seq),

				lnk_product_attribute: Attribute.asFormGroup(ta.lnk_product_attribute),
			});
		return fg;
	}
}

export class Attribute {
	id!: number;
	seq!: number;
	name!: string;
	shortname!: string;

	static asFormGroup(a?: Attribute): FormGroup {
		if (!a) return new FormGroup({});
		const fg = new FormGroup<{
			id: FormControl<number | null>,
			seq: FormControl<number | null>,
			name: FormControl<string | null>,
			shortname: FormControl<string | null>,
		}>
			({
				id: new FormControl(a?.id),
				seq: new FormControl(a?.seq),
				name: new FormControl(a?.name),
				shortname: new FormControl(a?.shortname),
			});
		return fg;
	}
}

// Table product_packaging
export class ProductPackaging {
	id?: number;
	name?: string;
	qty?: number;
}

export class Packaging {
	id?: number;
	name?: string;
	qty?: number;
	barcode?: string;
	static asFormGroup(p?: Packaging): FormGroup {
		const fg = new FormGroup({
			id: new FormControl(p?.id),
			name: new FormControl(p?.name),
			qty: new FormControl(p?.qty),
			barcode: new FormControl(p?.barcode),
		});
		return fg;
	};
}

export class TplPackaging {
	template?: number;
	packaging?: number;
	seq?: number;
	barcode?: string;

	lnk_packaging?: Packaging;

	static asFormGroup(p?: TplPackaging): FormGroup {
		const fg = new FormGroup({
			template: new FormControl(p?.template),
			packaging: new FormControl(p?.packaging),
			seq: new FormControl(p?.seq),
			barcode: new FormControl(p?.barcode),

			lnk_packaging: Packaging.asFormGroup(p?.lnk_packaging)
		});
		return fg;
	}
}

export class ProductPackagingVal {
	id?: number;
	product?: number;
	packaging?: number;
	is_default?: boolean;
	barcode?: string;

	lnk_product?: Product;
	lnk_packaging?: Packaging;

	static asFormGroup(p?: ProductPackagingVal): FormGroup {

		const fg = new FormGroup({
			id: new FormControl(p?.id),
			product: new FormControl(p?.product),
			packaging: new FormControl(p?.packaging),
			is_default: new FormControl(p?.is_default),
			barcode: new FormControl(p?.barcode),

			lnk_product: Product.asFormGroup(p?.lnk_product),
			lnk_packaging: Packaging.asFormGroup(p?.lnk_packaging)
		});
		return fg;
	}
}

export class PurchasePriceHist {
	id?: number;
	is_active?: boolean;
	price?: number;
	pricelist?: number;
	product?: number;
	supplier?: number;

	static asFormGroup(pph?: PurchasePriceHist): FormGroup {
		const fg = new FormGroup({
			id: new FormControl(pph?.id),
			is_active: new FormControl(pph?.is_active),
			price: new FormControl(pph?.price),
			pricelist: new FormControl(pph?.pricelist),
			product: new FormControl(pph?.product),
			supplier: new FormControl(pph?.supplier),
		});

		return fg;
	}
}

export class FseLpp {
	id?: string;
	name?: string;
	datend?: Date;

	static asFormGroup(lpp?: FseLpp): FormGroup {

		const fg = new FormGroup({
			id: new FormControl(lpp?.id),
			name: new FormControl(lpp?.name),
			datend: new FormControl(lpp?.datend),
		});
		return fg;
	}
}

