<app-menu-common url="sales"></app-menu-common>


<ng-container *ngIf="formGroup">
	<div class="mat-headline-1 grey">
		<div class="breadcrumb" >
			<span *ngIf="formGroup.get('id')?.value === 0">Nouveau Tiers</span>
			<span *ngIf="formGroup.get('id')?.value !== 0">Tiers</span>
			<span class="spacer"></span>
			<div>#{{ id }}</div>
		</div>
	</div>
	
	
	<app-action-bar form="mainform" (submitEmitter)="onActionBarSubmit($event)"> </app-action-bar>
	
	<div class="pagecontent">
		<form id="mainForm" [formGroup] = "formGroup" (ngSubmit)="onSubmit()">		
			<div class="maincontent">
				<div class="types">
					<div>
						<label class="">Type de tiers&nbsp;:&nbsp;</label>
						<mat-checkbox [disabled]="true" [checked]="true">Client</mat-checkbox>
						<mat-checkbox [disabled]="true" [checked]="false">Fournisseur</mat-checkbox>
						<mat-checkbox [disabled]="true" [checked]="false">Fabricant</mat-checkbox>
					</div>
					
					<div>
						<label class="">Activité&nbsp;:&nbsp;</label>
						<select formControlName="typ">
							<option *ngFor="let su of activities" [value]="su.id">{{su.name}}</option>
						</select>
					</div>
				</div>
				<div class="flexrow radiogroup">
					<label class="padding">Conditions de paiement&nbsp;:</label>
					<span class="radioborder">
						<input type="radio" id="comptant" name="typpay" value="0" formControlName="typpay">
						<label for="comptant">Comptant</label>
					</span>
					<span class="radioborder">
						<input type="radio" id="fdm" name="typpay" value="1" formControlName="typpay">
						<label for="fdm">30j fin de mois</label>
					</span>				
				</div>

				<div class="container" >
					<div class="col" id="col1">
						<div class="container">
							<div class="col30 padding">
								<div>Civilité</div>
							</div>
							<div class="col">
								<mat-radio-group aria-label="Civilité" formControlName="civility">
									<mat-radio-button value="0">Non précisée</mat-radio-button>
								  <mat-radio-button value="1">M.</mat-radio-button>
								  <mat-radio-button value="2">Mme</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>				
						<div class="container">
							<div class="col30 padding">
								<div>Nom / Raison sociale</div>
							</div>
							<div class="col">
									<mat-form-field >
										<input matInput formControlName="name" autocomplete="off">
									</mat-form-field>
							</div>
						</div>								
						<div class="container">
							<div class="col30 padding">
								<div>Prénom</div>
							</div>
							<div class="col">
									<mat-form-field >
										<input matInput formControlName="firstname" autocomplete="given-name">
									</mat-form-field>
							</div>
						</div>

						<div class="container">
							<div class="col30 padding">
								<div>Tél. 1</div>
							</div>
							<div class="col">
									<mat-form-field >
										<input matInput formControlName="tel1" autocomplete="off">
									</mat-form-field>
							</div>
						</div>												
					</div>
					
					<div class="col" id="col2">
						<div class="container">
							<div class="col30 padding">
								<div>e-mail</div>
							</div>
							<div class="col">
									<mat-form-field>
										<input matInput formControlName="email" autocomplete="email">
									</mat-form-field>
							</div>
						</div>
						
						<div class="container">
							<div class="col30 padding">
								<div>Tél. 2</div>
							</div>
							<div class="col">
									<mat-form-field >
										<input matInput formControlName="tel2" autocomplete="off">
									</mat-form-field>
							</div>
						</div>						
					</div>
		
				</div>
				<mat-tab-group >
					<mat-tab label="Adresses" *ngIf="true">
						<app-addresses></app-addresses>
					</mat-tab>
					<mat-tab label="Contacts" *ngIf="true">
						<app-contacts></app-contacts>
					</mat-tab>
					
				</mat-tab-group>
			</div>
		</form>

	</div>
</ng-container>