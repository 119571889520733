/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import '@angular/common/locales/global/fr';

import { importProvidersFrom, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app/app.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { authInterceptorProvider } from '@app/common/login.service';

bootstrapApplication(AppComponent, {
    providers: [
				provideHttpClient(),
        importProvidersFrom( BrowserModule, FormsModule, ReactiveFormsModule, AppRoutingModule, MatAutocompleteModule, MatToolbarModule, MatButtonModule, MatIconModule, MatSlideToggleModule, MatMenuModule, MatTableModule, MatSelectModule, MatInputModule, MatFormFieldModule, MatTabsModule, MatCheckboxModule, MatChipsModule, MatTreeModule, MatTooltipModule, MatCardModule, MatDividerModule, MatProgressSpinnerModule, MatSnackBarModule, MatDialogModule, MatRadioModule, MatExpansionModule, MatDatepickerModule, MatNativeDateModule),
        provideAnimations(),
        authInterceptorProvider,
        {provide: LOCALE_ID, useValue: 'fr' },					// TODO Change to fr-FR
        //provideHttpClient(withInterceptorsFromDi()),
        
    ]
})
  .catch(err => console.error(err));
