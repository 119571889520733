<ng-container *ngIf="gformGroup">
		<div class="flexgrid" [formGroup]="gformGroup">
					<div class="r1c1 flexrow">
						<div class="col30 padding">
							<div>Référence locale</div>
						</div>
						<div class="col">
								<mat-form-field >
									<input matInput formControlName="partnbloc" (ngModelChange)="onChangeName()">
								</mat-form-field>
						</div>
					</div>
					<div class="r1c2 flexrow">
						<div class="col30 padding">
							<div>Référence fabricant</div>
						</div>
						<div class="col">
								<mat-form-field>
									<input matInput formControlName="partnbman">
								</mat-form-field>
						</div>
					</div>

					<div class="r1c3 flexrow">
						<div class="col30 padding">
							<div>Référence distributeur</div>
						</div>
						<div class="col">
								<mat-form-field>
									<input matInput formControlName="partnbsup">
								</mat-form-field>
						</div>
					</div>
					
					<div class="r2c1 flexrow">
						<div class="padding">Prix d'achat HT </div>
						<ng-container formGroupName="lnk_purchase_price_hist">						
							<mat-form-field class="mat-form-field-readonly price" >
								<input matInput formControlName="price" readonly>
							</mat-form-field>
						</ng-container>

						<div class="padding">Prix de vente HT </div>
							<mat-form-field class="mat-form-field-readonly price">
								<input matInput formControlName="price_taxx" readonly>
							</mat-form-field>
						<div class="padding">Tx. Marge </div>
						<mat-form-field class="mat-form-field-readonly price">
							<input matInput readonly formControlName="margin_pct">
						</mat-form-field>						
					</div>					
					
					<div class="r2c3 flexrow">
						<div class="col30 padding">
							<div>Prix de vente TTC</div>
						</div>
						<div class="col">
								<mat-form-field class="price">
									<input matInput formControlName="price_taxi" price (ngModelChange)="onChangePrice()">
								</mat-form-field>
						</div>
					</div>							
		</div>
</ng-container>