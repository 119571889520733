import { Component, Inject, Optional, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, ControlContainer, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTabsModule, MatTabChangeEvent } from '@angular/material/tabs';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';
import { ProductService } from '@app/sales/product/product.service';
import { PartnerService } from '@app/common/partner/partner.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { ValuesService } from '@app/common/values/values.service';
import { Partner } from '@app/shared/_models/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
    styleUrls: ['./addresses.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, MatButtonModule, MatTooltipModule, MatIconModule, MatFormFieldModule]
})
export class AddressesComponent {
	constructor(private route: ActivatedRoute, private valuesService: ValuesService, private location: Location, private partnerService: PartnerService, private productService: ProductService, private router: Router, private fb: FormBuilder, private spinner: SpinnerService, public controlContainer: ControlContainer) { }

	@Output() updateline = new EventEmitter<FormArray>();
	
	private isDefaultUpdate: Boolean = false;	// Is default update flag. When true set all lines to UPDATE
	public formGroup!: FormGroup;
	public checked_line: number = 0;
	

	iidx?:number;				// Index of currently edited line
	iid?:string;
	ipartner?:string;
	ityp?:string;
	icivility?:string;
	iname?:string;
	ifirstname?:string;
	iroles:Array<number>=[4];	// Role 4 is a pure address
	istreet1:string = '';		// Those are for input sub-form. 
	istreet2:string = '';
	istreet3:string = '';
	izip:string = '';
	icity:string = '';
	icountry: string = 'France';
	
	ngOnInit(): void {
		this.formGroup = this.controlContainer.control as FormGroup;	// formgroup is inherited from parent
		// TODO Set checked_line by finding default address
	}
	
	loadPartner(): void {				// Triggered by parent. Partner is loaded
		this.isDefaultUpdate = false;	// Reset isDefaultUpdate
		this.checked_line = 0;			// API sends us default in first position 
	}

	addLine(): void {
		this.addresses.push(this.fb.group({
			id: 0,							// Server needs it to deserialize c_partner row
			partner: this.formGroup.get("id")?.value,
			typ: 1,							// Partner type=1 (indivudual) by convention for pure addresses
			civility: 0,
			firstname: null,
			name: null,
			// roles: [4],
			roles: this.fb.array([4]),		// Role 4 is a pure address
			street1: (this.istreet1 === ''?null:this.istreet1),
			street2: (this.istreet2 === ''?null:this.istreet2),
			street3: (this.istreet3 === ''?null:this.istreet3),
			zip: (this.izip === ''?null:this.izip),
			city: (this.icity === ''?null:this.icity),
			country: (this.icountry === ''?null:this.icountry),
			email: null,
			tel1: null,
			tel2: null,			
			is_default: false,				// Will be updated on submit. Warning! only used on server side. Not used for radio button state in page
			action: 'INSERT', 
		}));
		// this.clear(); // TODO remove comment in production
	}
	
	deleteLine(i: number): void {
		let checked = (this.checked_line == i);		// Is the deleted line the checked one?
		console.log("Removed checked? " + checked);
		if (checked && i !== 0) { 
			this.checked_line = 0;			// Line was checked so now checked one = index 0
			this.isDefaultUpdate = true;	// We changed checked line => update ALL
		}
		if (checked && i == 0) {
			this.checked_line = 1;	// Check next line. If no next no big deal
			this.isDefaultUpdate = true;	// We changed checked line => update ALL
		}
		this.addresses.at(i).get("action")?.patchValue("DELETE");
		// this.addresses.removeAt(i);
	}

	checkLine(i: number): void {
		this.checked_line = i;
		this.isDefaultUpdate = true;	// Set flag => UPDATE all lines
		console.log("checked_line: " + this.checked_line);
	}
	
	setAction(i: number): void {
		// console.log("setAction: " + i);
	}
	
	onSubmit(fg:FormGroup) {	// Only called by parent onSubmit
		console.log("%cSub-form submit, checked:" + this.checked_line, "color: orange");
		let addrs: FormArray = fg.controls["addresses"] as FormArray;
		if (addrs.length) {
			addrs.controls.forEach((line) => {		// Clear all is_default flags, do needed castings
				let linefg: FormGroup = line as  FormGroup;
				linefg.controls["civility"].setValue(parseInt(linefg.controls["civility"].value));	// Cast civility to int
				console.log("  Processing address civility - action: " + linefg.get("civility")?.value + ' - ' + linefg.get("action")?.value);
				line.get("is_default")?.setValue(false);
				if (linefg.dirty && line.get("action")?.value!=='DELETE') line.get("action")?.patchValue("UPDATE");
				if (this.isDefaultUpdate && line.get("action")?.value!=='DELETE') line.get("action")?.patchValue("UPDATE");	// isDefaultUpdate is set => Force UPDATE except for DELETE
				
				console.log("Dirty?: " + linefg.dirty);
			});
			addrs.at(this.checked_line).get("is_default")?.patchValue(true);	// Set is_default flag for the right one
		}
	}	
	
	postSubmit() {	// Only called by parent onSubmit, after POST
	}
	
	clear(): void {
		this.iidx = undefined;
		this.istreet1 = '';
		this.istreet2 = '';
		this.istreet3 = '';
		this.izip = '';
		this.icity = '';
		this.icountry = 'France';
	}	
	
	get addresses(): FormArray {
		return this.formGroup.controls["addresses"] as FormArray;
	}
	
}
