<app-menu-common url="purchase"></app-menu-common>
<div class="pageheader">
	<div class="breadcrumb" style="display: flex; justify-content: space-between;">
		<span class="mat-headline-1 grey" >Tarif Achat / </span><span class="mat-headline-1 grey">#{{id}}</span>
	</div>
</div>

<app-action-bar [form]="'mainform'"></app-action-bar>

<div class="maincontent">
	<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" id="mainform">
		<div class="pagecontent" *ngIf="dataSource" formArrayName="prices">
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef> Id </th>
					<td mat-cell *matCellDef="let p;"> {{p.get("id").value}} </td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef> Name </th>
					<td mat-cell *matCellDef="let p"> {{p.get("lnk_product").get("name").value}} </td>
				</ng-container>

				<ng-container matColumnDef="currentprice">
					<th mat-header-cell *matHeaderCellDef> Px. Actuel </th>
					<td mat-cell *matCellDef="let p;"> {{p.get("price").value}} </td>
				</ng-container>


				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef> Px. Futur </th>
					<td mat-cell *matCellDef="let p; let i = index;" [formGroup]="p">
						<mat-form-field>
							<mat-label>Input</mat-label>
							<input class="right" type="number" matInput type="text" formControlName="price">
						</mat-form-field>
					</td>
				</ng-container>


				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>
	</form>
</div>
