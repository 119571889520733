import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Event, Partner } from '@app/shared/_models/common';

let fb = new FormBuilder();

export class Sales_order {
	id!: number;
	customer!: number;
	store!: number;
	state!: number;
	addr_deliv!: number;
	addr_bill!: number;
	amount_taxx!: number;
	amount_taxi!: number;
	
	lines: Sales_order_l[]=[];
	payment: Sales_payment[]=[];
	// tax: Map<number, Sales_tax> = new Map();
	tax: Sales_tax[]=[];
	
	lnk_customer?: Partner;
	lnk_partner_deliv?: Partner;	// Delivery address
	dates?: Date[];
	lnk_delivery?: Delivery;

	static asFormGroup(o?: Sales_order): FormGroup {
		if (!o) return new FormGroup({});
		let lnk_customer = Partner.asFormGroup(o.lnk_customer);
		let lnk_delivery = Delivery.asFormGroup(o.lnk_delivery);
		let lines:FormArray = fb.array([]);
		let payment:FormArray = fb.array([]);
		let tax:FormArray = fb.array([]);
		let dates:FormArray = fb.array([]);
		if (o!.lines) for (let i = 0; i < o!.lines.length; i++) {
			let l:Sales_order_l = o!.lines[i];
			//console.log(JSON.stringify(l));
			lines.push(Sales_order_l.asFormGroup(l));
		} 
		//if (o!.payment) payment=fb.array([o.payment]);
		if (o!.payment) for (let i = 0; i < o!.payment.length; i++) {
			let p:Sales_payment = o!.payment[i];
			payment.push(Sales_payment.asFormGroup(p));
		}
		if (o!.tax) for (let i = 0; i < o!.tax.length; i++) {
			let t:Sales_tax = o!.tax[i];
			tax.push(Sales_tax.asFormGroup(t));
		}
		if (o!.dates) dates = fb.array(o.dates)	
		
		const fg = new FormGroup<{
			id: FormControl<number|null>,
			customer: FormControl<number|null>,
			store: FormControl<number|null>,
			state: FormControl<number|null>,
			addr_deliv: FormControl<number|null>,
			addr_bill: FormControl<number|null>,
			amount_taxx: FormControl<number|null>,
			amount_taxi: FormControl<number|null>,
			lines: FormArray,
			payment: FormArray,
			tax: FormArray,
			lnk_customer: FormGroup,
			dates: FormArray,
			lnk_delivery: FormGroup,
		}>
		({
			id: new FormControl(o?.id),
			customer: new FormControl(o?.customer),
			store: new FormControl(o?.store),
			state: new FormControl(o?.state),
			addr_deliv: new FormControl(+o?.addr_deliv),
			addr_bill: new FormControl(+o?.addr_bill),
			amount_taxx: new FormControl(+o?.amount_taxx),	// WARNING plus sign is mandatory to cast to number
			amount_taxi: new FormControl(+o?.amount_taxi),			
			lines: lines, 
			payment: payment,
			tax: tax,
			lnk_customer: lnk_customer,
			dates: dates,
			lnk_delivery: lnk_delivery,
		});
		// console.log(fg.get("lines")?.value);
		return fg;
	}
}

export class Sales_order_l {
	id?: number;
	id_order?: number;
	seq?: number;
	typ?: number;
	product?: number;
	template?: number;
	qty!: number;
	product_name?:string;
	product_barcode?:string;	
	vat?: number;
	vat_rate!: number;
	uprice_taxx!:number;
	uprice_taxi!:number;	
	discount_taxx!:number;	
	discount_taxi!:number;
	packaging_name?:string;
	packaging_qty!: number;
	memo?:string;
	
	static asFormGroup(sol?: Sales_order_l): FormGroup {
		if (!sol) return new FormGroup({});
		const fg = new FormGroup({
			id: new FormControl(sol?.id),
			id_order: new FormControl(sol?.id_order),
			seq: new FormControl(sol?.seq),
			typ: new FormControl(sol?.typ),
			product: new FormControl(sol?.product),
			template: new FormControl(sol?.template),
			qty: new FormControl(+sol?.qty),
			product_name: new FormControl(sol?.product_name),
			product_barcode: new FormControl(sol?.product_barcode),			
			vat: new FormControl(sol?.vat),
			vat_rate: new FormControl(+sol?.vat_rate),
			uprice_taxx: new FormControl(+sol?.uprice_taxx),
			uprice_taxi: new FormControl(+sol?.uprice_taxi),			
			discount_taxx: new FormControl(+sol?.discount_taxx),
			discount_taxi: new FormControl(+sol?.discount_taxi),
			packaging_name: new FormControl(sol?.packaging_name),
			packaging_qty: new FormControl(+sol?.packaging_qty),
			memo: new FormControl(sol?.memo),
		});
		return fg;
	}
}	

// To map sales_order.payment::jsonb to a class
export class Sales_payment {
	seq!: number;
	method!: number;
	amount!: number;	
	
	static asFormGroup(p?: Sales_payment): FormGroup {
		if (!p) return new FormGroup({});
		const fg = new FormGroup({
			seq: new FormControl(+p?.seq),
			method: new FormControl(+p?.method),
			amount: new FormControl(+p?.amount),
			});
		return fg;
	}
}

export class Sales_tax {
	tax!: number;
	rate!: number;
	base_amount!: number;
	tax_amount!: number;
  
	static asFormGroup(t?: Sales_tax): FormGroup {
		if (!t) return new FormGroup({});
		const fg = new FormGroup({
			tax: new FormControl(+t?.tax),
			rate: new FormControl(+t?.rate),
			base_amount: new FormControl(+t?.base_amount),
			tax_amount: new FormControl(+t?.tax_amount),
			});
		return fg;
	}  
}

export class Sales_invoice {
		customer!:number;
		adddr_bill!:number;
	  amount_taxx!:number;
	  amount_taxi!:number;
	  datcre!:Date;

		lnk_customer!: Partner;
	  lnk_addr_bill!: Partner;	  
    lnk_orders!: Sales_order[];
}

export class Delivery {
		id!:number;
		id_order!:number;
		id_planning!:number;
		mode!:number;
		askdatdel!:Date;
		askslot!:number;
		askop!:number;
		
	static asFormGroup(d?: Delivery): FormGroup {
		if (!d) return new FormGroup({						// Default values, mode 1
				id: new FormControl<number>(+0),
				id_order: new FormControl<number>(+0),
				id_planning: new FormControl<number>(+0),
				mode: new FormControl<number>(1),
				askdatdel: new FormControl<Date | undefined>(undefined),
				askslot: new FormControl<number>(+0),
				askop: new FormControl<number>(+0),

			});		
		const fg = new FormGroup({
				id: new FormControl<number>(+d.id),
				id_order: new FormControl<number>(+d.id_order),
				id_planning: new FormControl<number>(+d.id_planning),
				mode: new FormControl<number>(+d.mode),
				askdatdel: new FormControl<Date | undefined>(d.askdatdel),
				askslot: new FormControl<number>(+d.askslot),
				askop: new FormControl<number>(+d.askop),

			});
		return fg;
	}  		
}