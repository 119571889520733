import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Observable, of, fromEvent, concat, forkJoin } from "rxjs";
import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProductsComponent } from "@app/sales/products/products.component"

import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';

import { QuotationService } from '@app/sales/quotation/quotation.service';
import { PartnersService } from '@app/common/partners/partners.service';
import { Subject, BehaviorSubject } from 'rxjs';

import { environment } from '@app/../environments/environment';
import * as constants from "@app/shared/constants";
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActionBarComponent } from '@app/shared/action-bar/action-bar.component';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ProductsService } from '@app/sales/products/products.service';

@Component({
	selector: 'app-itemset',
	standalone: true,
	imports: [NgIf, NgFor, MatButtonModule, MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule, MatAutocompleteModule],
	templateUrl: './itemset.component.html',
	styleUrl: './itemset.component.scss'
})
export class ItemsetComponent {
	@ViewChild('searchManufInput', { static: true }) searchManufInput: ElementRef<HTMLInputElement> = {} as ElementRef;

	public searchmanuf: any = '';
	public faminput: string = '';
	public manufApiResponse: any;
	public searchinput: string = '';
	public setinput: string = '';
	formGroup!: FormGroup;

	constructor(private productsService: ProductsService, public dialog: MatDialog, private fb: FormBuilder) { }

	ngOnInit() {
		this.formGroup = this.fb.group({
			included: this.fb.array([]),
			excluded: this.fb.array([]),
		});

		fromEvent(this.searchManufInput.nativeElement, 'keyup').pipe(	// Attach search function to manuf field
			// get value
			map((event: any) => {
				return event.target.value;
			})
			// if character length greater then 2
			, filter(res => res.length > 2)

			// Time in milliseconds between key events
			, debounceTime(500)

			// If previous query is diffent from current   
			, distinctUntilChanged()

			// subscription for response
		).subscribe((text: string) => {
			this.searchGetCallPartner(text).subscribe((res) => {
				this.manufApiResponse = res;
			}, (err: any) => {
				console.log('error', err);
			});
		});
	}

	searchGetCallPartner(term: string): Observable<any> {
		if (term === '') {
			return of([]);
		}
		return this.productsService.search_manuf('*' + term + '*');
	}

	onItemKeydown(e: any) { }

	openProductDialog(): void {										// Open product lookup page as a modal dialog
		const dialogRef = this.dialog.open(ProductsComponent, {
			width: '80%',
			height: '80%',
			data: { mode: "DIALOG" },
		});

		dialogRef.afterClosed().subscribe(ret => {	// result could be undefined 
			console.log('The dialog was closed. Returned pid: ' + ret);
			this.searchinput = ret;
			if (ret) {
				this.addtoset('IN');	// Looking for a product id => index jpid:
			}
		});
	}

	addtoset(inputset:string) {
		let set=this.included;
		if (inputset == 'EX') set=this.excluded;
		if (this.searchinput) {
			let $obs = this.productsService.search_productpackaging('jbc', parseInt(this.searchinput)).subscribe((ppv: any) => {	// Result is a product_packaging_val
				console.log('productsService.search_bc: ' + JSON.stringify(ppv));
				if (ppv) {
					set.push(this.fb.group({
						id: ppv.id,
						name: ppv.lnk_product.name,
					}));
				} else {	// Barcode does NOT exist
					// this.searchinput.setErrors({ 'incorrect': true });
				}
				$obs.unsubscribe();
			});
		} else if (this.faminput) {
			set.push(this.fb.group({
				id: 12,
				name: 'Fam. ' + this.faminput,
			}));
		} else if (this.searchmanuf) {
			set.push(this.fb.group({
				id: 14,
				name: 'Four. ' + this.searchmanuf.name,
			}));
		}
		this.clearinput();
	}

	clearinput() {
		this.searchinput = '';
		this.faminput = '';
		this.setinput = '-';
		this.searchmanuf = '';
	}

	displayFn(o: any) {
		if (o) return o.name
		else return null;
	}

	deleteLine(i: number) { }

	get included(): FormArray {
		return this.formGroup.controls["included"] as FormArray;
	}
	
	get excluded(): FormArray {
		return this.formGroup.controls["excluded"] as FormArray;
	}	
}
