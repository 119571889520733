import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { EMPTY, empty, of } from "rxjs";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  constructor(private http: HttpClient) { }
  
  getQuotationsByFilter(name:string, state?:string) : Observable<any> {
	  let q:String = "";
	  if (name) q += "name=" + name;
	  if (state && state!="-1") q = q + (q===""?"":"&") + "state=" + state;
	  return this.http.get<void>(environment.server_url + "/sales/orders?" + q);
	  }
	
	 getQuotationById(id:number): Observable<any> {
		 return this.http.get<void>(environment.server_url + "/sales/orders?id=" + id + "&load_lines=true");
	 }
}
