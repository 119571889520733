import { Directive, ViewContainerRef, ElementRef, Input, OnInit } from '@angular/core';
import { navComponent } from '@app/shared/nav.component';
import { NavItem } from '@app/shared/nav-item';
import { MenuSalesComponent } from '@app/shared/menu-sales/menu-sales.component';
import { MenuHomeComponent } from '@app/shared/menu-home/menu-home.component';
import { MenuStockComponent } from '@app/shared/menu-stock/menu-stock.component';

@Directive({
    selector: 'navbarMenu',
    standalone: true
})

export class NavbarDirective {
	@Input() navbarTyp = '';
	
	private navs = new Map<String, NavItem>;
	
	constructor(public viewContainerRef: ViewContainerRef, private el: ElementRef) { 
		this.navs.set('home', new NavItem(MenuHomeComponent));
		this.navs.set('sales', new NavItem(MenuSalesComponent));
		this.navs.set('stock', new NavItem(MenuStockComponent));
	}

	ngOnInit(): void {
		let component = this.navs.get(this.navbarTyp);
		if (component) {
			// console.log("[NavbarDirective] Found menu component: " + this.navbarTyp);
			this.viewContainerRef.createComponent<navComponent>(component.component);
		}
	}
}
