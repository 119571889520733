<span class="menutitle">Ventes</span>

<button mat-button routerLink="/sales/quotations">Commandes</button>
<button mat-button routerLink="/sales/products">Produits</button>
<button mat-button routerLink="/common/partners">Clients</button>

<button mat-button [matMenuTriggerFor]="menuProducts">Prix</button>
<mat-menu #menuProducts="matMenu" xPosition="after">
  <button mat-menu-item routerLink="/sales/products">Produits</button>
  <button mat-menu-item routerLink="/sales/prices">Prix de vente</button>
  <button mat-menu-item routerLink="/sales/pricelists">Prépa. Tarif vente</button>  
  <button mat-menu-item routerLink="/sales/offers">Remises</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="menuinvoice">Facturation</button>
<mat-menu #menuinvoice="matMenu" xPosition="after">
  <button mat-menu-item routerLink="/sales/invoices">Recherche Factures</button>
  <button mat-menu-item routerLink="/sales/invoice-creation">Facturer</button>
  <button mat-menu-item routerLink="/fse/fsestobill">Recherche Factures FSE</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="menuProducts">Paramètres</button>
<mat-menu #menuProducts="matMenu" xPosition="after">
  <button mat-menu-item>Attributs</button>
  <button mat-menu-item>Variantes</button>
  <button mat-menu-item>Tarifs</button>
</mat-menu>
