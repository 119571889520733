<form [formGroup]="pformGroup">
<div class="flexgrid" >
            <div class="r1c1 flexrow">
                <div class="col30">
                    <div>Famille</div>
                </div>
                <div class="col">
                 <mat-form-field>
								<input matInput>
                 </mat-form-field>
            		</div>
            </div>
            
			<div class="r1c2 flexrow">
				<div class="col30 ">
					&nbsp;
				</div>
				<div class="col">
					&nbsp;
				</div>
			</div>
			
						<div class="r2c1 flexrow">
							<div class="col30 ">
								<div>Unité de Vente Conso.</div>
							</div>
							<div class="col">
								<div>
								  <mat-form-field>
									<mat-select formControlName="baseuom">
										<mat-option *ngFor="let su of salesUnit" [value]="su.id">
											{{su.name}}
										</mat-option>
									</mat-select>
								  </mat-form-field>
								</div>
							</div>
						</div>			
						
						<div class="r2c2 flexrow">
				<div class="col30 ">
					<div>Unité de Mesure du px unit.</div>
					</div>
					<div class="col">
						<div>
						  <mat-form-field>
							<mat-select formControlName="unitpriceuom" name="upuom">
								<mat-option *ngFor="let upuom of salesUnit" [value]="upuom.id">
									{{upuom.name}}
								</mat-option>
							</mat-select>
						  </mat-form-field>
						</div>
					</div>
				</div>						
				
						<div class="r3c1 flexrow">
							<div class="col30 ">
								<div>Taux de TVA</div>
							</div>
							<div class="col">
								  <mat-form-field>
									<mat-select formControlName="vat">
										<mat-option *ngFor="let tax of vat" [value]="tax.id">
											{{tax.name}}
										</mat-option>
									</mat-select>
								  </mat-form-field>
							</div>
						</div>		
						
						<div class="r4c1 flexrow gap">
							<div class="padding">
								<div>LPP</div>
							</div>
                <div class="gap">
                 <mat-form-field formGroupName="lnk_lpp">
										<input matInput type="text" formControlName="id">
                 </mat-form-field>
                 <span class="radioborder">{{ pformGroup.get('lnk_lpp')?.get('name')?.value }}</span>
                 <span class="datend">{{ pformGroup.get('lnk_lpp')?.get('datend')?.value }}</span>
            		</div>
						</div>	
			            
</div>

</form>