// Here go classes about the common module

import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Attribute } from '@app/shared/_models/product';
import { JwtPayload } from 'jwt-decode';

let fb = new FormBuilder();

export class Partner {
	id!: number;
	partner!: number;
	typ!: number;
	civility!: number;
	typpay!: number;
	firstname!: string;
	name!: string;
	roles:Array<number>=[];
	street1!: string;
	street2!: string;
	street3!: string;
	zip!: string;
	city!: string;
	country!: string;
	email!: string;
	tel1!: string;
	tel2!: string;
	is_default!: boolean;
	addresses: Partner[]=[];
	action!: string;

	static asFormGroup(p?: Partner): FormGroup {
		if (!p) return new FormGroup({});
		let addr:FormArray = fb.array([]);
		if (p.addresses) for (let i = 0; i < p.addresses.length; i++) {
			let pa:Partner = p.addresses[i];
			//addr.push(Partner.addressAsFormGroup(pa));
			addr.push(Partner.asFormGroup(pa));
		} 
		try{
			const fg = new FormGroup
			<{
				id: FormControl<number|null>,
				partner: FormControl<number|null>,
				typ: FormControl<number|null>,
				civility: FormControl<number|null>,
				typpay: FormControl<number|null>,
				firstname: FormControl<string|null>,
				name: FormControl<string|null>,
				roles: FormArray,
				street1: FormControl<string|null>,
				street2: FormControl<string|null>,
				street3: FormControl<string|null>,
				zip: FormControl<string|null>,
				city: FormControl<string|null>,
				country: FormControl<string|null>,
				email: FormControl<string|null>,
				tel1: FormControl<string|null>,
				tel2: FormControl<string|null>,
				is_default: FormControl<boolean|null>,
				addresses: FormArray,
				
				action: FormControl<string|null>,
			}>
			({
				id: new FormControl(p?.id),
				partner: new FormControl(p?.partner),
				typ: new FormControl(p?.typ),
				civility: new FormControl(p.civility!=undefined?p.civility:+0),	// Defaults to 0
				typpay: new FormControl(+p?.typpay),
				firstname: new FormControl(p.firstname),
				name: new FormControl({ value: p?.name, disabled: false }),
				roles: (p?.roles ? fb.array(p.roles) : fb.array([3])),	// WARNING Incosistent data, force null role to 3
				street1: new FormControl(p?.street1),
				street2: new FormControl(p?.street2),
				street3: new FormControl(p?.street3),
				zip: new FormControl(p?.zip),
				city: new FormControl(p?.city),
				country: new FormControl(p?.country),
				email: new FormControl(p?.email),
				tel1: new FormControl(p?.tel1),
				tel2: new FormControl(p?.tel2),
				is_default: new FormControl(p?.is_default),
				addresses: addr,
				
				action:new FormControl('UNCHANGED'),
			});
			return fg;
		} catch (e:any) {
			console.log("ERROR Loading formGroup from API call")
		}
		console.log("Returning fb")
		return fb.group({});
	}
}

export class Event {
	id?: number;
	typ?: number;
	objecttyp?: number;
	objectid?: number;
	//data?:string;
	data?: any;
}

export class C_value {	// Typical c_value data structure
	id?: number;
	name?: string;
	seq?: number;
}

export class User {
	id?: number;
	store:number;			// Default store
	login?: string;
	password?: string;
	firstname?: string;
	lastname?: string;
	token?: string;
	currentstore: number;	// Active store for user
	
	constructor (token: any) { // JwtPayload
		this.id=token.user_id;	
		this.login = token.login;
		this.store = token.store;
		this.currentstore = this.store;		// Current store is initialized w/ default store		
		this.firstname = token.firstname;
		this.lastname = token.lastname;
	}
	
	static asFormGroup(u?: User): FormGroup {
		
		const fg = new FormGroup({
			id: new FormControl(u?.id),
			store: new FormControl(u?.store),
			firstname: new FormControl(u?.firstname),
			lastname: new FormControl(u?.lastname),			
		});
		return fg;
	}	
}
