import { Component } from '@angular/core';

@Component({
    selector: 'app-quotationoptions',
    templateUrl: './quotationoptions.component.html',
    styleUrls: ['./quotationoptions.component.scss'],
    standalone: true
})
export class QuotationoptionsComponent {

}
