import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { PurchasePriceHist } from '@app/shared/_models/purchasePriceHist'

@Injectable({
	providedIn: 'root'
})
export class PurchaseService {

	constructor(private http: HttpClient) { }

	getPricelists(partner: String) {
		return this.http.get<void>(environment.server_url + "/purchase/pricelists?partner=" + '%' + partner + '%');
	}

	getPricelist(id: number):Observable<any> {
		return this.http.get<void>(environment.server_url + "/purchase/pricelist/" + id);
	}

	getPricelistAsFormArray(id: number): Observable<FormArray> {
		return this.getPricelist(id).pipe(map((purchasePriceHist: PurchasePriceHist[]) => {
			const fgs = new FormArray(purchasePriceHist.map(PurchasePriceHist.asFormGroup));
			return fgs;
		}));
	}
}
