<button
	mat-flat-button
	aria-label="Facturer"
	color="primary"
	style="margin:8px;"
	type="button"
	(click)="onActionBarAction('INVOICING')">	 

	<mat-icon>euro</mat-icon>
	Facturer
</button>