import { Injectable } from '@angular/core';
import { Observable, throwError, merge } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import * as constants from "@app/shared/constants";

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FseService {

	constructor(private http: HttpClient) { }
	
	getFsestobill$(): Observable<any> {
		return this.http.get<void>(environment.server_url + "/hc/fsestobill");
	}
}
