import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { LinkedProduct } from '@app/shared/_models/product';
import { User } from '@app/shared/_models/common';

let fb = new FormBuilder();

export class Pinvc {
	id?: number;
	typ?: number;
	store?: number;
	name?: string;
	datstart?: Date;
	datend?: Date;
	lines?: PinvcL[];

	static asFormGroup(p?: Pinvc): FormGroup {
		let lines: FormArray = fb.array([]);
		if (p!.lines) for (let i = 0; i < p!.lines.length; i++) {
			let l: PinvcL = p!.lines[i];
			//console.log(JSON.stringify(l));
			lines.push(PinvcL.asFormGroup(l));
		}
		const fg = new FormGroup({
			id: new FormControl(p?.id),
			typ: new FormControl(p?.typ),
			store: new FormControl(p?.store),
			name: new FormControl(p?.name),
			datstart: new FormControl(p?.datstart),
			datend: new FormControl(p?.datend),
			lines: lines,
		});
		return fg;
	}
}

export class PinvcL {
	id?: number;
	pinvc?: number;
	product?: number;
	zone?: number;
	user?: number;
	datscan?: Date;
	barcode?: string;
	amount: number = 0;
	note?: string;

	indnew: boolean = false;							// Not existent in data. Indicates a newly scanned item
	
	lnk_product?: LinkedProduct;
	lnk_user?: User;

	static asFormGroup(p?: PinvcL): FormGroup {
		let lnk_product = LinkedProduct.asFormGroup(p?.lnk_product);		
		let lnk_user = User.asFormGroup(p?.lnk_user);
		
		const fg = new FormGroup({
			id: new FormControl(p?.id),
			pinvc: new FormControl(p?.pinvc),
			product: new FormControl(p?.product),
			zone: new FormControl(p?.zone),
			user: new FormControl(p?.user),
			datscan: new FormControl(p?.datscan),
			barcode: new FormControl(p?.barcode),
			amount: new FormControl(p?.amount),
			
			lnk_product: lnk_product,
			lnk_user: lnk_user,
		});
		return fg;
	}
}

export class Zone {
	id?: number;
	store?: number;
	name?: number;		
}	