<h2 mat-dialog-title>Code barre existant dans cet inventaire/zone</h2>
<mat-dialog-content class="flexcolumn">
	<div class="greyborder">
		<div class="flexrow">
			<span class="title">Id [code]</span><span class="value">{{ data.pinvcl.product }}&nbsp;[{{data.pinvcl.barcode}}]</span><br/>
		</div>
		
		<div class="flexrow">
			<span class="title">Produit</span><span class="value">{{ data.pinvcl.lnk_product?.name }}</span><br/>
		</div>
		
		<div class="flexrow">
			<span class="title">Qté</span><span class="value">{{ data.pinvcl.amount }}</span><br/>
		</div>
	</div>
	<br/>	
	<div class="flexrow">
		<span class="title">Nouvelle Qté</span><span class="value"><input id="qty" [formControl]="qty" cdkFocusInitial></span><br/>
	</div>
	
	<br/>	
  <div class="flexrow">
  	Remplacer la qté ou ajouter ?
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annuler</button>
  <button mat-button (click)="onReplace()" >Remplacer</button>
  <button mat-button (click)="onAdd()" cdkFocusInitial>Ajouter</button>
</mat-dialog-actions>