import { Component } from '@angular/core';
import { InvoiceCreationService } from '@app/sales/invoice-creation/invoice-creation.service';
import { QuotationService } from '@app/sales/quotation/quotation.service'
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { Sales_order, Sales_invoice } from '@app/shared/_models/sales';

import { environment } from '@app/../environments/environment';
import * as constants from "@app/shared/constants";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { InvoiceCreationActionComponent } from '../../shared/action-bar/invoice-creation-action/invoice-creation-action.component';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
    selector: 'app-invoice-creation',
    templateUrl: './invoice-creation.component.html',
    styleUrls: ['./invoice-creation.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, ActionBarComponent, NgIf, InvoiceCreationActionComponent, NgFor, RouterLink, MatButtonModule, MatIconModule, NgClass, MatExpansionModule, DatePipe]
})
export class InvoiceCreationComponent {
	constructor(private invoiceCreationService: InvoiceCreationService, private quotationService: QuotationService) { }
	public sales_order_status = constants.sales_order_state;
	public datasource!: Sales_invoice[];

	ngOnInit(): void {
		this.invoiceCreationService.getInvoices()
			.subscribe((data: Sales_invoice[]) => {
				this.datasource = data;
			});
	}

	onClickChangeState(order_id: number, state: number) {
		console.log("onClickDeliver: " + order_id);
		outerloop:
		for (let i in this.datasource) {
			console.log("Looping: " + this.datasource[i].lnk_customer.name);
			for (let j in this.datasource[i].lnk_orders) {
				console.log("Looping: " + this.datasource[i].lnk_customer.name + " - " + this.datasource[i].lnk_orders[j].id);
				if (this.datasource[i].lnk_orders[j].id === order_id) {
					console.log("Found order: " + JSON.stringify(this.datasource[i].lnk_orders[j]));
					this.quotationService.postOrderChangeStateEvent(constants.EVENTTYP_CHANGEORDERSTATE, order_id, state).subscribe((data => {
						this.datasource[i].lnk_orders[j].state = state;	// Set order to "delivered"
					}));
					break outerloop;
				}
			}

		}
	}
	
	onActionBarAction (action:String) {
		console.log("InvoiceCreationComponent - onActionBarAction: " + action);
		switch (action) {
			case "INVOICING": 
				this.quotationService.postInvoicingEvent().subscribe(()=>{
					console.log("Back from postInvoicingEvent");
					// TODO Reload page
				});
				break;
			}
		}	

	datState(o: Sales_order, state: number): Date | undefined {	// Get formatted date for current status
		if (o !== undefined && state !== undefined && o.dates && o.dates[state]) return o.dates[state];
		return undefined;
	}
}
