import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { Observable, throwError, merge, EMPTY, Subject } from 'rxjs';
import { map, distinct, mapTo, catchError, retry, filter } from 'rxjs/operators';
import { Event } from '@app/shared/_models/common';
import { Sales_order } from '@app/shared/_models/sales';
import * as constants from "@app/shared/constants";

import { environment } from '@app/../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class QuotationService {

	constructor(private http: HttpClient) { }

	public subject = new Subject<any>();

	sendAddproductpackagingfromHistory(product: any, qty: number = 1) {
		this.subject.next({ product, qty });
	}

	getQuotationAsFormGroup(id: number): Observable<FormGroup> {
		return this.getQuotationById(id).pipe(
			map((orders: Sales_order[]) => {
				return Sales_order.asFormGroup(orders[0]);
			}));
	}

	getQuotationById(id: number): Observable<any> {
		let q: String = "id=" + id;
		if (q === '') return EMPTY;
		return this.http.get<void>(environment.server_url + "/sales/orders?" + q + "&load_lines=true");
	}

	getCustomerHistory(customerId: number, curentOrderId: number): Observable<any> {
		return this.http.get<void>(environment.server_url + "/sales/orders?customer=" + customerId + '&load_lines=true').pipe(
			map((orders: any) => {
				return orders.filter((order: any) => order.id !== curentOrderId);	// Exclude current order
			})
		);
	}

	postQuotationEvent(eventtyp: number, formGroup: FormGroup): Observable<any> {
		let event: Event = {
			id: 0,
			typ: eventtyp,			// 1: Insert, 2: Update, 3: Delete
			objectid: 0,
			objecttyp: constants.OBJECTTYP_ORDER,		// Order
			data: formGroup.value,
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}

	get_docs_by_object_id(id: number): Observable<any> {
		return this.http.get<void>(environment.server_url + "/docsbyobjectid/" + id);
	}

	postOrderChangeStateEvent(eventtyp: number, order_id: number, state: number): Observable<any> {
		let data = { "state": state };
		let event: Event = {
			id: 0,
			typ: eventtyp,			// 1: Insert, 2: Update, 3: Delete
			objectid: order_id,
			objecttyp: constants.OBJECTTYP_ORDER,		// Order
			data: data,
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}

	postInvoicingEvent(): Observable<any> {
		console.log("In postInvoicingEvent");
		let event: Event = {
			id: 0,
			typ: constants.EVENTTYP_INVOICING,			// Special type
			objectid: 0,
			objecttyp: constants.OBJECTTYP_ORDER,		// Order
			data: {},
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}

	postDeliveryEvent(eventtyp: number, formGroup: FormGroup): Observable<any> {
		console.log("In postDeliveryEvent");
		let event: Event = {
			id: 0,
			typ: eventtyp,
			objectid: 0,		// FIXME get delivery id from formgroup
			objecttyp: constants.OBJECTTYP_DELIVERY,		// Delivery
			data: formGroup.value,
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}
	
	postHCCardEvent(eventtyp: number, data: any): Observable<any> {	// Post Healthcare recipients from hc card
		console.log("In postHCRecipientEvent");
		let event: Event = {
			id: 0,
			typ: eventtyp,
			objectid: 0,
			objecttyp: constants.OBJECTTYP_HCCARD,
			data: data,
		}
		return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
	}	
}
