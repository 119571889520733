import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';

import { QuotationService } from '@app/sales/quotation/quotation.service';
import { PartnersService } from '@app/common/partners/partners.service';
import { Subject, BehaviorSubject } from 'rxjs';

import { environment } from '@app/../environments/environment';
import * as constants from "@app/shared/constants";
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActionBarComponent } from '@app/shared/action-bar/action-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';
import { ItemsetComponent } from '@app/sales/offer/itemset/itemset.component';
import { OutcomeComponent } from '@app/sales/offer/outcome/outcome.component';

@Component({
  selector: 'app-offer',
  standalone: true,
  imports: [ItemsetComponent, OutcomeComponent, MatDividerModule, MatTabsModule, MenuCommonComponent, NgIf, FormsModule, MatInputModule, ReactiveFormsModule, MatButtonModule, NgFor, ActionBarComponent, MatCheckboxModule, MatRadioModule, MatFormFieldModule],
  templateUrl: './offer.component.html',
  styleUrl: './offer.component.scss'
})
export class OfferComponent {
	constructor(private route: ActivatedRoute, private router: Router, private spinner: SpinnerService, private alert: AlertService, public dialog: MatDialog, private fb: FormBuilder) {}	

	public constants = constants;
	public id: number = 0;
	public eventtyp:number = constants.EVENTTYP_INSERT;			// Insert event by default	
	public formGroup!: FormGroup;	
	public trigkind:string="AMT";	// AMT or QTY
	public trigset:string="";		// GLOBAL or ITEM
	public conskind:string="FIXED";	// AMT or QTY
	public trigqty:number=0;
	
	ngOnInit(): void {
		this.id = Number(this.route.snapshot.paramMap.get('id'));
		console.log("ngOnInit, id: " + this.id);
		if(this.id === 0) { // New offer (no URL param)
			this.formGroup = this.fb.group({
				id: new FormControl<number>(+0),
				trig_qty: new FormControl<number>(+0),
				});		
		} else {				// Load existing offer
			this.loadOffer();
		}
	}	
	
	loadOffer() {
		// this.spinner.show();
		this.eventtyp = constants.EVENTTYP_UPDATE; 										// Update mode. Will issue an update event
		}	
		
	onSubmit() {
		}		
		
	onActionBarSubmit (formName:String) {
		this.onSubmit();
	}
	onChangeTrigKind () {
		this.trigset="";
	}
}
