import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Inject, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormatCurrencyPipe } from '@app/shared/pipes/format-currency.pipe';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, ControlContainer, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
	selector: 'app-discount',
	standalone: true,
	imports: [FormatCurrencyPipe, FormsModule, MatIconModule, MatDialogModule, NgIf, MatButtonModule, ReactiveFormsModule, NgClass, A11yModule],
	templateUrl: './discount.component.html',
	styleUrls: ['./discount.component.scss', '../../../styles-custom.scss'],
})
export class DiscountComponent implements OnInit, AfterViewInit {

	public ppv: any;
	public qty!: number;
	public formGroup!: FormGroup;

	constructor(@Optional() public dialogRef: MatDialogRef<DiscountComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any, private fb: FormBuilder) {
		console.log(JSON.stringify(dialogData, null, 2));
		this.ppv = dialogData.ppv;
		this.qty = dialogData.qty;
		this.formGroup = this.fb.group({
			discountmode: new FormControl<string>('1'),	// Default discount mode is "fixed price"
			inputfixed: new FormControl<string>(this.ppv.lnk_product.price_taxi),
			inputamount: new FormControl<string>(""),
			inputpct: new FormControl<string>(""),
			calcuprice: new FormControl<number>(this.ppv.lnk_product.price_taxi),
			calcmnt: new FormControl<number>((parseFloat(this.ppv.lnk_product.price_taxi) * this.qty)),
		});
	}

	ngOnInit(): void { }

	ngAfterViewInit(): void { }

	onInputChange(e: any) {						// Change in input fields => updateValues
		// this.updateValues();
	}

	onKeyUp(e: KeyboardEvent) {		// Keyup in form => submit on Enter
		console.log("e.key" + e.key);
		// Here insert sanity check => show some error style 
		e.preventDefault;
		e.stopPropagation;
		if (e.key === "Enter") {
			this.onSubmit();
		} else {
			this.updateValues();
		}
	}

	onSubmit() {
		// Sanity check. FIXME move it to onKeyUp
		if (this.f["discountmode"].value == 1) {
			if (this.f["inputfixed"].value >= this.ppv.lnk_product.price_taxi) return;	// Discounted price must be < initial price
		} else if (this.f["discountmode"].value == 2) {
		} else if (this.f["discountmode"].value == 3) {
		}
		this.dialogRef.close({ "discountmode": this.f["discountmode"].value, "inputfixed": this.f["inputfixed"].value, "inputamount": this.f["inputamount"].value, "inputpct": this.f["inputpct"].value, "calcuprice": this.f["calcuprice"].value });
	}

	onRadioChange() {
		// console.log('onRadioChange');
	}

	onFocus(discountmode: number) {
		// console.log('onFocus, discountmode:', discountmode);
		this.f["discountmode"].patchValue(discountmode.toString());
		this.updateValues();
	}

	updateValues() {
		if (this.discountmode == 1) {
			this.f["calcuprice"].patchValue(this.f["inputfixed"].value);
			this.f["calcmnt"].patchValue(this.f["inputfixed"].value * this.qty);
		} else if (this.discountmode == 2) {
			this.f["calcuprice"].patchValue(this.ppv.lnk_product.price_taxi - this.f["inputamount"].value);
			this.f["calcmnt"].patchValue((this.ppv.lnk_product.price_taxi - this.f["inputamount"].value) * this.qty);
		} else if (this.discountmode == 3) {
			let unitprice = this.ppv.lnk_product.price_taxi * (1 - (this.f["inputpct"].value / 100));
			this.f["calcuprice"].patchValue(unitprice);
			this.f["calcmnt"].patchValue(unitprice * this.qty);
		}
	}

	clear() {
		this.formGroup = this.fb.group({
			discountmode: new FormControl<string>('1'),	// Default discount mode is "fixed price"
			inputfixed: new FormControl<string>(this.ppv.lnk_product.price_taxi),
			inputamount: new FormControl<string>(""),
			inputpct: new FormControl<string>(""),
			calcuprice: new FormControl<number>(this.ppv.lnk_product.price_taxi),
			calcmnt: new FormControl<number>((parseFloat(this.ppv.lnk_product.price_taxi) * this.qty)),
		});
		this.discountmode == 1;
	}

	// convenience getter for easy access to form fields
	get f() { return this.formGroup.controls; }
	get discountmode() { return this.f["discountmode"].value; }
}
