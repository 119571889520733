import { Component, Input } from '@angular/core';
import { navComponent } from '../nav.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
	standalone: true,
  selector: 'app-menu-sales',
  templateUrl: './menu-sales.component.html',
  styleUrls: ['./menu-sales.component.scss'],
  imports: [MatMenuModule, MatButtonModule, RouterLink],
})
export class MenuSalesComponent implements navComponent {
	@Input() data: any;
}
