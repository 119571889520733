import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { ProductsService } from '../products/products.service';
import { AttributesService } from '../product/attributes/attributes.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { NgIf, NgFor } from '@angular/common';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

type AttributeValMapType = {
	[id: number]: { attributevaluename: string, attributeshortname: string };
}

export class Variant {
	id: number;
	pname: string;
	is_sold: boolean;
	is_purchased: boolean;

	constructor(id: number, pname: string, is_sold: boolean, is_purchased: boolean) {
		this.id = id;
		this.pname = pname;
		this.is_sold = is_sold;
		this.is_purchased = is_purchased;
	}
}

@Component({
    selector: 'app-variants',
    templateUrl: './variants.component.html',
    styleUrls: ['./variants.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, NgIf, RouterLink, ActionBarComponent, FormsModule, ReactiveFormsModule, NgFor, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule]
})

export class VariantsComponent {
	productAttributeVals: any[] = [];
	search: string = "";				// Search field
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	formGroup!:FormGroup;

	constructor(private productsService: ProductsService, private attributesService: AttributesService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) { }
	
	public template:any;

	ngOnInit(): void {
		this.template = Number(this.route.snapshot.paramMap.get('template'));
		this.formGroup = this.fb.group({});
		
		console.log("ngOnInit (template id): (" + this.template + ")");
		this.productsService.getProductsTplByFilterAsFormArray("", "", undefined, this.template)
			.subscribe((tpl: any) => {
				//this.formGroup.setControl('items', items);			
				//this.dataSource = new MatTableDataSource(items[0]);
				this.formGroup = tpl.at(0);
			});
/*					
		this.productsService.getProductsByFilter("product", "template:" + this.template, "&addattrs=1")	// Load product
			.subscribe((data: any) => {
				data.map((v: any) => {
					const vform: FormGroup = this.fb.group({
						id: [v.id],
						p_name: [v.p_name],
						attributestring: [v.attributestring],
						is_sold: this.fb.control(v.is_sold),
						is_purchased: this.fb.control(v.is_purchased)
					})
					this.variants.push(vform);
				});
			});
*/			
			
	}

	onSubmit(e:String) {
		console.log(this.formGroup.value);
		this.productsService.postProducts(this.formGroup.value)
			.subscribe(response => console.log("ret: " + response));
	}

	onClickRow(row: any): void {
		console.log("clicked: " + row.id);
		return;
		this.router.navigate(['/sales/product/' + row.id]);
	}

	get variants(): FormArray {
		return this.formGroup.controls["lnk_products"] as FormArray;
	}

	get variantsControl(): FormGroup[] {		// This is used to comply to TS type checking on formGroup
		return (this.formGroup.controls["lnk_products"] as FormArray).controls as FormGroup[];
	}
}
