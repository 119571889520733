import { NgIf, NgFor, JsonPipe } from '@angular/common';
import { Component, forwardRef, Input, ViewChild, numberAttribute } from '@angular/core';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule, FormBuilder, ControlContainer, FormGroupDirective, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ValuesService } from '@app/common/values/values.service';
import { MatOptionModule, DateAdapter } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

import { QuotationService } from '@app/sales/quotation/quotation.service';

import * as constants from "@app/shared/constants";

@Component({
  selector: 'select-integer',
 	standalone: true,
 	imports: [ReactiveFormsModule, JsonPipe, MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, NgIf, MatSelectModule, NgFor, MatOptionModule, SelectIntegerComponent],
	templateUrl: './select-integer.component.html',
	styleUrls: ['./select-integer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectIntegerComponent),
      multi: true
    }
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class SelectIntegerComponent implements ControlValueAccessor {
	
	constructor(private controlContainer: ControlContainer, private valuesService: ValuesService) { }

  @Input({ required: true }) public targetControlName!: string;
  @Input({ required: true, transform: numberAttribute }) public c_values_id!: string;
  
  public formGroup!: FormGroup;
  public options:any[]=[];  

  ngOnInit() { 
		this.formGroup = this.controlContainer.control as FormGroup;
		this.valuesService.getValues$(constants.C_VALUES_DELIVERY_SLOT)	// Get Delivery slots & add default value
			.subscribe((data: any) => {
				this.options = data;
				this.options.unshift({ "id": 0, "name": "-" });	// FIXME replace by DB row 
			});		 
		}
	

	// WARNING ControlValueAccessor implementation NOT USED. This is just a template for futur use

  selectedIndex!: number;

  valueChanged(value: any) {
    this.onChange(value);
    this.onTouched();
  }

  // CVA implementation
  public onChange = (_: any) => { };
  public onTouched = () => {};

  // register onChange which we will call when the selected value is changed
  // so that the value is passed back to the form model
  public registerOnChange(fn:any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn:any): void {
    this.onTouched = fn;
  }

  // sets the selected value based on the corresponding form model value
  public writeValue(value: number): void {
		// console.log("Writing value");
    this.selectedIndex = value;
  }
}
