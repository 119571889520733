<app-menu-common url="sales"></app-menu-common>

<ng-container *ngIf="formGroup.get('name')">
	<div class="breadcrumb">
		<h2 class="mat-headline-1 grey">Variantes du Générique <a [routerLink]="['/sales/product', template]" class="darker">{{ formGroup.get("name")?.value }}</a></h2>
	</div>

	<app-action-bar form="mainForm" (submitEmitter)="onSubmit($event)"></app-action-bar>

	<div class="pagecontent">
		<form id="mainForm" [formGroup]="formGroup" (ngSubmit)="onSubmit('')">
			<div class="maincontent" *ngIf="true">
				<table class="mat-elevation-z2" style="width: 100%;">
					<tr>
						<th class="mdc-data-table__header-cell">Nom</th>
						<th class="mdc-data-table__header-cell">Attributs</th>
						<th class="mdc-data-table__header-cell">Actif vente</th>
						<th class="mdc-data-table__header-cell">Actif achat</th>
						<th class="mdc-data-table__header-cell">&nbsp;</th>
					</tr>
					<ng-container *ngFor="let varform of variantsControl; let i = index">
						<tr class="form-row" [formGroup]="varform">
							<td >
								<mat-form-field appearance="fill">
									<input matInput formControlName="name">
								</mat-form-field>
							</td>
							<td>
								<span class=""> {{varform.value.attributestring}}&nbsp;</span>
							</td>
							<td >
								<mat-checkbox [formControl]="$any(varform).get('is_sold')" color="primary"></mat-checkbox>
							</td>
							<td>
								<mat-checkbox [formControl]="$any(varform).get('is_purchased')" color="primary">
								</mat-checkbox>
							</td>
							<td>
								<button mat-flat-button color="primary" [routerLink]="['/sales/variant', template, varform.value.id]" type="button">Détails</button>
							</td>
						</tr>
					</ng-container>
				</table>
				<br/>
			</div>
		</form>
	</div>
</ng-container>
