import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, Sort,MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { Observable, of, fromEvent } from "rxjs";
import { debounceTime, map, distinctUntilChanged, filter, startWith, switchMap } from "rxjs/operators";

import { SalesPriceHist, SalesService } from '@app/sales/sales.service';
import { ProductsService } from '@app/sales/products/products.service';
import { calc_margin, calc_taux_marq, round_currency } from '@app/shared/util';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, NgClass, DecimalPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';
import { OnlyFloat } from '@app/shared/directive/onlyFloat.directive';

@Component({
    selector: 'app-sales-prices',
    templateUrl: './sales-prices.component.html',
    styleUrls: ['./sales-prices.component.scss', '../../styles-custom.scss'],
    standalone: true,
    imports: [MenuCommonComponent, MatFormFieldModule, MatInputModule, MatButtonToggleModule, FormsModule, MatAutocompleteModule, NgFor, MatOptionModule, MatButtonModule, MatIconModule, ActionBarComponent, ReactiveFormsModule, NgIf, MatTableModule, NgClass, DecimalPipe, OnlyFloat, MatSortModule]
})
export class SalesPricesComponent {
	@ViewChild('searchManufInput', { static: true }) searchManufInput: ElementRef<HTMLInputElement> = {} as ElementRef;
	
	public displayedColumns: string[] = ['id', 'p_name', 'purchasePrice', 'salesPrice_taxx', 'salesPrice_taxi', 'margin', 'marginrate', 'marqrate'];
	public searchmanuf: any = "";	// Search field
	// public searchmanuf: any = { id: '5', name: 'TENA' };	// for dev 
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public formGroup: any;
	public isSearching: boolean = false;
	public apiResponse: any;
	public limit_good_margin: string = '30';
	public marginFilterControl: FormControl = new FormControl();
	
	constructor(private productsService: ProductsService, private salesService: SalesService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder ) { }	
	
	ngOnInit(): void {
		// this.onSearch();  // for dev
		this.formGroup = this.fb.group({
			prices: this.fb.array([])
		});
		this.marginFilterControl = new FormControl('noFilter');
		
		fromEvent(this.searchManufInput.nativeElement, 'keyup').pipe(
			// get value
			map((event: any) => {
				return event.target.value;
			})
			// if character length greater then 2
			, filter(res => {
				if (res.length > 2) { return true}
				else {
					this.apiResponse = undefined;
					return false
				}
			})

			// Time in milliseconds between key events
			, debounceTime(500)

			// If previous query is diffent from current   
			, distinctUntilChanged()

			// subscription for response
		).subscribe((text: string) => {
			this.isSearching = true;
			this.searchGetCallPartner(text).subscribe((res: any) => {
				this.isSearching = false;
				this.apiResponse = res;
			}, (err:any) => {
				this.isSearching = false;
				console.log('error', err);
			});
		});
		// this.searchManufInput.nativeElement.focus(); // for dev comment
	}

	onSearch() {
		console.log('searchmanuf', this.searchmanuf);
		if (this.searchmanuf && this.searchmanuf.hasOwnProperty("id")) this.salesService.getPricesAsFormArray(this.searchmanuf.id)
			.subscribe((data: FormArray) => {
				this.formGroup.setControl('prices', data);
				this.dataSource = new MatTableDataSource((this.formGroup.get('prices') as FormArray).controls);
			}); else {
				console.log('Invalid searchmanuf. No search');
			}
	}



	sortData(sort: any) {  // sort is like {active: 'salesPrice_taxi', direction: 'asc'}
		const data = this.formGroup.get('prices').value;
		if (sort.active && sort.direction !== ''){
			data.sort((a: any, b: any) => {
				switch (sort.active) {
					case 'id':
						return compare(a.lnk_product.id, b.lnk_product.id, sort.direction);
					case 'p_name':
						return compare(a.lnk_product.name, b.lnk_product.name, sort.direction);
					case 'purchasePrice':
						return compare(+a.lnk_purchase_price_hist.price, +b.lnk_purchase_price_hist.price, sort.direction);
					case 'salesPrice_taxx':
						return compare(a.price_taxx, b.price_taxx, sort.direction);
					case 'salesPrice_taxi':
						return compare(a.price_taxi, b.price_taxi, sort.direction);
					case 'salesPrice_taxi':
						return compare(a.margin, b.margin, sort.direction);
					case 'margin':
						// return compare((+a.price_taxx) - (+a.lnk_purchase_price_hist.price), (+b.price_taxx) - (+b.lnk_purchase_price_hist.price), sort.direction);
						return compare( a.margin , b.margin, sort.direction);
					case 'marginrate':
						return compare(a.margin_pct , b.margin_pct, sort.direction);
					case 'marqrate':
						return compare(a.marq_pct , b.marq_pct, sort.direction);
					default:
						return 0;
				}
			});
			this.formGroup.get('prices').patchValue(data);
			this.dataSource = new MatTableDataSource((this.formGroup.get('prices') as FormArray).controls);
		}
		function compare(a: number | string | null, b: number | string | null, direction: string) {
			return ( a===null ?-1 : b===null? 1 : ( a < b ? -1 : 1)) * (direction === 'asc' ? 1 : -1);
		}

		if (sort.active === 'margin' || sort.active === 'marginrate' ||  sort.active === 'marqrate')
			data.forEach((value: any, index: any) => {
				this.onKeyUpPrice( index);
			});

	}

	showRow(row : any): boolean {
		switch (this.marginFilterControl.value) {
			case 'noFilter':
				return true;
			case 'goodMarginFilter':
				return row.value.margin_pct >= this.limit_good_margin
			case 'badMarginFilter':
				return ( row.value.margin_pct <= this.limit_good_margin &&  row.value.margin_pct >= 0)
			case 'negativeMarginFilter':
				return row.value.margin_pct < 0
			default:
				return true;
			}
		}


	
	onCancel(): void {
		this.searchmanuf="";
		this.dataSource = new MatTableDataSource();
		this.apiResponse = undefined;
		this.searchManufInput.nativeElement.focus();  
	}	

	onSubmit() {
		// TODO: Use EventEmitter with form value
		console.log(this.formGroup.get("prices").value);
		this.salesService.postPrices(this.formGroup.value)
			.subscribe((response: string) => console.log("ret: " + response) );
	}

	onKeydown(event: any) {
		if (event.key === "Enter") {
			console.log(event);
			this.onSearch();
		}
	}
	
	searchGetCallPartner(term: string) : Observable<any>{
		if (term === '') {
			return of([]);
		}
		return this.productsService.search_manuf('*' + term + '*');
	}		

	onKeyUpPrice(i: any, inputType?: string ){	// Update derived values
		const prices =  <FormArray>this.formGroup.controls.prices;
		let fg = prices.at(i);
		let tva = 0.2 // FIXME hard coded tax rate
		if (inputType === 'salesPrice_taxi') {
			const price_taxx = round_currency((+fg.get('price_taxi')?.value) / ( 1+tva)  ); 
			console.log('price_taxx',price_taxx);
			fg.get('price_taxx')?.setValue(price_taxx);

		} else {
			const price_taxi = round_currency((+fg.get('price_taxx')?.value) *  ( 1+tva)); 
			fg.get('price_taxi')?.setValue(price_taxi);
		}
		fg.get('margin')?.setValue(round_currency(fg.get('price_taxx')?.value - fg.get('lnk_purchase_price_hist')?.get('price')?.value));

		let margin_pct = calc_margin(fg.get('lnk_purchase_price_hist')?.get('price')?.value, fg.get('price_taxx')?.value);
		if (margin_pct) margin_pct = round_currency(margin_pct * 100);
		fg.get('margin_pct')?.setValue(margin_pct);
		let marq_pct = calc_taux_marq(fg.get('lnk_purchase_price_hist')?.get('price')?.value, fg.get('price_taxx')?.value);
		if (margin_pct) margin_pct = round_currency(margin_pct * 100);
		fg.get('marq_pct')?.setValue(margin_pct);
	}
	onClickSelectAll(e:any){	if(e && e.target && typeof e.target.select === "function" )   e.target.select();   }

	
	displayFn(o:any) {
		if (o) return o.name
		else return null;
	}
	
	get search_enabled():boolean {
		return (this.searchmanuf && this.searchmanuf.hasOwnProperty("id")) as boolean;
	}
}
