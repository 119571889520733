<div class="pagecontent fixedtop" *ngIf="dataSource" >
	<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Produit</th>
			<td mat-cell *matCellDef="let p" >{{ p.product_name }}</td>
		</ng-container>
		<ng-container matColumnDef="qty">
			<th mat-header-cell *matHeaderCellDef>Qté</th>
			<td mat-cell *matCellDef="let p" >{{ p.qty }}</td>
		</ng-container>		
		<ng-container matColumnDef="uprice">
			<th mat-header-cell *matHeaderCellDef>Px. U</th>
			<td mat-cell *matCellDef="let p" >{{ p.uprice_taxi }}</td>
		</ng-container>		
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"></tr>
	</table>
</div>
