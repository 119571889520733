<app-menu-common url="sales" *ngIf="!dialogData"></app-menu-common>
<mat-dialog-actions align="end" *ngIf="dialogData">
	<button mat-button [mat-dialog-close]=""><mat-icon>close</mat-icon></button>
</mat-dialog-actions>

<div class="pageheader flexcolumn" (keydown)="onKeydown($event)">
	<div class="flexgrid" >
		<div class="col1">
			<div class="pagetitle grey">Recherche Tiers</div>
			<button mat-flat-button color="primary" type="button" [routerLink] = "['/common/partner']">Nouveau Tiers</button>		
		</div>
		<div class="col2">
			<div>
				<mat-form-field id="nameinputgroup">
				  <input  #searchNameInput matInput [(ngModel)]="searchname" placeholder="Rechercher par nom / prénom"  autofocus >
				  <button matSuffix mat-icon-button aria-label="Clear" (click)="searchname=''">
				    <mat-icon>close</mat-icon>
				  </button>	  
				</mat-form-field>
			</div>
			<div *ngIf="false">
				<mat-form-field id="addressinputgroup">
				  <input matNativeControl #searchAddressInput matInput [(ngModel)]="searchtown" placeholder="Adresse" disabled>
				  <button matSuffix mat-icon-button aria-label="Clear" (click)="searchtown=''">
				    <mat-icon>close</mat-icon>
				  </button>		  
				</mat-form-field>
			</div>		
			<div>
				<label class="">Activité&nbsp;</label>
				<select [(ngModel)]="searchact">
					<option *ngFor="let su of activities" [value]="su.id">{{su.name}}</option>
				</select>
			</div>		
		</div>
		<div class="col3">
			<div>
				<mat-checkbox [disabled]="false" [checked]="true">Client</mat-checkbox>
				<mat-checkbox [disabled]="false" [checked]="false">Fournisseur</mat-checkbox>
				<mat-checkbox [disabled]="false" [checked]="false">Fabricant</mat-checkbox>
			</div>
			<div>
				<label class="">Site&nbsp;</label>
				<select [(ngModel)]="searchstore">
					<option value="0">Tous</option>
					<option value="1">Pdv Ollioules</option>
					<option value="2">Pdv La Valette</option>
				</select>				 
			</div>
		</div>
	</div>

	<div class="actionline">
    <span class="spacer"></span>
    <button class="center largebutton" mat-flat-button color="primary" aria-label="Rechercher" (click)="onSearch()">
    <mat-icon>search</mat-icon>
    </button>
    &nbsp;&nbsp;
    <button class="center largebutton" mat-flat-button color="basic" aria-label="Effacer" (click)="onCancel()">
    <mat-icon>clear</mat-icon>
    </button>      
    <span class="spacer"></span>
  </div>	
</div>

<div class="pagecontent" *ngIf="dataSource" >
	<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
		  <ng-container matColumnDef="id">
		    <th mat-header-cell *matHeaderCellDef>Id</th>
		    <td mat-cell *matCellDef="let p" >{{ p.id }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="store">
		    <th mat-header-cell *matHeaderCellDef>Site</th>
		    <td mat-cell *matCellDef="let p" >{{ p.store }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="activity">
		    <th mat-header-cell *matHeaderCellDef>Activité</th>
		    <td mat-cell *matCellDef="let p" >{{ map_activities.get(p.typ) }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="name">
		    <th mat-header-cell *matHeaderCellDef>Nom</th>
		    <td mat-cell *matCellDef="let p" >{{ p.name }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="firstname">
		    <th mat-header-cell *matHeaderCellDef>Prénom</th>
		    <td mat-cell *matCellDef="let p" >{{ p.firstname }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="street1">
		    <th mat-header-cell *matHeaderCellDef>Appart.</th>
		    <td mat-cell *matCellDef="let p" > {{ p.addresses[0]?.street1 }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="street2">
		    <th mat-header-cell *matHeaderCellDef>Bât.</th>
		    <td mat-cell *matCellDef="let p" > {{ p.addresses[0]?.street2 }}</td>
		  </ng-container>

		  <ng-container matColumnDef="street3">
		    <th mat-header-cell *matHeaderCellDef>Rue</th>
		    <td mat-cell *matCellDef="let p" > {{ p.addresses[0]?.street3 }}</td>
		  </ng-container>			  			  

		  <ng-container matColumnDef="zip">
		    <th mat-header-cell *matHeaderCellDef>CP</th>
		    <td mat-cell *matCellDef="let p" > {{ p.addresses[0]?.zip }}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="city">
		    <th mat-header-cell *matHeaderCellDef>Ville</th>
		    <td mat-cell *matCellDef="let p" > {{ p.addresses[0]?.city }}</td>
		  </ng-container>			  			  
		  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClickRow(row, $event)" ></tr>
	</table>
</div>

