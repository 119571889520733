<app-menu-common url="sales"></app-menu-common>

<div *ngIf="formGroup && formGroup.value.lines">
	
	<div class="pageheader flexcolumn" [formGroup] = "formGroup">
		<section class="flexrow">
			<div class="grey pagetitle" *ngIf="!id">Nouveau Bon de commande</div>
			<div class="grey pagetitle" *ngIf="id">Bon de commande<ng-container >&nbsp;-&nbsp;Site&nbsp;{{ formGroup.value.store }}</ng-container></div>
			<span class="spacer"></span>
			<div *ngIf="id" class="grey pagetitle">#&nbsp;{{id}}</div>
		</section>
		<div class="flexgrid">
			<section class="left">
				<section class="flexrow">
					<div class="label">Créé le </div>
					<div> {{ datState(1) | date:'dd/MM/yy hh:mm' }}</div>
				</section>
				<section class="flexrow">
					<div class="label">Client</div>
					@if(customer){	 
						<div [class.unidentified]="!customer" id="customername">{{ customer_label }}</div>
					} @else {
						<mat-form-field id="nameinputgroup" >
							<input matInput formControlName="searchnameClient" placeholder="Rechercher par nom / prénom / NIR" [matAutocomplete]="auto" (keydown)="onKeyDownInputClient($event)" [cdkTrapFocusAutoCapture]="searchnamefocus" [cdkTrapFocus]="searchnamefocus">
							<button matSuffix mat-icon-button aria-label="Clear" (click)="resetCustomer()" >
							  <mat-icon>close</mat-icon>
							</button>
							<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayClientName" autoActiveFirstOption >
								<mat-option  *ngFor="let client of apiResponseClients" [value]="client" (click)="onClickonclientOption(client)" >
								  {{displayClientName(client)}}
								</mat-option>
							</mat-autocomplete>	  
						</mat-form-field>
					}
					@if(formGroup.value.state <= 2){	<!--Search client icon (magnifier) only on init state -->
						@if(formGroup.value.customer != 0){	<!--Reset customer button-->
						<button matSuffix mat-icon-button aria-label="Clear" (click)="resetCustomer()" matTooltip="Effacer">
							<mat-icon>close</mat-icon>
						</button>
						} @else {
						<button mat-icon-button color="primary" id="advancecustomersearch" (click)="openCustomerDialog()" matTooltip="Recherche avancée"><mat-icon>search</mat-icon></button>
						<button mat-icon-button matTooltip="Lire carte vitale" color="primary" (click)="onbsclick()">
							<mat-icon >credit_card</mat-icon>
						</button>
						}
					}
				</section>
				<section class="flexrow">
					<div class="label">Adresse de livraison</div>
					<div class="row">
						<select *ngIf="customer && customer.id !== 0" formControlName="addr_deliv" [(selectedIndex)]="addr_liv_index" >
							<option *ngFor="let ad of customer.addresses; let i = index" [value]="ad.id" >{{ad.street1}} {{ad.street2}} {{ad.street3}} {{ad.zip}} {{ad.city}}</option>
						</select>
					</div>
				</section>
				<section class="flexrow">
					<div class="label">Adresse de facturation</div>
					<div class="row">
						<select *ngIf="customer && customer.id !== 0" formControlName="addr_bill">
							<option *ngFor="let ad of customer.addresses" [value]="ad.id" >{{ad.street1}} {{ad.street2}} {{ad.street3}} {{ad.zip}} {{ad.city}}</option>
						</select>
					</div>
				</section> 		
			</section>
			<section class="right flexcolumn">
				<div class="flexcolumn" style="align-self: end;">
					<div class="status" ><span [ngClass]="{created:formGroup.value.state === constants.SALES_ORDER_STATE_CREATED, quote:formGroup.value.state === constants.SALES_ORDER_STATE_QUOTE, preparation:formGroup.value.state === constants.SALES_ORDER_STATE_PREPARATION, delivered:formGroup.value.state === constants.SALES_ORDER_STATE_DELIVERED, invoiced:formGroup.value.state === constants.SALES_ORDER_STATE_INVOICED, payed:formGroup.value.state === constants.SALES_ORDER_STATE_PAYED}" [class.valid]="formGroup.value.state === 2">{{ sales_order_status.get(formGroup.value.state) }}</span></div>
					<div class="datstate" *ngIf="datCurrentState">Le {{ datCurrentState | date:'dd/MM/yy hh:mm' }}</div>
				</div>
				<app-thumb-delivery-type [formGroup]="formGroup"></app-thumb-delivery-type>
				<!-- <app-vignette-mode-delivery [formGroup]="formGroup"  ></app-vignette-mode-delivery> -->

			</section>
		</div>
	</div>
	
	<app-action-bar form="mainform" (submitEmitter)="onActionBarSubmit($event)" [hassave]="true"> 
		<ng-container *ngIf="true"> 
			<app-quotation-action (submitEmitter)="onActionBarAction($event)" [state]="formGroup.value.state"></app-quotation-action>
		</ng-container>
	</app-action-bar>
	
	<div class="pagecontent">
		<form class="grow1" [formGroup]="formGroup" (ngSubmit)="onSubmit()" id="mainformXX" >
			<div class="maincontent">
				<mat-tab-group [selectedIndex]="selectedtab" (selectedIndexChange)="selectedIndexChange($event)">
					<mat-tab label="Produits" *ngIf="loaded">
						<app-quotationgeneral [loadOrderEventsSubject$]="loadOrderEventsSubject.asObservable()" (onSubmit)="onSubmit()"></app-quotationgeneral>
					</mat-tab>
					<mat-tab label="Livraison" *ngIf="formGroup.value.state === constants.SALES_ORDER_STATE_PREPARATION && id>0">
						<app-quotationdelivery [loadOrderEventsSubject$]="loadOrderEventsSubject.asObservable()" [submitEventSubject$]="submitEventSubject.asObservable()"></app-quotationdelivery>
					</mat-tab>					
					<mat-tab label="Options" *ngIf="true">
						<app-quotationoptions></app-quotationoptions>
					</mat-tab>
					<mat-tab label="Documents" *ngIf="true">
						<app-quotationdocs></app-quotationdocs>
					</mat-tab>											
				</mat-tab-group>
			</div>
		</form>
		@if (customer && formGroup.value.state === constants.SALES_ORDER_STATE_CREATED) {
			<div class="maincontent grow1 backgroundhistorysale"  >
				<app-salehistory [customer]="customer" [id]="id" ></app-salehistory>
			</div>
		}
	</div>	
</div>

<div *ngIf="id===-1" style="padding: 20px; font-size: x-large;">
	ERREUR : Document inexistant
</div>