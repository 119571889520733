<!--DEPRECATED-->
<button
	*ngIf="false"
	mat-flat-button
	aria-label="Livrer"
	color="primary"
	style="margin:8px;"
	type="button"
	(click)="onActionBarAction('DELIVER')">	 

	<mat-icon>local_shipping</mat-icon>
	Livrer
</button>

<button
	*ngIf="state==constants.SALES_ORDER_STATE_PREPARATION || state==constants.SALES_ORDER_STATE_QUOTE"
	mat-flat-button
	aria-label="Dévalider"
	color="primary"
	style="margin:8px;"
	type="button"
	(click)="onActionBarAction('DEVALIDATE')">	 

	<mat-icon>remove_done</mat-icon>
	Dévalider
</button>

<button
	*ngIf="state==constants.SALES_ORDER_STATE_PREPARATION"
	mat-flat-button
	aria-label="Changer en devis"
	color="primary"
	style="margin:8px;"
	type="button"
	(click)="onActionBarAction('QUOTE')">	 

	<mat-icon>request_quote</mat-icon>
	Changer en devis
</button>

<button
	*ngIf="state==constants.SALES_ORDER_STATE_QUOTE"
	mat-flat-button
	aria-label="Changer en devis"
	color="primary"
	style="margin:8px;"
	type="button"
	(click)="onActionBarAction('PREPARATION')">	 

	<mat-icon>request_quote</mat-icon>
	Changer en préparation
</button>

<button
	mat-flat-button
	aria-label="Imprimer"
	color="primary"
	style="margin:8px;"
	type="button"
	
	[matMenuTriggerFor]="menu">
	<mat-icon>print</mat-icon>
	Imprimer
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onActionBarAction('PRINT_ORDER')" [disabled]="state < 2">Bon de commande</button>
  <button mat-menu-item (click)="onActionBarAction('PRINT_QUOTE')">Devis</button>
</mat-menu>

<button 
	*ngIf="false"
	mat-flat-button
	aria-label="Vaider"
	color="primary"
	style="margin:8px;"
	type="button"
	(click)="onActionBarAction('VALID')">
	<mat-icon>done</mat-icon>
	Valider
</button>
