<app-menu-common url="sales"></app-menu-common>
<div class="pageheader flexcolumn">
	<section class="flexrow">
		<div class="grey pagetitle">Bons de commande à facturer</div>
	</section>
</div>

	<app-action-bar [hassave]="false" > 
		<ng-container *ngIf="true"> 
			<app-invoice-creation-action (submitEmitter)="onActionBarAction($event)"></app-invoice-creation-action>
		</ng-container>
	</app-action-bar>
	
<ng-container *ngIf="datasource">
	<div class="orderblock bglavander" *ngFor="let add of datasource">
		<section class="flexrow">
			<div class="label">Client :</div>
			<div>{{ add.lnk_customer.firstname }}&nbsp;{{ add.lnk_customer.name }}</div>
		</section>
		<section class="flexrow">
			<div class="label">Adresse de facturation :</div>
			<div>{{ add.lnk_addr_bill.street1 }}&nbsp;{{ add.lnk_addr_bill.street2 }}&nbsp;{{ add.lnk_addr_bill.street3 }}&nbsp;{{ add.lnk_addr_bill.zip }}&nbsp;{{ add.lnk_addr_bill.city }}&nbsp;</div>
		</section>
				
		<div class="order" *ngFor="let o of add.lnk_orders">
			<div class="order_detail flexrow">
				<div>
					<div class="weight-500 ordertitle" ><a href="#" [routerLink]="['/sales/quotation/', o.id]">Commande n° {{ o.id }}</a></div>
					<section class="flexrow">
						<div class="label">Addresse de livraison</div>
						<div>{{ o?.lnk_partner_deliv?.street1 }}&nbsp;{{ o?.lnk_partner_deliv?.street2 }}&nbsp;{{ o?.lnk_partner_deliv?.street3 }}&nbsp;{{ o?.lnk_partner_deliv?.zip }}&nbsp;{{ o?.lnk_partner_deliv?.city }}&nbsp;</div>
					</section>
					<section class="flexrow">
						<div class="label">Date de validation&nbsp;:</div>
						<div>{{ datState(o, 1) | date:'dd/MM/yy hh:mm' }}</div>
					</section>					
					
				</div>
				<div class="action">
					<button mat-flat-button color="primary" (click)="onClickChangeState(o.id, 3)" *ngIf="o.state===2"><mat-icon>local_shipping</mat-icon>Livrer</button>
					<button mat-flat-button color="primary" (click)="onClickChangeState(o.id, 2)" *ngIf="o.state===3"><mat-icon>block</mat-icon>Annuler liv.</button>
					<section class="weight-500 status" ><div [ngClass]="{init:o.state === 1, valid:o.state === 2, delivered:o.state === 3, soldout:o.state === 5}" [class.valid]="o.state === 2">{{ sales_order_status.get(o.state) }}</div></section>
				</div>
			</div>
			<mat-accordion>
				<mat-expansion-panel>
					<mat-expansion-panel-header>
		      	<mat-panel-title xlass="flexrow"><div>Total TTC : {{ o.amount_taxi }}</div><span class="spacer"></span><div>{{ o.lines.length }}&nbsp;lignes</div></mat-panel-title>
					</mat-expansion-panel-header>
					<div class="orderline" *ngFor="let l of o.lines">
						<span class="col1">{{ l.product_name }}</span><span class="col2">{{ l.qty }}</span><span class="col3">{{ l.uprice_taxi }}</span><span class="col3">{{ l.qty * l.uprice_taxi }}</span>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		<div>
		</div>
				
		</div>
	</div>
</ng-container>
