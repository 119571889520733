import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { Observable, throwError, merge, EMPTY } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { Event, Partner } from '@app/shared/_models/common';
import { Sales_order } from '@app/shared/_models/sales';

import { environment } from '@app/../environments/environment';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private http: HttpClient) { }
  
  postPrintEvent(objecttyp: number, objectid: number, data: any): Observable<any> {
	  console.log("postPrintEvent");
	  let event:Event = {
		  id: 0,
		  typ: 4,					// 4: Print
		  objecttyp: objecttyp,		
		  objectid: objectid,
		  data: data,
	  }
	  return this.http.post<any>(environment.server_url + "/event", event, { 'headers': headers });
  }  
}
