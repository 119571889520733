<app-menu-common url="sales"></app-menu-common>

<ng-container *ngIf="dataSource && formGroup">
	<div class="pageheader">
		<div class="flexrow">
			<span>Produit<span class="darker">&nbsp;{{formGroup.get("name")?.value}}&nbsp;{{formGroup.get("attributestring")?.value}}</span></span>
			<span class="spacer"></span>
			<span>#{{product_id}}</span>		
		</div>
		<div class="flexgrid">
			<div class="left">
				<div>
					<span class="label">Fabricant</span>&nbsp;<span>{{formGroup.get("lnk_template")?.get("lnk_partner_manuf")?.get("name")?.value}}</span>
				</div>
				<div>
					<span class="label">Distributeur</span>
				</div>						
										
				<div>
					<span *ngIf="formGroup.get('is_purchased')?.value">Autorisé Achat</span><span *ngIf="!formGroup.get('is_purchased')?.value">Interdit Achat</span>&nbsp;/&nbsp;<span *ngIf="formGroup.get('is_sold')?.value">Autorisé vente</span><span *ngIf="!formGroup.get('is_sold')?.value">Interdit vente</span>
				</div>				
			</div>
			<div class="right flexcolumn">
				<a [routerLink]="['/sales/product', template_id]">
					<button mat-mat-icon-button color="primary" aria-label="Voir le modèle">
    				<mat-icon>settings</mat-icon>
  				</button>			
				</a>
			</div>					
		</div>
	</div>
	
	<app-action-bar form="mainForm" (submitEmitter)="onSubmit($event)"></app-action-bar>
	
	<div class="pagecontent">
		<form id="mainForm" [formGroup] = "formGroup" (ngSubmit)="onSubmit('')">		
			<div class="maincontent" >
				<mat-form-field [style.width.%]=50>
					<mat-label>Input</mat-label>
					<input matInput type="text" formControlName="name">
				</mat-form-field>

				<mat-tab-group [(selectedIndex)]=tab (selectedTabChange)="selectedTabChange($event)">
					<mat-tab label="Général">
						<app-variantgeneral (valueChange)="childValueChange($event)"></app-variantgeneral>
					</mat-tab>
					<mat-tab label="Condt. / Codes barres" *ngIf="true">
						<app-variantpackaging [template]="template_id" [product]="product_id"></app-variantpackaging>
					</mat-tab>
				</mat-tab-group>
			</div>
		</form>
	</div>
</ng-container>
