import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, ControlContainer, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ProductsComponent } from "../../products/products.component"
import { ProductsService } from '@app/sales/products/products.service';
import { ValuesService } from '@app/common/values/values.service';
import { C_value } from "@app/shared/_models/common";
import { calc_margin, round_currency } from '@app/shared/util';
import { BehaviorSubject, Observable, pipe, Subscription } from 'rxjs';
import * as constants from "@app/shared/constants";
import { Sales_tax } from '@app/shared/_models/sales';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, DecimalPipe, KeyValuePipe } from '@angular/common';
import { DiscountComponent } from '@app/sales/quotation/discount/discount.component';
import { FormatCurrencyPipe } from '@app/shared/pipes/format-currency.pipe';
import { QuotationService } from '../quotation.service';

@Component({
	selector: 'app-quotationgeneral',
	templateUrl: './quotationgeneral.component.html',
	styleUrls: ['./quotationgeneral.component.scss', '../../../styles-custom.scss'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, RouterLink, MatButtonModule, MatTooltipModule, MatIconModule, DecimalPipe, KeyValuePipe, FormatCurrencyPipe]
})
export class QuotationgeneralComponent implements OnInit, OnDestroy {
	formGroup!: FormGroup;									// Inherited from parent
	// payformGroup!: FormGroup;								// Payment form group (local)
	searchinput: FormControl = new FormControl('');
	userpriceinput: FormControl = new FormControl('');
	linetype: FormControl = new FormControl<number>(1);			// Defaults to 1 (item). 
	paymenttype: FormControl = new FormControl<number>(1);
	paymentinput: FormControl = new FormControl<number | undefined>(undefined);
	paymentmethods: C_value[] = [];
	map_paymentmethods = new Map();
	nbitems: number = 0;
	remainer: number = 0;
	payed: number = 0;
	change: number = 0;
	salehistorySubscription!: Subscription;
	test: Function = () => { console.log('salut from console quotation general component'); }

	@Input() loadOrderEventsSubject$!: Observable<number>;								// Parent order was loaded
	private eventsSubscription!: Subscription;
	@Output("onSubmit") onSubmit: EventEmitter<any> = new EventEmitter();	// To send submit to parent
	@ViewChild('search') searchElement!: ElementRef;

	constructor(private valuesService: ValuesService, private productsService: ProductsService, public dialog: MatDialog, private fb: FormBuilder, public controlContainer: ControlContainer, private http: HttpClient, private quotationservice: QuotationService) { }

	ngOnInit(): void {
		this.formGroup = this.controlContainer.control as FormGroup;					// formgroup is inherited from parent
		// TODO load payformGroup from service
		/*		this.payformGroup = this.fb.group({
					paylines: this.fb.array([]),
				});*/

		this.salehistorySubscription = this.quotationservice.subject.asObservable().subscribe((data) => {
			this.addLine(data.product, data.qty)
		})

		this.eventsSubscription = this.loadOrderEventsSubject$.subscribe((id_order) => { 	// Triggered on Order loading. Flag is unused
			console.log("Received id_order:" + id_order);
			if (id_order > 0) this.processTotal();
			if (id_order > 0) this.processRemainer();
			// if (flag) this.processTax();
		});
		this.valuesService.getValues$(constants.C_PAYMENT_METHOD)	// Get payment methods
			.subscribe((data: any) => {
				this.paymentmethods = data;
				this.paymentmethods.map(a => {
					this.map_paymentmethods.set(a.id, a.name);	// Exclude non positive keys from list
				})
			});
	}

	search_productpackaging(index: string): void {						// Searching from barcode => result is a product_packaging_val
		if (!this.searchinput.value) return;	// Input field is empty => NOP
		let bc_s: string = this.searchinput.value.toString().trim();
		let retour_reg_match = bc_s.match(new RegExp("^(([0-9]*)[xX\*])?([0-9]+)$"))
		if (!retour_reg_match || retour_reg_match.length < 3) {  // We have string so we open product dialog
			this.openProductDialog();
			this.searchinput = new FormControl('');
			return
		}
		let qty = retour_reg_match[2] ? +retour_reg_match[2] : 1
		let bc = +retour_reg_match[3]
		// console.log("bc - qty: " + bc+" - " + qty);

		let $obs = this.productsService.search_productpackaging(index, bc).subscribe((ppv: any) => {	// Result is a product_packaging_val
			// console.log('productsService.search_bc: ' + JSON.stringify(ppv));
			if (ppv) {
				if (this.linetype.value == 1) {	// Item => just add line
					this.addLine(ppv, qty);
				} else if (this.linetype.value == 2) {	// Discount => must provide discount amount
					this.openDiscountDialog(ppv, qty, (discount: any) => {
						if (discount) {
							this.addLine(ppv, qty);
							this.addDiscountLine(ppv, qty, discount);
						}
					});

				}
			} else {	// Barcode does NOT exist
			}
			$obs.unsubscribe();
		});
	}


	addLine(ppv: any, qty: number): void {				// Add a line from product_packaging_val API to formgroup
		this.lines.push(this.fb.group({
			id: 0,										// API needs an id
			id_order: 0,
			seq: this.lines.length + 1,
			typ: Number(this.linetype.value),
			product: [ppv.product],
			product_barcode: [ppv.barcode],
			template: [ppv.lnk_product.template],
			qty: [qty],
			product_name: [ppv.lnk_product.name],			// Crashed once, why?
			vat: [ppv.lnk_product.lnk_template.vat],
			vat_rate: [parseFloat(ppv.lnk_product.lnk_template.vat_rate)],
			vat_amount: [0],
			uprice_taxi: new FormControl<number>(ppv.lnk_product.price_taxi),
			uprice_taxx: new FormControl<number>(ppv.lnk_product.price_taxx),
			amount_taxi: new FormControl<number>(ppv.lnk_product.price_taxi * ppv.lnk_packaging.qty),
			amount_taxx: new FormControl<number>(ppv.lnk_product.price_taxx * ppv.lnk_packaging.qty),
			discount_taxi: [0],
			discount_taxx: [0],	// TODO	is this usefull?
			packaging_name: [ppv.lnk_packaging.name],
			packaging_qty: [parseFloat(ppv.lnk_packaging.qty)],
			memo: [''],
		}));
		this.searchinput = new FormControl('');				// Empty input control
		this.searchElement!.nativeElement.focus();
		this.processTotal();
	}

	addDiscountLine(ppv: any, qty: number, discount: any): void {				// Add a line from product_packaging_val API to formgroup
		let uprice_taxi = discount.inputfixed - ppv.lnk_product.price_taxi;
		let uprice_taxx = discount.inputfixed - ppv.lnk_product.price_taxx;
		this.lines.push(this.fb.group({
			id: 0,										// API needs an id
			id_order: 0,
			seq: this.lines.length + 1,
			typ: this.linetype.value,
			product: [ppv.product],												// TODO keep item id?
			product_barcode: [ppv.barcode],
			template: [''],
			qty: [qty],
			product_name: ["Remise " + ppv.lnk_product.name],
			vat: [''],
			vat_rate: [''],
			vat_amount: [0],
			uprice_taxi: new FormControl<number>(uprice_taxi),
			uprice_taxx: new FormControl<number>(uprice_taxx),
			amount_taxi: new FormControl<number>(uprice_taxx * qty),
			amount_taxx: new FormControl<number>(uprice_taxx * qty),
			discount_taxi: [0],
			discount_taxx: [0],	// TODO					
			packaging_name: [ppv.lnk_packaging.name],
			packaging_qty: [parseFloat(ppv.lnk_packaging.qty)],
			memo: [''],
		}));
		this.searchinput = new FormControl('');				// Empty input control
		this.searchElement!.nativeElement.focus()
		this.processTotal();
	}

	deleteLine(i: number): void {
		this.lines.removeAt(i);
		this.processTotal();
	}

	onTotal(e: Event): void {	// State 1 -> 2. Submit
		e.stopPropagation; 		// Prevents Submit
		this.processTotal();
		this.formGroup.get('state')?.setValue(constants.SALES_ORDER_STATE_PREPARATION);	// State -> "En préparation". Skip state 2 "quote"
		this.remainer = this.formGroup.get("amount_taxi")?.value;
		this.onSubmit.emit();	// Emit submit to parent => Save Order
	}

	openProductDialog(): void {										// Open product lookup page as a modal dialog
		const dialogRef = this.dialog.open(ProductsComponent, {
			width: '80%',
			height: '80%',
			data: { mode: "DIALOG", searchname: this.searchinput.value },
		});

		dialogRef.afterClosed().subscribe(ret => {	// result could be undefined 
			console.log('The dialog was closed. Returned pid: ' + ret);
			this.searchinput.patchValue(ret);
			if (ret) {
				this.search_productpackaging('jpid');	// Looking for a product id => index jpid:
			}
		});
	}

	openDiscountDialog(ppv: any, qty: number, cb: Function) {	// Open discount dialog
		const dialogRef = this.dialog.open(DiscountComponent, {
			//width: '60%',
			//height: '40%',
			data: { mode: "DIALOG", ppv: ppv, qty: qty },
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe((ret: any) => {	// result could be undefined 
			console.log('The discount dialog was closed. Returned data: ' + JSON.stringify(ret, null, 2));
			cb(ret);
		});
	}

	processTotal(): void {
		let taxi = 0; let taxx = 0;
		this.nbitems = +0;
		for (let i = 0; i < this.lines.length; i++) {
			taxi += parseFloat(this.lines.at(i).get("uprice_taxi")?.value) * this.lines.at(i).get("qty")?.value;
			taxx += parseFloat(this.lines.at(i).get("uprice_taxx")?.value) * this.lines.at(i).get("qty")?.value;
			this.nbitems += +this.lines.at(i).get("qty")?.value;
		}
		//this.total = total;
		this.formGroup.get("amount_taxi")?.patchValue(+taxi);
		this.formGroup.get("amount_taxx")?.patchValue(+taxx);
	}

	processRemainer(): void {
		this.payed = 0;	// WARNING computed in cents to prevent weird float behaviours
		for (let i = 0; i < this.paylines.length; i++) {
			this.payed += parseFloat(this.paylines.at(i).get("amount")?.value) * 100;
		}
		let remainer = (this.formGroup.get("amount_taxi")?.value * 100) - this.payed;
		this.payed = Math.round(this.payed) / 100;	// Back to €
		this.remainer = Math.round(remainer) / 100;
	}

	onPriceQtyBlur(i: number): void {
		if (this.formGroup.value.state > constants.SALES_ORDER_STATE_CREATED) return;
		// let line_amount = round_currency(this.lines.at(i).get("price_taxi")?.value * this.lines.at(i).get("qty")?.value);
		console.log("i/amount: " + i + "/" + JSON.stringify(this.lines.at(i).value));
		// this.lines.at(i).get("amount_taxi")!.setValue(+line_amount);	
		this.lines.at(i).get("typ")!.setValue(2);	// Set type to user input price
		this.processTotal();
	}

	addpayment(method: number, amount: number) {
		this.paylines.push(this.fb.group({
			seq: new FormControl<number>(this.paylines.length + 1),
			method: new FormControl<number>(+method),
			amount: new FormControl<number>(+amount),
		}));
		this.processRemainer();
		if (this.remainer <= 0) {			// Sell out => Compute change, order is sold
			this.change = -this.remainer;	// Remainer is <= 0 => change >=0
			if (this.change !== 0)
				this.paylines.push(this.fb.group({	// Add a payment line for change. Key is -1
					seq: new FormControl<number>(this.paylines.length + 1),
					method: new FormControl<number>(-1),			// Method = -1: change
					amount: new FormControl<number>(-this.change),	// Amount is negative 
				}));
			this.remainer = 0;
			this.formGroup.get('state')?.setValue(constants.SALES_ORDER_STATE_PAYED);	// State -> "PAYED"
		}
		this.paymentinput.setValue('');
		this.onSubmit.emit();	// Emit submit to parent => Save Order
	}

	deletePayments() {
		this.paylines.clear();
		this.processRemainer();
		this.onSubmit.emit();	// Emit submit to parent => Save Order
	}

	onSellout() {						// Push sellout Button => App payment for remainer value
		this.addpayment(this.paymenttype.value, this.remainer);
	}

	onCancel(): void {
		this.searchinput = new FormControl('');
	}

	onItemKeydown(e: any) {
		if (e.key === "Enter") {
			this.search_productpackaging('jbc');	// Looking for a barcode => index jbc:
			e.preventDefault(); // Prevents form submit on Enter
		}										
	}
	
	onClickSelectAll(e: any) { if (e && e.target && typeof e.target.select === "function") e.target.select(); } // ??

	onBlurSearchInput() {
		if (this.linetype.value == 1) {	// On search input blur, auto search to allow for barcode scanners to send a tab
			this.search_productpackaging('jbc');	// Looking for a barcode => index jbc:
		}
		// if(e.target ) e.target.focus();
	}

	onPaymentKeydown(e: any) {
		if (e.key === "Enter") {
			e.preventDefault();											// Prevents form submit on Enter
			this.onPayment();
		} else {
			this.paymentinput.setErrors({ 'incorrect': false });
		}
	}

	onLineKeydown(e: any, i: number) {
		if (e.key === "Enter") {
			e.preventDefault();											// Prevents form submit on Enter
			this.onPriceQtyBlur(i);
		} else {
		}
	}

	onPayment() {	// Push on payment button => Trigger addPayment w/ input value
		if (!this.paymentinput.value) return;
		if ((this.paymentinput.value > this.remainer) && this.paymenttype.value != 1) {
			console.log("Blocked payment [remainer / payment / type] = [" + this.remainer + " / " + this.paymentinput.value + " / " + this.paymenttype.value)
			// TODO alerte pas de rendu de monnaie
		} else {
			this.addpayment(this.paymenttype.value, this.paymentinput.value);
		}
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
		this.salehistorySubscription.unsubscribe();
	}

	get lines(): FormArray {
		return this.formGroup.controls["lines"] as FormArray;
	}

	get paylines(): FormArray {
		return this.formGroup.controls["payment"] as FormArray;
	}

	get linesControls(): FormGroup[] {		// This is used to comply to TS type checking on formGroup
		return (this.formGroup.controls["lines"] as FormArray).controls as FormGroup[];
	}

	// convenience getter for easy access to form fields
	get f() { return this.formGroup.controls; }
}
