<div class="salehistoryorder" >

    <div class="grey pagetitle">Historique Client</div>
    <table style="width: 100%; table-layout:auto;" >
   @for(order of orders ; track order.id; ){
    <!-- <div class="order"> -->

        <!-- <tr class="titledateorder" >Commande du  <span class="dateorder">{{  order.dates[1] | date:'dd/MM/yy hh:mm' }}</span></tr> -->
        <tr>
            <td class="titledateorder" colspan="6">Commande N°{{order.id}} du  <span class="dateorder">{{  order.dates[1] | date:'dd/MM/yy ' }}</span></td>
        </tr>

        <tr>
                <th >&nbsp;</th>
                <th>&nbsp;</th>
                <!-- <th class="col0">#</th> -->
                <th class="col1">Id</th>
                <!-- <th class="col1">Code barre</th> -->
                <th class="col2">Produit - Unité de Vente</th>
                <th class="col3">Qté</th>
                <!-- <th class="col3">Colisage</th>			 -->
                <th class="col4">Px U.</th>
                <th class="col5">Total</th>
                <!-- <th class="col6">TVA</th> -->
            </tr>
            
                @for( line of order.lines; track line.id; let i =$index ){
                    <tr>
                        @if(order.lines.length >1 ){
                            @if(i===0){
                                <td [attr.rowspan]="order.lines.length" class="addgroup" >
                                    <button  mat-icon-button color="primary" aria-label="ajouter ce produit dans la commande" (click)="addAllproductOfOrder(order.lines)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </td>
                            }
                        } @else { 
                            <td class="col7">	
                               
                            </td>  
                        }
                        <td class="col7"  >	
                            <button mat-icon-button color="primary" aria-label="ajouter ce produit dans la commande" (click)="addproduct(line)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </td>
                        <!-- <td class="col0">
                            {{ line.seq }}
                        </td>				 -->
                        <td class="col1">
                            {{ line.product }}
                        </td>
                        <!-- <td class="col1">
                            {{ line.product_barcode }}
                        </td>				 -->
                        <td class="col2"> <!-- name -->
                            <a href="#" [routerLink]="['/sales/variant', line.template, line.product]" target="_blank">{{ line.product_name }}</a> <span *ngIf="line.packaging_name">-</span> {{ line.packaging_name }}
                        </td>
                        <td class="col3">	<!-- Qty -->
                            {{line.qty}}
                        </td>	
                        <!-- <td class="col3" >
                            {{ line.packaging_qty }}
                        </td>					 -->
                        <td class="col4"  *ngIf="true">	<!-- Unit price -->
                            {{line.uprice_taxi}}
                        </td>
                        <td class="col5">	<!-- Amount -->
                            {{ line.uprice_taxi * line.qty | fc }}
                        </td>		
                        <!-- <td class="col6">	
                            {{ line.vat }}
                        </td>				 -->
                    </tr>
                }
   
                <tr class="trdivider"></tr>
        <!-- </div> -->
        
        
    }
</table>
</div>
