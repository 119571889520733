import { Component, ViewChild, AfterViewInit, ElementRef, inject } from '@angular/core';
import { NgFor, NgIf, DatePipe, KeyValuePipe, JsonPipe } from '@angular/common';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle, } from '@angular/material/dialog';

import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y'

import { ActionBarComponent } from '@app/shared/action-bar/action-bar.component';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';

import { LinkedProduct, Product, ProductPackagingVal } from '@app/shared/_models/product';
import { Partner, User } from '@app/shared/_models/common';
import { Zone } from '@app/shared/_models/stock';

import { Pinvc, PinvcL } from '@app/shared/_models/stock';
import { ProductsService } from '@app/sales/products/products.service';
import { PinvcsService } from '@app/stock/pinvcs/pinvcs.service';
import { LoginService } from '@app/common/login.service';
import { AlertService } from '@app/shared/alert.service'
import * as constants from "@app/shared/constants";
import { HttpErrorResponse } from '@angular/common/http';
import { DialogBcexistsComponent } from '@app/stock/dialog-bcexists/dialog-bcexists.component';
import { FormatCurrencyPipe } from '@app/shared/pipes/format-currency.pipe';

@Component({
	selector: 'app-pinvc',
	standalone: true,
	imports: [FormatCurrencyPipe, MenuCommonComponent, MatPaginatorModule, ActionBarComponent, MatIconModule, FormsModule, A11yModule, MatButtonModule, MatTableModule, DatePipe, JsonPipe, NgIf, NgFor, RouterLink, ReactiveFormsModule],
	templateUrl: './pinvc.component.html',
	//styleUrl: './pinvc.component.scss'
	styleUrls: ['./pinvc.component.scss', '../../styles-custom.scss']
})
export class PinvcComponent {
	public dataSource: MatTableDataSource<PinvcL> = new MatTableDataSource();							// Loaded scans from DB as MatTableDataSource
	public displayedColumns: string[] = ['id', 'barcode', 'productname', 'amount', 'datscan', 'action'];
	public formGroup!: FormGroup;
	public pinvc_id: number = 0;
	public store_id: number = 0;
	public fc_bc: FormControl<string | null> = new FormControl(null);
	public fc_note: FormControl<string | null> = new FormControl(null);										// User input designation
	public fc_qty: FormControl<number | null> = new FormControl(null);
	public currentzone: FormControl<number> = new FormControl(0, { nonNullable: true });
	public product_current: LinkedProduct | undefined;			// Last scanned product
	public state: number = 0;	// 0: Waiting for BC, 1: Valid BC. Input Qty, 2: BC NOT FOUND
	public focus = 0;					// Who has the focus? 0: Barcode, 1: note, 3: Qty 
	public zones?: Zone[];


	readonly dialog = inject(MatDialog);
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	constructor(private route: ActivatedRoute, private pinvcsService: PinvcsService, private productsService: ProductsService, private router: Router, private fb: FormBuilder, private loginService: LoginService, private alertService: AlertService) { }

	ngOnInit(): void {
		this.pinvc_id = Number(this.route.snapshot.paramMap.get('id'));
		this.store_id = Number(this.route.snapshot.paramMap.get('store'));
		this.formGroup = this.fb.group({
			id: new FormControl<number>(+0),
			name: new FormControl<string | undefined>(undefined),
			typ: new FormControl<number | undefined>(undefined),
			store: new FormControl<number | undefined>(this.store_id),
			datstart: new FormControl<Date | undefined>(undefined),
		});
		if (this.pinvc_id) { this.loadPinvc(this.pinvc_id); }		// Load Pinvc header
		if (this.store_id) { this.loadZones(this.store_id); }		// Load zones of store
	}

	loadLines(pinvc: number, zone: number) {
		//this.pinvcsService.getPinvcAsFormGroup(this.pinvc_id).subscribe((data: FormGroup) => 
		if (zone == 0) return;
		this.pinvcsService.getPinvcByIdZone(pinvc, zone).subscribe({
			next: (pinvc: Pinvc) => {
				this.dataSource = new MatTableDataSource(pinvc.lines);
				this.dataSource.paginator = this.paginator;
				if (this.formGroup) {			// After submit, formgroup exists => patch
					this.formGroup.patchValue(Pinvc.asFormGroup(pinvc));
					//console.log("[load] Patching data: " + JSON.stringify(data.getRawValue()));
				} else {	// First formgroup setting. No previous value
					this.formGroup = Pinvc.asFormGroup(pinvc);
					//console.log("[load] Setting data: " + JSON.stringify(data.getRawValue()));
				}
				this.formGroup.removeControl('line');		// Lines are not used, free memory
				this.resetInput();
			},
			error: (error: HttpErrorResponse) => {
				console.error(error);
				this.alertService.error(error.message + "\n" + error.error);
			}
		});
	}

	onKeydownBC(e: any) {
		if (e.key === "Enter" || e.key === "Tab") {	// Enter key OR tab key => onBlur				
			e.preventDefault();												// Do not jump everywhere
			let bc: string | undefined = this.fc_bc.value?.toString().trim();											// Get bc
			const found = this.dataSource.data?.find(pinvcl => { return pinvcl.barcode === bc });	// Check if line is already in scanned items
			if (found) {
				console.log("pinvcl found: " + JSON.stringify(found));
				this.processDuplicate(found);										// Open dialog then NOP OR replace qty w/ new one
				return;																					// In any case, do not add a new line
			} else {																					// New barcode => normal flow: search Redis & insert in DS
				this.search_productpackaging('tmpjbc');						// Item is not in datasource, search for it in Redis index
			}
		} else {
			this.state = 0;					// Force state to "Input BC"
		}
	}

	onKeydownNote(e: any) {
		if (e.key === "Enter") {
		} else {
		}
	}

	onBlurNote() {	// Leaving note input
	}

	onKeydownQty(e: any) {
		//console.log("e: ", e);
		if (e.key === "Enter" || e.key === "Tab") {	// On enter OR TAB => Save Pinvcl and add line to the datasource
			this.addPincl();
		} else {
		}
	}

	addPincl() {	// Save Pinvcl, add line to the datasource AND on server, reset input zone
		let bc: string | undefined = this.fc_bc.value?.toString().trim();
		let lnk_user: User | undefined = this.loginService.loggeduser;
		const amount = (this.fc_qty.value == null ? 0 : +this.fc_qty.value);
		if (!amount || amount < 0 || amount>9999) {																			// TODO WARNING hard coded value
			this.alertService.error("Quantité invalide");
			return;
		}

		let newPinvcl: PinvcL = { id: 0, pinvc: this.pinvc_id, product: this.product_current?.id, zone: +this.currentzone.value, user: lnk_user?.id, amount: amount, barcode: bc, datscan: new Date(), note: this.fc_note.value ?? undefined, lnk_product: this.product_current, lnk_user: lnk_user, indnew: true };
		console.log("[addPincl] newPinvcl: " + JSON.stringify(newPinvcl));

		//for (let i:number = 0; i<200; i++) {
		let obs$ = this.pinvcsService.postPinvcL(constants.EVENTTYP_INSERT, newPinvcl).subscribe({	// TEMPLATE subscribe call w/ error handling
			next: (response: any) => {
				//console.log("[addPincl] Server response: " + JSON.stringify(response));
				newPinvcl.id = response.data.stock_pinvc_l_id;		// Update id w/ real id from server
				//console.log("[addPincl] newPinvcl: " + JSON.stringify(newPinvcl));
				this.dataSource.data.unshift(newPinvcl);					// Add line to the beginning
				this.dataSource._updateChangeSubscription(); 			// Refresh datasource
				this.resetInput();																// Reset input fields
				obs$.unsubscribe();
			},
			error: (error: HttpErrorResponse) => {
				console.error(error);
				this.alertService.error(error.message + "\n" + error.error);
			}
		});
	}

	search_productpackaging(index: string): void {						// Searching from barcode => result is a product_packaging_val
		if (!this.fc_bc.value) {																// BC Input field is empty => Manual entry, Note only
			const fakeProduct = new Product;
			fakeProduct.id = 0;
			fakeProduct.template = 0;
			fakeProduct.name = (this.fc_note.value ? this.fc_note.value : undefined);
			this.product_current = Product.asLinkedProduct(fakeProduct);
			this.state = 2;					// Set state to "NOT FOUND"
			this.focus = 1;					// Focus goes to Note			
		} else {
			let bc: string = this.fc_bc.value.toString().trim();		// TODO analyze barcode type

			let obs$ = this.productsService.search_productpackaging(index, +bc).subscribe({		// Search barcode in Redis index
				next: (ppv: ProductPackagingVal) => {																						// Result is a product_packaging_val
					if (ppv) {																																		// Found product => set product_current
						this.product_current = Product.asLinkedProduct(ppv.lnk_product);
						this.state = 1;					// Set state to "input qty"
						this.focus = 2;					// Focus goes to Qty
						//alert();
					} else {																																			// NOT FOUND => allow to add a fake product w/ a note
						const fakeProduct = new Product;
						fakeProduct.id = 0;
						fakeProduct.template = 0;
						fakeProduct.name = (this.fc_note.value ? this.fc_note.value : undefined);
						this.product_current = Product.asLinkedProduct(fakeProduct);
						this.state = 2;					// Set state to "NOT FOUND"
						this.focus = 1;					// Focus goes to Note
					}
					obs$.unsubscribe();
				},
				error: (error: HttpErrorResponse) => {
					console.error(error);
					this.alertService.error(error.message + "\n" + error.error);
					obs$.unsubscribe();
				}
			});
		}
	}

	deletePinvcl(p: PinvcL, cb: () => void = () => null) {	// Delete line from server AND Datasourse. indnew: pinvcl i in new datasource
		let obs$ = this.pinvcsService.deletePinvcL(constants.EVENTTYP_DELETE, p).subscribe({
			next: (_data) => {
				const ds = this.dataSource;	// Just a shortcut

				// var startTime = performance.now()			// TEMPLATE performance
				const index = ds.data.indexOf(p);					// Remove line from datasource
				//console.log(`Call to indexOf took ${performance.now() - startTime} milliseconds`)

				ds.data.splice(index, 1);
				ds._updateChangeSubscription(); 					// Refresh datasource // TODO message OK(?)				
				obs$.unsubscribe();
				cb();																			// Job done, call callback function
			},
			error: (error: HttpErrorResponse) => {
				console.error(error);
				this.alertService.error(error.message + "\n" + error.error);
				obs$.unsubscribe();
			}
		});
	}

	processDuplicate(pinvcl: PinvcL): void {												// pinvcl allready exists in datasource
		const dialogRef = this.dialog.open(DialogBcexistsComponent, { data: { pinvcl: pinvcl }, position: { left: '10px' } });
		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${JSON.stringify(result)}`);
			if (!result) {// Do not replace content => abort scan
				this.resetInput();
				return;
			}
			pinvcl.indnew = true;
			if (result.action == 'REPLACE') {
				pinvcl.amount = +result.qty;
			} else if (result.action == 'ADD') {
				pinvcl.amount += +result.qty;
			} else {																	// Some inconsistency => NOP. TODO alert
				this.resetInput();
				return;
			}
			// In datasource. Replace => delete then add
			this.deletePinvcl(pinvcl, () => {												// Delete datasource line
				let obs$ = this.pinvcsService.postPinvcL(constants.EVENTTYP_INSERT, pinvcl).subscribe({		// Post line creation event
					next: (_response: any) => {													// Line is created on server => add it to datasource
						this.dataSource.data.unshift(pinvcl);							// Add line to the beginning
						this.dataSource._updateChangeSubscription(); 			// Refresh datasource
						this.resetInput();
						obs$.unsubscribe();
					},
					error: (error: HttpErrorResponse) => {
						console.error(error);
						this.alertService.error(error.message + "\n" + error.error);
						obs$.unsubscribe();
					}
				});
			});
		});
	}

	loadZones(store: number) {												// Get zones from server 
		this.pinvcsService.getZonesByStore(store)
			.subscribe({
				next: (zones: Zone[]) => {
					this.zones = zones;												// Set local array
					//this.currentzone = new FormControl(zones[0].id ?? null);	// Current zone is set to first in the list
					console.log("[loadZones]");
				},
				error: (e) => {
					console.error("ERREUR Serveur : ", e.error);
				}
			});
	}

	loadPinvc(id: number) {												// Get zones from server 
		this.pinvcsService.getPinvcById(id)
			.subscribe({
				next: (pinvc: Pinvc) => {
					console.log("[loadPinvc] ", JSON.stringify(pinvc));
					this.formGroup = Pinvc.asFormGroup(pinvc);
				},
				error: (e) => {
					console.error("ERREUR Serveur : ", e.error);
				}
			});
	}

	onZoneChange(event: any) {	// Zone setting, time to load lines for tis zone
		console.log("[onZoneChange] new value: ", event.target.value);
		this.loadLines(this.pinvc_id, this.currentzone.value);
	}

	resetInput() {
		this.fc_bc = new FormControl(null);								// Reset input fields
		this.fc_qty = new FormControl(null);
		this.fc_note = new FormControl(null);
		this.product_current = undefined;									// Reset current product. TODO Set focus on bc input field
		this.state = 0;																		// Set state to "Waiting for bc"
		this.focus = 0;
	}

	getTitle(): string {
		if (this.formGroup.get('typ')?.value == 1) return "Inventaire Complet";
		else if (this.formGroup.get('typ')?.value == 2) return "Inventaire Partiel (tournant)"
		else return "Type d'inventaire inconnu";

	}
}
