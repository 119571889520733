import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValuesService {
	private _updateData$ = new BehaviorSubject<void>(undefined);	// Osvervable that triggers an update
	private cache = new Array<Observable<any>>();	// C_VALUES cache as sharereplay obs$

  constructor(private http: HttpClient) { }
  
  public getObs(id:number):Observable<any> {
		if (this.cache[id]) {
			console.log("ValuesService.getObs found in cache");
			return this.cache[id];
		}
		console.log("ValuesService.getObs cache miss!");
		const obs$ = this.http.get<void>(environment.server_url + "/common/values/" + id);
		this.cache[id]=obs$;
		return obs$;
	}
	
	public getValues$(id:any):Observable<any> {	// Get cached values
		if (this.cache[id]) {											// Values in cache
			//console.log(`ValuesService: #${id}  found in cache`);
			return this.cache[id];
		}
		//console.log(`ValuesService: #${id} cache miss!`);
		const ret$ = this._updateData$.pipe(	// Cache miss. Get values from API call
			switchMap(() => this.http.get<void>(environment.server_url + `/common/values/${id}`)),
			shareReplay(1)
		);
		this.cache[id]=ret$;		// Put value in cache
		return ret$;	
  }
  
  public updateData() {
    this._updateData$.next();
  }  
}
