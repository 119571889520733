import { Component, Input } from '@angular/core';
import { SpinnerService } from '../spinner.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule]
})
export class SpinnerComponent {
  constructor(public spin: SpinnerService) {}
}
