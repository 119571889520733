import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge, EMPTY } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { Event, Partner } from '@app/shared/_models/common';
import { Product, ProductTpl, TplPackaging, Packaging, ProductPackagingVal, ProductPackaging } from '@app/shared/_models/product';
import * as constants from "@app/shared/constants";

import { environment } from '@app/../environments/environment';

// const headers = { 'content-type': 'application/json' };

@Injectable({
	providedIn: 'root'
})

export class ProductService {

	constructor(private http: HttpClient) { }

	/* DEPRECATED */
	getProductMini(id: number) : Observable<any> {
		return this.http.get<any>(environment.server_url + "/product/mini/" + id);
	}
	
	/* DEPRECATED */
	getProductMiniAsFormGroup(id: number) : Observable<FormGroup> {
		return this.getProductMini(id).pipe(map((product: Product) => {
			console.log("product0: " + JSON.stringify(product));
			const fg = Product.asFormGroup(product);
			return fg;
		}));
	}
	
	getTemplate(id: number) : Observable<any> {
		return this.http.get<any>(environment.server_url + "/template/" + id);
	}
	
	getTemplateAsFormGroup(id: number) : Observable<FormGroup> {
		return this.getTemplate(id).pipe(map((productTpl: ProductTpl) => {
			const fg = ProductTpl.asFormGroup(productTpl);
			return fg;
		}));
	}

	getProduct(id: number) : Observable<any> {
		return this.http.get<void>(environment.server_url + "/product/" + id);
	}
	
	getProductAsFormGroup(id: number) : Observable<FormGroup> {
		return this.getProduct(id).pipe(map((product: Product) => {
			const fg = Product.asFormGroup(product);
			return fg;
		}));
	}	
	
	getPackagingsByTemplateAsFormArray(template: any) : Observable<FormArray>{	
		return this.getPackagingsByTemplate(template).pipe(map((tplPackaging: TplPackaging[]) => {
			// console.log("template: " + JSON.stringify(productTpl));
			const fgs:FormArray = new FormArray( tplPackaging.map(TplPackaging.asFormGroup));
			return fgs;
		}));
	}
	
	getPackagingsByTemplate(template: any) : Observable<any> { 
		return this.http.get<void>(environment.server_url + "/template_packagings/" + template);
	}	
	
	getPackagings() : Observable<any> { 
		return this.http.get<void>(environment.server_url + "/packagings");
	}		

	getPackagingValsByProductAsFormArray(product: any) : Observable<FormArray>{	
		return this.getPackagingValsByProduct(product).pipe(map((productPackagingVals: ProductPackagingVal[]) => {
			const fgs:FormArray = new FormArray( productPackagingVals.map(ProductPackagingVal.asFormGroup));
			return fgs;
		}));
	}
	
	getPackagingValsByProduct(product: any) : Observable<any> { 
		return this.http.get<void>(environment.server_url + "/product_packaging_vals/" + product);
	}
		
	postTemplate(formGroup: FormGroup): Observable<any> {
		console.log("In postTemplate, " + JSON.stringify(formGroup?.value));
		//return EMPTY;
		return this.http.post<any>(environment.server_url + "/template", formGroup.value, { 'headers': constants.jsonheader });
	}
	
  postProductEvent(eventtyp: number, objecttyp: number, oldFormGroup: FormGroup, newFormGroup: FormGroup): Observable<any> {
	  let old_new = {'old':oldFormGroup.value, 'new':newFormGroup.value};
	  let event:Event = {
		  id: 0,
		  typ: eventtyp,			// 1: Insert, 2: Update, 3: Delete
		  objectid: 0,
		  objecttyp: objecttyp,		// Product OR Template
		  data: old_new,
	  }
	  return this.http.post<any>(environment.server_url + "/event", event, { 'headers': constants.jsonheader });
  }
  
	postProduct(product: any): Observable<any> {
		return this.http.post<any>(environment.server_url + "/product", product, { 'headers': constants.jsonheader });
	}		
	
	postTplpackaging(tplPackaging:TplPackaging) {
		return this.http.post<any>(environment.server_url + "/tplpackaging", tplPackaging, { 'headers': constants.jsonheader });
	}
	
	deletetTplpackaging(template:number, packaging: number) {
		return this.http.delete<any>(environment.server_url + "/tplpackaging/"+ template + "/" + packaging);
	}	
	
	postPackagingVal(productPackagingVal:ProductPackagingVal) {
		return this.http.post<any>(environment.server_url + "/packagingval", productPackagingVal, { 'headers': constants.jsonheader });
	}
	
	deletePackagingVal(id:number, packaging_id: number, product_id: number) {
		return this.http.delete<any>(environment.server_url + "/packagingval/"+ id + "/" + packaging_id + "/" + product_id);
	}	
}
