import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PurchasePriceHist } from '../purchase/purchase.models';

import {calc_margin,calc_taux_marq, round_currency} from '@app/shared/util';
import { Product } from '@app/shared/_models/product';

@Injectable({
	providedIn: 'root'
})
export class SalesService {

	constructor(private http: HttpClient) { }

	getPriceLists(partner: string) {
		return this.http.get<void>(environment.server_url + "/sales/pricelists?partner=" + partner);
	}

	getPricelist(id: number) {
		return this.http.get<void>(environment.server_url + "/sales/pricelist/" + id);
	}

	getPrices(supplier: number): Observable<any> {
		return this.http.get<void>(environment.server_url + "/sales/prices?partner=" + supplier);
	}

	getPricesAsFormArray(supplier: number): Observable<FormArray> {
		return this.getPrices(supplier).pipe(map((salesPriceHists: SalesPriceHist[]) => {
			const fgs = new FormArray(salesPriceHists.map(SalesPriceHist.asFormGroup));	
			return fgs;
		}));
	}

	postPrices(prices: any): Observable<any> {
		const headers = { 'content-type': 'application/json' };
		return this.http.post<any>(environment.server_url + "/sales/prices", prices, { 'headers': headers });
	}
}

class OldSalesPriceHist {
	price_taxx: number = 0;
	price_taxi: number = 0;	
}

export class SalesPriceHist {
	id?: number;
	price_taxx: number =0;
	price_taxi: number = 0;	
	product?: number;
	pricelist?: number;
	is_active?: boolean;
	
	lnk_product: Product = new Product;	// Linked data
	lnk_purchase_price_hist?: PurchasePriceHist;
	
	static asFormGroup(salesPriceHist: SalesPriceHist): FormGroup {
		const oldfg = new FormGroup({
			price_taxx: new FormControl(+salesPriceHist.price_taxx),
			price_taxi: new FormControl(+salesPriceHist.price_taxi),			
		});
			
		let margin_pct_ = calc_margin( +(salesPriceHist.lnk_purchase_price_hist?.price??0),+(salesPriceHist.price_taxx??0)); // tout les null converti en zéro car autorise la division par 0
		let marque_pct_ = calc_taux_marq( +(salesPriceHist.lnk_purchase_price_hist?.price??0),+(salesPriceHist.price_taxx??0)); // tout les null converti en zéro car autorise la division par 0

		if (margin_pct_) margin_pct_ = round_currency(margin_pct_ * 100);
		if (marque_pct_) marque_pct_ = round_currency(marque_pct_ * 100);
		
			
		const fg = new FormGroup({
			id: new FormControl(salesPriceHist.id),
			product: new FormControl({value: salesPriceHist.product, disabled: true}),
			price_taxx: new FormControl(+salesPriceHist.price_taxx),
			price_taxi: new FormControl(+salesPriceHist.price_taxi),			
			pricelist: new FormControl({value: salesPriceHist.pricelist, disabled: true}),
			is_active: new FormControl({value: salesPriceHist.is_active, disabled: true}),
			lnk_product: Product.asFormGroup(salesPriceHist.lnk_product),
			lnk_purchase_price_hist: PurchasePriceHist.asFormGroup(salesPriceHist.lnk_purchase_price_hist),
			// Added derived controls
			old: oldfg, 
			margin: new FormControl({value: (salesPriceHist.lnk_purchase_price_hist?.price == undefined?undefined:round_currency(salesPriceHist.price_taxx - salesPriceHist.lnk_purchase_price_hist?.price)), disabled: false}),
			margin_pct: new FormControl({value: (margin_pct_), disabled: false}),
			marq_pct : new FormControl({value: (marque_pct_), disabled: false}),
		});		
		return fg;
	}
}
