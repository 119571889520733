import { Component } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTabsModule, MatTabChangeEvent} from '@angular/material/tabs';
import { ActivatedRoute, Router, NavigationEnd, RouterLink } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { Location, NgIf } from '@angular/common';
import * as constants from "@app/shared/constants";
import { ProductService } from '@app/sales/product/product.service';
import { calc_margin, round_currency } from '@app/shared/util';
import { VariantpackagingComponent } from './variantpackaging/variantpackaging.component';
import { VariantGeneralComponent } from './variantgeneral/variantgeneral.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
    selector: 'app-variant',
    templateUrl: './variant.component.html',
    styleUrls: ['./variant.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, NgIf, RouterLink, MatIconModule, ActionBarComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatTabsModule, VariantGeneralComponent, VariantpackagingComponent]
})
export class VariantComponent {
	constructor(private route: ActivatedRoute, private location: Location, private productService: ProductService, private router: Router, private fb: FormBuilder) { }

	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public oldFormGroup!: FormGroup;	// Snapshot of originally loaded product
	public formGroup!: FormGroup;
	public template_id: any;
	public product_id: number = 0;	
	public packaging: any = undefined;
	public eventtyp:number = constants.EVENTTYP_INSERT;			// Insert event by default
	
	public tab: number=0;
	
	private childChange=false;	// Child tab change detection
	private routerevents$:any;	
	loadProductEventsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	ngOnInit(): void {
		this.product_id = Number(this.route.snapshot.paramMap.get('id'));			// Extract params. product id and template id are mandatory
		this.template_id = Number(this.route.snapshot.paramMap.get('template'));
	
  	this.route.queryParams.subscribe(params => {	// 	Is there a tab number in URL? 
		if (params && params['tab'] != null) {
			this.tab=Number(params['tab']);
		} 
		});
		
		if (this.product_id === 0) {	// New product
			
		} else {
			this.loadProduct();
		}
  		
		this.routerevents$ = this.router.events.subscribe((event) => {
		    if (event instanceof NavigationEnd && this.product_id !==0) {	// Real reload, not a F5 on same page
		    	console.log("NavigationEnd, reloading");
		    	this.loadProduct();
		    }
		  });				
	}
	
	loadProduct() {
		this.eventtyp = constants.EVENTTYP_UPDATE; 										// Update mode. Will issue an update event
		this.productService.getProductAsFormGroup(this.product_id)
			.subscribe((data: FormGroup) => {
				if (this.formGroup) {			// After submit, formgroup exists => patch
					this.formGroup.patchValue(data);
				} else {									// First formgroup setting. No previous value
					this.formGroup = data;
					this.loadProductEventsSubject.next(true);	// Emit a loadorder event to child component
				}
				let margin_pct = calc_margin(this.formGroup.get('lnk_purchase_price_hist')?.get('price')?.value, this.formGroup.get('price_taxx')?.value);
				if (margin_pct) margin_pct = round_currency(margin_pct * 100);
				this.formGroup.removeControl('margin_pct');
				this.formGroup.addControl('margin_pct', new FormControl(margin_pct));
				this.oldFormGroup = JSON.parse(JSON.stringify(this.formGroup.value));		// Take a snapshot of newly loded product
			});		
	}

	onSubmit(e:String) {
		// TODO: Use EventEmitter with form value
		console.log(this.formGroup.value);
		this.productService.postProductEvent(this.eventtyp, constants.OBJECTTYP_PRODUCT, this.oldFormGroup, this.formGroup).subscribe((response: any) => {
			console.log("Server response: " + JSON.stringify(response));
			this.router.navigate(['/sales/variant/' + this.template_id + '/' + response.data.product_id]);	// Reload Product
		});				
		this.childChange = false;	// Changes saved => revert to "no change in childs"
	}
	
	selectedTabChange(event: MatTabChangeEvent) {
		console.log("selectedTabChange: " + event.index);
		if (event.index === 1 && this.childChange) {	// Leaving general tab. Check on modifications
			// TODO show a message 
			this.tab=0;	// Force tab 0
		}
	}
	
	childValueChange(e:any) {
		console.log("childValueChange");
		this.childChange = true; 
	}
}
