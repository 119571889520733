  <mat-toolbar color="primary">
		<span *ngIf="loginService?.loggeduser">
	    <button mat-icon-button class="" [matMenuTriggerFor]="menu" aria-label="Menu">
	      <mat-icon>menu</mat-icon>
	    </button>
	    
	    <mat-menu #menu="matMenu">
				<button mat-menu-item routerLink="/home">Home</button>    
			 	<button mat-menu-item routerLink="/purchase/pricelists">Achats</button>
				<button mat-menu-item routerLink="/sales/products">Ventes</button>
				<button mat-menu-item routerLink="/stock/pinvcs">Stock</button>
			</mat-menu>
		</span>

		<!-- <ng-template NavbarDirective [navbarMenu]="url"></ng-template> -->
		
		<navbarMenu [navbarTyp]="url"></navbarMenu> 
		
		<span class="spacer"></span> 
		<span *ngIf="loginService?.loggeduser">
			<button mat-button [matMenuTriggerFor]="sitemenu">{{ loginService.loggeduser?.lastname }} - Site&nbsp;{{ loginService.loggeduser?.currentstore }}<mat-icon>expand_more</mat-icon></button>
			<mat-menu #sitemenu="matMenu">
			  <button mat-menu-item (click)="onClick(1)">Site 1</button>
			  <button mat-menu-item (click)="onClick(2)">Site 2</button>
			  <hr/>
			  <button mat-menu-item (click)="logout()">Deconnexion</button>
			</mat-menu>
		</span>
	  </mat-toolbar>