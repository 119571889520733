<app-menu-common url="home"></app-menu-common>

<div class="pageheader">
	<span class="mat-headline-1 grey">Identification</span>
	<div class="badge">
		<i class="material-icons" color=”primary”>badge</i>
	</div>
</div>

<i class="material-icons badge" color=”primary”>badge</i>

<div class="pagecontent">
	<mat-card class="login-card">
		<mat-card-header>
		</mat-card-header>
		<mat-card-content>
			<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form" id="loginform" >
				<mat-form-field class="full-width row">
					<mat-label>Login</mat-label>
					 <i class="material-icons prefix inputIcon">account_circle</i>
					<input matInput placeholder="Login" formControlName="username" #login autofocus/>
				</mat-form-field>
				<br/>
				<mat-form-field class="full-width row">
					<mat-label>Mot de passe</mat-label>
					 <i class="material-icons prefix inputIcon">lock</i>
					<input matInput placeholder="Password" formControlName="password">
				</mat-form-field>
	
			</form>
		</mat-card-content>
		<mat-card-actions>
			<button mat-flat-button type="submit" color="primary" form="loginform">Connexion</button>
		</mat-card-actions>
	</mat-card>
</div>