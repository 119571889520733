<ng-container *ngIf="formGroup">
	<form id="mainForm" [formGroup]="formGroup">
		<table class="mat-elevation-z2" style="width: 100%;">
			<tr>
				<th class="mdc-data-table__header-cell">Condt.</th>
				<th class="mdc-data-table__header-cell">Multiple.</th>
				<th>&nbsp;</th>
			</tr>
			<ng-container *ngFor="let t of tplPackagings; let i = index">
				<tr class="form-row" [formGroup]="t">
					<td >{{t.get("lnk_packaging")?.get("name")?.value}}</td>
					<td >{{t.get("lnk_packaging")?.get("qty")?.value}}</td>
					<td><button mat-icon-button color="primary" matTooltip="Supprimer" aria-label="Supprimer" style="padding: 0px; margin-left:6px; max-height: 20px; max-width: 20px;" (click)="deleteLine(t.get('lnk_packaging')?.get('id')?.value)"><mat-icon style="font-size: 16px; margin-top: -6px; max-height: 20px; max-width: 20px;">delete</mat-icon></button></td>
				</tr>
			</ng-container>
		</table>

		<!-- Add a BC -->
		<div id="newbc">
		  <mat-form-field>
				<mat-select [formControl]="newpackaging">
					<mat-option *ngFor="let p of packagings" [value]="p.id">
						{{ p.name }}
					</mat-option>
				</mat-select>
		  </mat-form-field>
	
			<button type="button" mat-flat-button color="primary" matTooltip="Ajouter" aria-label="Ajouter" (click)="post_packagingval()">Ajouter</button>
		</div>
	</form>
</ng-container>