import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable, Subject, of, fromEvent, concat, forkJoin, BehaviorSubject } from "rxjs";
import { debounceTime, map, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';

import * as constants from "@app/shared/constants";
import { ProductsService } from '@app/sales/products/products.service';
import { QuotationsService } from '@app/sales/quotations/quotations.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';
import { NgFor, NgIf, DatePipe, KeyValuePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';
import { QuotationdetailsComponent } from '@app/sales/quotations/quotationdetails/quotationdetails.component'

@Component({
    selector: 'app-quotations',
    templateUrl: './quotations.component.html',
    styleUrls: ['./quotations.component.scss', '../../styles-custom.scss'],
    standalone: true,
    imports: [QuotationdetailsComponent, MenuCommonComponent, MatButtonModule, RouterLink, MatFormFieldModule, MatInputModule, FormsModule, MatIconModule, NgFor, NgIf, MatTableModule, DatePipe, KeyValuePipe]
})
export class QuotationsComponent {
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public displayedColumns: string[] = ['id', 'date', 'status', 'customer', 'amount_taxi'];
	public sales_order_state=constants.sales_order_state;
	public searchname: string = "";		// Name Search field
	public searchstate: string = "-1";	// State Search field
	public mouseoverSubject: Subject<number> = new Subject();
	public mouseoutSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
	
	constructor(private quotationsService: QuotationsService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private spinner: SpinnerService, private alert: AlertService) { }

	ngOnInit(): void {
		constants.sales_order_state.set("-1", "Tous");
	}
	
	onKeydown(event: any) {
		if (event.key === "Enter") {
			// console.log(event);
			this.onSearch();
		}
	}

	onSearch(): void {
		this.quotationsService.getQuotationsByFilter(this.searchname, this.searchstate)
			.subscribe({
					next: (items: any) => {
						this.dataSource = new MatTableDataSource(items);
						this.spinner.hide();
					},
					error: (e) => {
						console.log("ERREUR Serveur : ", e.error);
						this.spinner.hide();
						this.alert.error("ERREUR Serveur : " + e.error);	
					}});
	}
	
	onClickRow(row: any, _e: Event): void {
		this.router.navigate(['/sales/quotation/' + row.id]);	// Jump to partner only in standalone mode (not in dialog)
	}
	
	onMouseOver(row: any) {
		//console.log("Over row: " +row.id);
		this.mouseoutSubject.next(true);
		this.mouseoverSubject.next(row.id);
	}
	
	onMouseOut(row: any) {
		//console.log("Out row:" +row.id);
		this.mouseoutSubject.next(false);
	}	

	onCancel(): void {
		this.searchname="";
		this.searchstate="-1";
		this.dataSource = new MatTableDataSource();
	}

}
