<mat-dialog-actions align="end" *ngIf="dialogData">
	<button mat-button [mat-dialog-close]="" tabindex="-1"><mat-icon>close</mat-icon></button>
</mat-dialog-actions>

<table id="oldvalues" class="bglavander">
		<tr>
			<th class="col1">Id. Produit</th>
			<th class="col0">Produit</th>
			<th class="col2">Qté</th>
			<th class="col3">Px U. €</th>
			<th class="col3">Mnt TTC €</th>
		</tr>
		<tr>
			<td class="col1"> {{ ppv.product }} </td>
			<td> {{ ppv.lnk_product.name }} </td>
			<td class="col2"> {{ qty }} </td>
			<td class="col2"> {{ ppv.lnk_product.price_taxi | fc}} </td>
			<td class="col2"> {{ ppv.lnk_product.price_taxi * qty | fc}} </td>
</table>

<form [formGroup]="formGroup" >
	<div class="discount">
			<div class="flexgrid radiogroup"> <!--  cdkFocusRegionStart does NOT work! https://material.angular.io/cdk/a11y/overview#focustrap -->
				<span style="grid-column-start:1; grid-row-start: 1;" >
					<span class="radioborder" >
						<input type="radio" id="fixed" name="discountmode" formControlName="discountmode" value="1" checked (change)="onRadioChange()" tabindex="-1">
						<label class="custom-control-label" for="fixed">Prix&nbsp;forcé</label>
					</span>
				</span>					

				<span><input type="text" formControlName="inputfixed" style="grid-column-start:2; grid-row-start: 1; justify-self: end;" [ngClass]="{active:formGroup.value.discountmode == 1, inactive:formGroup.value.discountmode != 1}" tabIndex="1" (input)="onInputChange($event)" (focus)="onFocus(1)" (keyup)="onKeyUp($event)" [cdkTrapFocusAutoCapture]="formGroup.value.discountmode == 1" [cdkTrapFocus]="formGroup.value.discountmode == 1" tabindex="1"/>&nbsp;€</span>

				<span style="grid-column-start:1; grid-row-start: 2;">
					<span class="radioborder">
						<input type="radio" id="amount" name="discountmode" formControlName="discountmode" value="2" >
						<label class="custom-control-label" for="amount">Rabais en montant</label>
					</span>
				</span>					
			
				<span><input type="text" formControlName="inputamount" style="grid-column-start:2; grid-row-start: 2; justify-self: end;" [ngClass]="{active:formGroup.value.discountmode == 2, inactive:formGroup.value.discountmode != 2}" (input)="onInputChange($event)" (focus)="onFocus(2)" [cdkTrapFocusAutoCapture]="formGroup.value.discountmode == 2" [cdkTrapFocus]="formGroup.value.discountmode == 2" tabindex="2"/>&nbsp;€</span>

				<span style="grid-column-start:1; grid-row-start: 3;" >				
					<span class="radioborder">
						<input type="radio" id="pct" name="discountmode" formControlName="discountmode" value="3" >
						<label class="custom-control-label" for="pct">Rabais en %</label>
					</span>
				</span>
								
				<span><input type="text" formControlName="inputpct" style="grid-column-start:2; grid-row-start: 3; justify-self: end;" [ngClass]="{active:formGroup.value.discountmode == 3, inactive:formGroup.value.discountmode != 3}" (input)="onInputChange($event)" (focus)="onFocus(3)" [cdkTrapFocusAutoCapture]="formGroup.value.discountmode == 3" [cdkTrapFocus]="formGroup.value.discountmode == 3" tabindex="3" />&nbsp;% </span>
			</div>
			<div class="amount">
				<table id="newvalues">
					<tr><th class="col1">P.U.</th><th class="col1">Mnt TTC</th></tr>
					<tr><td class="col1">{{ formGroup.value.calcuprice | fc}}</td><td class="col1">{{ formGroup.value.calcmnt | fc}}</td></tr>
				</table>
			</div>
	</div>
</form>	

<div class="action">
	<button class="clear" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clear()"> <mat-icon>close</mat-icon> </button>
	<button type="button" mat-flat-button color="primary" matTooltip="Ok" aria-label="Ok" (click)="onSubmit()"  >Ok</button>
</div>				
						
						
									