import { Component, ElementRef, Input, Output, Renderer2, EventEmitter } from '@angular/core';
import * as constants from "@app/shared/constants";
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-quotation-action',
    templateUrl: './quotation-action.component.html',
    styleUrls: ['./quotation-action.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, MatMenuModule]
})
export class QuotationActionComponent {
	@Input() public state!: number;						// The state of the order
	@Output() submitEmitter = new EventEmitter<String>();	// Emits the triggered action
	
	public constants = constants;
	
	onActionBarAction (action:String) {
		console.log("onActionBarAction: " + action);
		this.submitEmitter.emit(action);
	}	
}
