import { Component, ViewChild, ElementRef, OnInit, Inject, Optional } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable, of, fromEvent, concat, forkJoin } from "rxjs";

import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { PartnersService } from '@app/common/partners/partners.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';
import { ValuesService } from '@app/common/values/values.service';
import { C_value } from "@app/shared/_models/common";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';
import { NgIf, NgFor } from '@angular/common';
import * as constants from "@app/shared/constants";
import * as util from "@app/shared/util";

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss', '../../styles-custom.scss'],
    standalone: true,
    imports: [NgIf, MenuCommonComponent, MatDialogModule, MatButtonModule, MatIconModule, RouterLink, MatFormFieldModule, MatInputModule, FormsModule, NgFor, MatCheckboxModule, MatTableModule]
})
export class PartnersComponent {
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public searchname: string = "";		// Name Search field
	public searchtown: string = "";		// Town Search field
	public searchact : string = "0";			// Activity search field. Defaults to 0 == ALL	
	public searchstore : string = "0";			// Store Defaults to 0 == ALL
	public activities:C_value[]=[];
	public map_activities = new Map();
	
	public displayedColumns: string[] = ['id', 'store', 'activity', 'name', 'firstname', 'street1', 'street2', 'street3', 'zip', 'city'];
	
	constructor(private partnersService: PartnersService, private valuesService: ValuesService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private spinner: SpinnerService, private alert: AlertService, @Optional() public dialogRef: MatDialogRef<PartnersComponent>, @Optional()  @Inject(MAT_DIALOG_DATA) public dialogData: any,) { }

	ngOnInit(): void {
		this.valuesService.getValues$(constants.C_VALUES_CUSTOMER_ACT)	// Get activities
			.subscribe((data: any) => {
				this.activities = data;
				this.activities.map(a => {
					this.map_activities.set(a.id, a.name);
				});
				let toutes=new C_value;																		// Add "Toutes" option on first position
				toutes.id=0; toutes.name='Toutes'; toutes.seq=0;
				this.activities.unshift(toutes);
			});		
			this.searchname = this.dialogData?.searchcriteria;
		}
	
	onKeydown(event: any) {			// TEMPLATE event.key === "Enter"
	if (event.key === "Enter") {
		this.onSearch();
		}
	}
		
	onSearch(): void {
		let obs:Observable<any>;
		if (this.searchname.length > 0 || this.searchtown.length > 0 || this.searchact != "0" || this.searchstore != "0") {	// Must input a criteria
			this.spinner.show();
			if (/*this.searchname.length == 15 &&*/ !isNaN(+this.searchname)) { // We have a NIR
				obs = this.partnersService.getPartnersByFilter(undefined, undefined, (this.searchact=="0"?"":this.searchact), (this.searchstore=="0"?"":this.searchstore), +this.searchname);
			} else {
				obs = this.partnersService.getPartnersByFilter(util.postgressearchstring(this.searchname), this.searchtown, (this.searchact=="0"?"":this.searchact), (this.searchstore=="0"?"":this.searchstore), undefined);
			}
			obs
				.subscribe({
					next: (items: any) => {
						//this.formGroup.setControl('items', items);
						this.dataSource = new MatTableDataSource(items);
						this.spinner.hide();
					},
					error: (e) => {
						console.log("ERREUR Serveur : ", e.error);
						this.spinner.hide();
						this.alert.error("ERREUR Serveur : " + e.error);	// TEMPLATE subscribe call
					}});
		}		
	}
	
	onClickRow(row: any, e: Event): void {
		if (!this.dialogRef) this.router.navigate(['/common/partner/' + row.id]);	// Jump to partner only in standalone mode (not in dialog)
		else {	// Dialog mode
			this.dialogRef.close(row);
		}
	}	
			
	onCancel(): void {
		this.searchname = "";
		this.searchtown = "";
		this.searchact = "0";
		this.searchstore = "0";
		this.dataSource = new MatTableDataSource();
	}	
	
	forceSpinnerHiding() {	// TODO
		this.spinner.hide();
	}
}
