import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';

import { PurchaseService } from '../purchase.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
    selector: 'app-pricelist',
    templateUrl: './pricelist.component.html',
    styleUrls: ['./pricelist.component.scss'],
    standalone: true,
    imports: [MenuCommonComponent, ActionBarComponent, FormsModule, ReactiveFormsModule, NgIf, MatTableModule, MatFormFieldModule, MatInputModule]
})
export class PricelistComponent {
	constructor(private purchaseService: PurchaseService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private spin: SpinnerService, private alert: AlertService) { }

	public formGroup: any;
	public id: number | undefined = undefined;	// Productlist id
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	displayedColumns: string[] = ['id', 'name', 'currentprice', 'price'];

	ngOnInit(): void {
		this.formGroup = this.fb.group({
			prices: this.fb.array([])
		});
		this.spin.show();
		this.id = Number(this.route.snapshot.paramMap.get('id'));
		this.purchaseService.getPricelistAsFormArray(this.id)
			.subscribe((formarray: FormArray) => {
				this.formGroup.setControl('prices', formarray);
				this.dataSource = new MatTableDataSource(formarray.controls);
				this.spin.hide();
			});
	}
	onSubmit(): void {
		console.log(this.formGroup.get("prices").value);
		this.alert.success("c bon");
	}
}
