<app-menu-common url="sales"></app-menu-common>

<div class="pageheader" (keydown)="onKeydown($event)">
  <div class="searchline">
		<span class="mat-headline-1 grey">Prix de Vente&nbsp;<span class="darker">{{searchmanuf.name}}</span></span>
    	<span>&nbsp;</span>
		<span class="spacer"></span>
		<mat-form-field id="inputgroup">
		  <input matNativeControl #searchManufInput matInput [(ngModel)]="searchmanuf" placeholder="Rechercher par fabricant" (keydown)="onKeydown($event)" (change)="onKeydown($event)" [matAutocomplete]="auto" autofocus (click)="onClickSelectAll($event)">
	      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"  autoActiveFirstOption >
	        <mat-option  *ngFor="let option of apiResponse" [value]="option">
	          {{option.name}}
			</mat-option>
		  </mat-autocomplete>
		</mat-form-field>
		<span class="spacer"></span>
		<button class="largebutton center" [disabled]="!search_enabled" mat-flat-button color="primary" aria-label="Rechercher" (click)="onSearch()">
		  <mat-icon>search</mat-icon>
		</button>
		&nbsp;&nbsp;
		<button class="largebutton center" mat-flat-button color="basic" aria-label="Effacer" (click)="onCancel()">
		  <mat-icon>clear</mat-icon>
		</button>     
  </div>
  <div class="filterLine"  >
	<div style="display: flex; align-items: center;">
		<span class="mat-headline-1 grey">Seuil de marge&nbsp;&nbsp;</span>
		<mat-form-field   style="width: 80px;">
			<input matInput  [(ngModel)]="limit_good_margin" value="sdfsdf" subscriptSizing="fixed"  >
		</mat-form-field>
	</div>
	 <div>
		<span class="spacer"></span><span class="spacer"></span>
		<mat-button-toggle-group   name="fontStyle" aria-label="Font Style"   [formControl]="marginFilterControl"  >
			<mat-button-toggle  value="noFilter" > <mat-icon>clear</mat-icon>&nbsp;Aucun filtre</mat-button-toggle>
			<mat-button-toggle  value="goodMarginFilter" class="greenText" ><mat-icon>thumb_up</mat-icon>&nbsp;Bonne marge</mat-button-toggle>
			<mat-button-toggle  value="badMarginFilter">marge faible</mat-button-toggle>
			<mat-button-toggle  value="negativeMarginFilter"  class="redText"><mat-icon>thumb_down</mat-icon>&nbsp;Marge négative</mat-button-toggle>
		  </mat-button-toggle-group>
	  </div> 
  </div>
</div>

<app-action-bar form="mainform"></app-action-bar>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" id="mainform">
	<ng-container *ngIf="dataSource">
		<div class="pagecontent" *ngIf="dataSource" formArrayName="prices">
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z2"  matSortDisableClear matSort (matSortChange)="sortData($event)">
		
			  <ng-container matColumnDef="id">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Produit</th>
			    <td mat-cell *matCellDef="let p;" [formGroup]="p"> {{p.get("lnk_product").get("id").value}}</td>
			  </ng-container>
			  
			  <ng-container matColumnDef="p_name">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header >Produit</th>
			    <td mat-cell *matCellDef="let p;" [formGroup]="p"> {{p.get("lnk_product").get("name").value}} </td>
			  </ng-container>			  
			  
			  <ng-container matColumnDef="purchasePrice">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header >PA&nbsp;HT</th>
			    <td class="right" mat-cell *matCellDef="let p;" [formGroup]="p"> {{p.get("lnk_purchase_price_hist").get("price").value}} </td>
			  </ng-container>
	
			  <ng-container matColumnDef="salesPrice_taxx">
			    <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header >PV&nbsp;HT</th>
			    <td mat-cell *matCellDef="let p; let i = index;" [formGroup]="p"> 
					<input type="text"  onlyFloat
					class="right" formControlName="price_taxx"
					[value]="p.get('price_taxx').value"
					(input)="onKeyUpPrice(i,'salesPrice_taxx')"
					(change)="onKeyUpPrice(i,'salesPrice_taxx')"   
					(keydown)="onKeyUpPrice(i,'salesPrice_taxx')" 
					(blur)="onKeyUpPrice(i,'salesPrice_taxx')"   
					>
				</td>
			  </ng-container>		  
			  
			  <ng-container matColumnDef="salesPrice_taxi">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header >PV&nbsp;TTC</th>
			    <!-- <td class="right" mat-cell *matCellDef="let p;" [formGroup]="p"> {{p.get("price_taxi").value  | number : '1.2-2'}}</td> -->
				<td mat-cell *matCellDef="let p; let i = index;" [formGroup]="p">
					<!-- <input id="name" type="number" [formControl]="age" /> -->
					<input type="text"  onlyFloat
					class="right" formControlName="price_taxi"
					[value]="p.get('price_taxi').value" 
					(input)="onKeyUpPrice(i,'salesPrice_taxi')"
					(change)="onKeyUpPrice(i,'salesPrice_taxi')"   
					((keydown))="onKeyUpPrice(i,'salesPrice_taxi')" 
					(blur)="onKeyUpPrice(i,'salesPrice_taxi')"   
					>
				</td>
			  </ng-container>
			  
			  <ng-container matColumnDef="margin">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header >Marge</th>
			    <td class="right" mat-cell *matCellDef="let p;" [formGroup]="p">{{ p.get("margin").value | number : '1.2-2' }}</td>
			  </ng-container>			
			  
			  <ng-container matColumnDef="marginrate">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header >Marge&nbsp;%</th><!-- (p.get("margin_pct").value >= 0.3) -->
			    <td class="right" [ngClass]="{'green': p.get('margin_pct').value >= limit_good_margin && p.get('lnk_purchase_price_hist').get('price').value >= 0, 'red': p.get('margin_pct').value < 0 }" mat-cell *matCellDef="let p;" [formGroup]="p"> {{ p.get("margin_pct").value | number : '1.2-2' }}</td>
			  </ng-container>

			  <ng-container matColumnDef="marqrate">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header >Taux&nbsp;Marque&nbsp;%</th><!-- (p.get("margin_pct").value >= 0.3) -->
			    <td class="right"  mat-cell *matCellDef="let p;" [formGroup]="p"> {{ p.get("marq_pct").value | number : '1.2-2' }}</td>
			  </ng-container>				    			  
			  
			  <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"  ></tr>
			  <tr  mat-row *matRowDef="let row; columns: displayedColumns;"  [hidden]="!showRow(row)" [ngClass]="{'greybg': row.get('lnk_purchase_price_hist').get('price').value <= 0}"></tr> [style.height.px]="20"	  
			</table>
		</div>
	</ng-container>
</form>

