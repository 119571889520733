<div id="tabcontainer" [formGroup]="formGroup" *ngIf="formGroup">
	<ng-container formGroupName="lnk_delivery">

	  <div class="flexrow">
			<mat-button-toggle-group name="deliverymodegroup" aria-label="Modes de livraison" formControlName = "mode" (change)="onChangeDeliveryMode($event)">
				@for (m of deliverymodes; track m.id) {
				  <mat-button-toggle value="{{m.id}}">{{ m.name }}</mat-button-toggle>
				}
			</mat-button-toggle-group>
			<select-integer targetControlName="askslot" c_values_id="constants.C_VALUES_DELIVERY_SLOT" *ngIf="false"></select-integer> <!-- Template for select-integer -->
			
			<div class="flexSubRow" *ngIf="showDatedel()" >
				<mat-form-field>
					<mat-label>Date de livraison demandée</mat-label>
					<!-- <input matInput [matDatepicker]="picker" formControlName="askdatdel" (dateChange)="onDateChange()" [min]="mindate"/> -->
					<input matInput [matDatepicker]="picker" formControlName="askdatdel" [min]="mindate"/> <!-- WARNING! mindate causes "Expression has changed" -->  
					<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
				
				<div>	<!-- AM / PM -->
					<mat-button-toggle-group formControlName="askslot" (change)="onChangeSlotDay($event)" >
						<mat-button-toggle value="0">Indifférent</mat-button-toggle>
						@for (slot of slotsDay; track slot.id) {
							<mat-button-toggle value="{{ slot.id }}">{{ slot.name }}</mat-button-toggle>
						}
					</mat-button-toggle-group>
				</div>
			</div>
	  </div>
		<!-- @if(validaskdatdel && momentJourneControl.value){ -->
	@if(formGroup.get('lnk_delivery')?.get('askdatdel')?.value && (formGroup.get('lnk_delivery')?.get('askslot')?.value ??0) > 0){
		<div class="flexrowLeft">
			<mat-checkbox  (change)="onChangePrecision($event, 1)" [formControl]="precisionControl1">Précision 1</mat-checkbox>
			@if(niveauPrecisionhoraire>0){
					<mat-button-toggle-group formControlName="askop">
						@for (slotsOperator of operatorsL1; track slotsOperator.id) {
						<mat-button-toggle value="{{ slotsOperator.id }}">{{ slotsOperator.name }}</mat-button-toggle>
						}
					</mat-button-toggle-group>
			}
		</div>
		<div class="flexrowLeft">
			@if(niveauPrecisionhoraire>0){
				<mat-checkbox (change)="onChangePrecision($event, 2)" [formControl]="precisionControl2">Précision 2</mat-checkbox>
				}
			@if(niveauPrecisionhoraire>1){
				<mat-button-toggle-group formControlName="askop">
					@for (slotsOperator of operatorsL2; track slotsOperator.id) {
						<mat-button-toggle value="{{ slotsOperator.id }}">{{ slotsOperator.name }}</mat-button-toggle>
					}
				</mat-button-toggle-group>
			<mat-form-field class="slots">
				<mat-select formControlName="askslot" formControlName="askslot" (selectionChange)="is_valid()">
					<mat-option *ngFor="let su of (askslotDayValue === '1' ? slotsMorning : slotsAfternoon )" [value]="su.id.toString()"  >
					{{ su.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			}
		</div>
	}

	  @if(errorMessage !==''){
	  <div class="errorMsg">
		{{ errorMessage }}
	  </div>
	  } @else {
	  <div class="validationMsg">Validation possible</div>
	  }
	  <mat-form-field class="memo">
		<mat-label>Commentaire</mat-label>
		<textarea matInput placeholder="Commentaire"></textarea>
	  </mat-form-field>
	</ng-container>
</div>
  