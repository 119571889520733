import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-product-button',
  standalone: true,
  imports: [MatButtonModule, RouterLink, MatIconModule],
  templateUrl: './product-button.component.html',
  styleUrl: './product-button.component.scss'
})
export class ProductButtonComponent {
	@Input() id: number | undefined = undefined;
	@Input() template_id: number | undefined = undefined;
	
	constructor() {
		console.log("[ProductButtonComponent constructor] ", this.template_id, this.id);
	}
}
