import { Component, ElementRef, Input, Output, Renderer2, EventEmitter } from '@angular/core';
import { ControlContainer, Form, FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SpinnerComponent } from '../spinner/spinner.component';
import { SpinnerService } from '../spinner.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
    standalone: true,
    imports: [ NgIf, MatButtonModule, MatTooltipModule, MatIconModule, ],
})
export class ActionBarComponent {
	@Input() public form: string = "";						// Name of form to submit (OBSOLETE)
	@Input() public hassave: boolean = true;			// Save button?
	@Input() public hasback: boolean = false;			// Back button?
	@Output() submitEmitter = new EventEmitter<String>();	// Emits the form name (utility?)

	constructor(private spinner: SpinnerService) { }

	ngOnInit(): void { }

	public onSubmit(e: Event) {
		console.log("ActionBarComponent - onSubmit, form: " + this.form)
		e.stopPropagation();
		this.submitEmitter.emit(this.form);
	}

	public onCancel() {
		console.log("Cancel");
		this.spinner.show();
	}

}
