import { Component } from '@angular/core';
import { NgFor, NgIf, DatePipe, KeyValuePipe } from '@angular/common';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ActionBarComponent } from '@app/shared/action-bar/action-bar.component';
import { MenuCommonComponent } from '@app/shared/menu-common/menu-common.component';

import { PinvcsService } from '@app/stock/pinvcs/pinvcs.service';

@Component({
  selector: 'app-pinvcs',
  standalone: true,
  imports: [MenuCommonComponent, ActionBarComponent, MatIconModule, MatButtonModule, MatTableModule, DatePipe, NgIf, RouterLink],
  templateUrl: './pinvcs.component.html',
  styleUrls: ['./pinvcs.component.scss', '../../styles-custom.scss']
})
export class PinvcsComponent {
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public displayedColumns: string[] = ['store', 'name', 'datstart', 'datend', 'scan'];	

	constructor (private pinvcsService: PinvcsService, private router: Router) {}
	
	ngOnInit(): void {
			this.onSearch();
	}
	
	onSearch(): void {
		this.pinvcsService.getPinvcsByFilter(1)	// TODO add a listbox w/ pinvcs types
			.subscribe({
					next: (items: any) => {
						this.dataSource = new MatTableDataSource(items);
						//this.spinner.hide();
					},
					error: (e) => {
						console.error("ERREUR Serveur : ", e.error);
						//this.spinner.hide();
						//this.alert.error("ERREUR Serveur : " + e.error);	// TEMPLATE subscribe call
					}});
	}		
	
	onCancel(): void {
		// TODO reset filters
		this.dataSource = new MatTableDataSource();
	}	
	
	onClickRow(row: any, _e: Event): void {
		//this.router.navigate(['/sales/quotation/' + row.id]);	// Jump to partner only in standalone mode (not in dialog)
	}	
	
	onEndInventory(): void {
		// TODO 
	}
	
	onScan(id_pinvcs:number, id_store:number): void {
		// TODO
		console.log('id_pinvcs: ', id_pinvcs); 
		this.router.navigate([`/stock/pinvc/${id_pinvcs}/${id_store}`]);
	}	
}
