export function calc_margin(a: number, b: number): number { // attention a es souvent un string  d'ou le double =
	if (b == 0 && a == 0) return 0; // on autorise la division par zero car bien gérée (+ infini ou - infini)
	// if (!b || !a || a===0) return undefined;
	let ret = (b - a) / a;
	return ret;
}

export function calc_taux_marq(a: number, b: number): number { // attention a es souvent un string  d'ou le double =
	if (b == 0 && a == 0) return 0; // on autorise la division par zero car bien gérée (+ infini ou - infini)
	// if (!b || !a || a===0) return undefined;
	let ret = (b - a) / b;
	return ret;
}

export function round_currency(x: number): number {
	let ret = Math.round(x * 100) / 100;
	return ret;
}

export function postgressearchstring(s: string): string {	// Get a postgres 'to_tsquery compatible search string from a search input'
	let splitted = s.trim().split(" ");
	let searchstring: string = "";
	for (let i = 0; i < splitted.length; i++) {
		let w = splitted[i].trim();
		if (w.length == 0) continue;
		w = w + ":*";
		if (i < splitted.length - 1) w = w + "%20%26%20"; // encoded <space>&<space>
		searchstring += w;
	}
	console.log("searchstring ", searchstring);
	return searchstring;
}