import { Component, Input, Injectable, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, ControlContainer, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValuesService } from 'src/app/common/values/values.service';
import { calc_margin, round_currency } from '@app/shared/util';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-variantgeneral',
    templateUrl: './variantgeneral.component.html',
    styleUrls: ['./variantgeneral.component.scss', '../../../styles-custom.scss'],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
})
export class VariantGeneralComponent {
	@Output() valueChange = new EventEmitter<boolean>();

	public salesUnit: any;
	public gformGroup: any;

	constructor(private valuesService: ValuesService, public controlContainer: ControlContainer) { }
	ngOnInit(): void {
		this.gformGroup = this.controlContainer.control;
		this.setMargin();
	};
	
	onChangeName():void {
		console.log("onChangeName");
		this.valueChange.emit( true );
	}
	
	onChangePrice():void {
		let taxrate=this.gformGroup.get('lnk_template')?.get("vat_rate")?.value / 100;
		let price_taxx = this.gformGroup.get('price_taxi')?.value / (1 + taxrate);
		this.gformGroup.removeControl('price_taxx');
		this.gformGroup.addControl('price_taxx', new FormControl(round_currency(price_taxx)));
		this.setMargin();
	}
	
	private setMargin():void {
		let margin_pct = calc_margin(this.gformGroup.get('lnk_purchase_price_hist')?.get('price')?.value, this.gformGroup.get('price_taxx')?.value);
		if (margin_pct) margin_pct = round_currency(margin_pct * 100);
		this.gformGroup.removeControl('margin_pct');
		this.gformGroup.addControl('margin_pct', new FormControl(margin_pct));
	}
	
}
