<ng-container *ngIf="formGroup">
	<form id="mainForm" [formGroup]="formGroup">
		<table class="mat-elevation-z2" style="width: 100%;">
			<tr>
				<th class="mdc-data-table__header-cell">Séq.</th>
				<th class="mdc-data-table__header-cell">Attribut</th>
				<th class="mdc-data-table__header-cell">Nom court</th>
				<th>&nbsp;</th>
				<th>&nbsp;</th>
				<th>&nbsp;</th>
			</tr>
			<ng-container *ngFor="let t of tplAttributes; let i = index">
				<tr class="form-row" [formGroup]="t">
					<td >{{t.get("seq")?.value}}</td>
					<td >{{t.get("lnk_product_attribute")?.get("name")?.value}}</td>
					<td >{{t.get("lnk_product_attribute")?.get("shortname")?.value}}</td>
					<td><button mat-icon-button color="primary" *ngIf="i < tplAttributes.length-1" (click)="moveLine(t.get('lnk_product_attribute')?.get('id')?.value, t.get('seq')?.value, +1)"><mat-icon>arrow_drop_down</mat-icon></button></td>
					<td><button mat-icon-button color="primary" *ngIf="i>0" (click)="moveLine(t.get('lnk_product_attribute')?.get('id')?.value, t.get('seq')?.value, -1)"><mat-icon>arrow_drop_up</mat-icon></button></td>
					<td><button mat-icon-button color="primary" aria-label="Supprimer" style="padding: 0px; margin-left:6px; max-height: 20px; max-width: 20px;" (click)="deleteLine(t.get('lnk_product_attribute')?.get('id')?.value)"><mat-icon style="font-size: 16px; margin-top: -6px; max-height: 20px; max-width: 20px;">delete</mat-icon></button></td>
				</tr>
			</ng-container>			
		</table>
		
		<!-- Add attribute -->
		<div id="newattribute">
				<select [formControl]="newattribute"  placeholder="Ajouter un attribut" class="custom-select" (change)="onAddattribute()">
					<option value="" disabled selected>Ajouter un attribut</option>
					<option *ngFor="let p of attributes" [value]="p.id"> {{ p.name }} </option>
				</select>
	  </div>
	</form>
</ng-container>