import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { Event, Partner } from '@app/shared/_models/common';
import { EMPTY, empty, of } from "rxjs";

import { Product, ProductTpl } from '@app/shared/_models/product';
import * as constants from "@app/shared/constants";

import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class ProductsService {
	
	constructor(private http: HttpClient) { }
	
	getProductsTplByFilterAsFormArray(name: string, manuf: string, pid?:number, tid?: number): Observable<FormArray> {
		return this.getProductsTplByFilter(name, manuf, pid, tid).pipe(map((productTpls:ProductTpl[]) => {
			// console.log("fgs: " + JSON.stringify(productTpls));
			const fgs:FormArray = new FormArray(productTpls.map(ProductTpl.asFormGroup));
			return fgs;
		}));
	}	

	getProductsTplByFilter(name: string, manuf: string, product_id?: number, template_id?: number): Observable<any> {
		let q:String = "";
		if (product_id) {	// TODO why 
			console.log("product_id: " + product_id);
			q += "product_id=" + product_id
		} else {
			if (name) q += "name=" + name;
			if (manuf) {
				if (q !== '') q += "&";
				q += "manuf="+manuf;
			}
			
			if (template_id) q += "template_id=" + template_id;
		}
		if (q === '') return EMPTY;
		console.log("q: " + q);
		return this.http.get<void>(environment.server_url + "/productstpl?" + q );
	}

	getProductById(id: number) { // TODO REMOVE ME
		return this.http.get<void>(environment.server_url + "/product/" + id);
	}

	getProductsByFilter(typ: string, q: string, options: String) { 
		return this.http.get<void>(environment.server_url + "/products?typ=" + typ + "&q=" + q + options);
	}
	
	search_i(q: string) {
		q = "?q=" + q;
		return this.http.get<void>(environment.server_url + "/search/i" + q);
	}
	
	search_manuf(q: string) {
		q = "?q=" + q;
		return this.http.get<void>(environment.server_url + "/search/manuf" + q);
	}
	
	search_bc(q: string): Observable<any> {
		if (parseInt(q) !== +q) return of(null);  // Not a number => do not call barcode API
		q = "?q=" + q;
		return this.http.get<void>(environment.server_url + "/search/bc" + q);
	}		
	
	search_productpackaging(index:string, key:number): Observable<any> {	// Search barcode REDIS index jbc:
		let params = "?index=" + index + "&key=" + key;
		return this.http.get<void>(environment.server_url + "/search/productpackaging" + params);
	}	
	
	postProducts(variants: any): Observable<any> {
		return this.http.post<any>(environment.server_url + "/products", variants, { 'headers': constants.jsonheader });
	}
}
