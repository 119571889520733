import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { Observable, throwError, merge, EMPTY } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { Product, ProductTpl, TplPackaging, Packaging, ProductPackagingVal } from '@app/shared/_models/product';
import { Event, Partner } from '@app/shared/_models/common';
import { Attribute } from '@app/shared/_models/product';
import { SpinnerService } from '@app/shared/spinner.service';

import { environment } from '@app/../environments/environment';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient) { }
  
  getPartnerAsFormGroup(id:number) : Observable<FormGroup> {
		return this.getPartnerByid(id).pipe(map((partner: Partner[]) => {
			const fg = Partner.asFormGroup(partner[0]);
			return fg;
		}));	  
	  }
	  
  getPartnerByid(id:number) : Observable<any> {
	  return this.http.get<void>(environment.server_url + "/common/partner/" + id );
  }    
  
  postPartnerEvent(eventtyp: number, formGroup: FormGroup): Observable<any> {
	  let event:Event = {
		  id: 0,
		  typ: eventtyp,			// 1: Insert, 2: Update
		  objectid: 0,
		  objecttyp: 1,		// Partner
		  data: formGroup.value,
	  }
	  return this.http.post<any>(environment.server_url + "/event", event, { 'headers': headers });
  }
}
