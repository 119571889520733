import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Product, ProductTpl, TplPackaging, Packaging, ProductPackagingVal, ProductPackaging } from '@app/shared/_models/product';
import { ProductService } from '@app/sales/product/product.service';
import { CommonService } from '@app/common/common.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-productpackaging',
    templateUrl: './productpackaging.component.html',
    styleUrls: ['./productpackaging.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, MatButtonModule, MatTooltipModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class ProductpackagingComponent {
	constructor(private route: ActivatedRoute, private location: Location, private commonService: CommonService, private productService: ProductService, private router: Router, private fb: FormBuilder) { }
	
	public template!: number;
	public formGroup: FormGroup = this.fb.group({
		tplPackagings: this.fb.array([])
	});
	public newpackaging: FormControl=new FormControl(1);	// Defaults to 'unité'
	public templatepackagings!: any;
	public packagings: ProductPackaging[] = [];
	public tab: number=0;
	
	ngOnInit(): void {
		this.template = Number(this.route.snapshot.paramMap.get('id'));
		this.productService.getPackagings ()			// Get all packagings, to fill the listbox
			.subscribe((data: ProductPackaging[]) => {
				this.packagings = data;
			});
			
		this.productService.getPackagingsByTemplateAsFormArray (this.template)	// Get packagings for template (table product_tpl_packaging)
			.subscribe((data: FormArray) => {
				this.formGroup.removeControl("tplPackagings");
				this.formGroup.addControl("tplPackagings", data);
			});
			
  	this.route.queryParams.subscribe(params => {	// 	Is there a tab number in URL? 
		if (params && params['tab'] != null) {
			this.tab=Number(params['tab']);
		} 
	});			
	}
	
	post_packagingval(): void {
		console.log("Adding tplpackaging: " + this.newpackaging.value);
		if (!isNaN(this.newpackaging.value)) {
			let tplPackaging: TplPackaging = {
				template: this.template,
				packaging: this.newpackaging.value,
				seq: 1,
			}
			this.productService.postTplpackaging(tplPackaging)
				.subscribe((response: any) => {
					console.log("Server response: " + JSON.stringify(response));
					this.router.navigateByUrl('sales/product/' + this.template + '?tab=1');
				});
		} else {	// Should NOT happen, packaging is comming from a listbox
			
		}
	}	
	
	deleteLine(packaging:number) {
		console.log("deleteLine: " + packaging);
		this.productService.deletetTplpackaging(this.template, packaging)
			.subscribe((response: any) => {
				console.log("Server response: " + JSON.stringify(response));
				this.router.navigateByUrl('sales/product/' + this.template + '?tab=1');
			});		
	}

	get templatePackagings(): FormArray {
		return this.formGroup.get('templatePackagings') as FormArray;
	}
	
	get tplPackagings(): FormGroup[] {
		return (this.formGroup.controls["tplPackagings"] as FormArray).controls as FormGroup[];
	}
}