import { Component } from '@angular/core';

@Component({
	standalone: true,
  selector: 'app-menu-home',
  templateUrl: './menu-home.component.html',
  styleUrls: ['./menu-home.component.scss']
})
export class MenuHomeComponent {

}
