<app-menu-common url="sales"></app-menu-common>
<div *ngIf="formGroup">
	<div class="pageheader flexcolumn" [formGroup]="formGroup">
		<section class="flexrow">
			<div class="grey pagetitle" *ngIf="!id">Nouvelle offre promotionnelle</div>
			<div class="grey pagetitle" *ngIf="id">Offre</div>
			<span class="spacer"></span>
			<div *ngIf="id" class="grey pagetitle">#&nbsp;{{id}}</div>
		</section>
		<div class="flexgrid"></div>
	</div>
	<app-action-bar form="mainform" (submitEmitter)="onActionBarSubmit($event)" [hassave]="true"> </app-action-bar>
	<div class="pagecontent">
		<form  (ngSubmit)="onSubmit()" id="mainform">
			<div class="maincontent">
			<mat-tab-group>
				<mat-tab label="Déclencheur">
					<section class="cb-section">
						<mat-radio-group [(ngModel)]="trigkind" name="trigkind" (change)="onChangeTrigKind()">
							<mat-radio-button class="cb-margin" value="AMT">Remise sur montant</mat-radio-button>
							<mat-radio-button class="cb-margin" value="QTY">Remise sur quantité</mat-radio-button>
						</mat-radio-group>
					</section>
					<section class="cb-section" *ngIf="trigkind=='AMT'">
						<mat-radio-group [(ngModel)]="trigset" name="trigset">
							<mat-radio-button class="cb-margin" value="GLOBAL">Montant global de la commande</mat-radio-button>
							<mat-radio-button class="cb-margin" value="ITEM">Montant d'un ensemble de produits</mat-radio-button>
						</mat-radio-group>
					</section>			
					
					<mat-divider style="margin-bottom: 10px;"></mat-divider>
					
					<section class="flexrow" *ngIf="trigset==='GLOBAL'">
						<div class="paddingtop glow">Montant déclencheur&nbsp;</div>
					  <mat-form-field class="">
					    <mat-label>Montant déclencheur</mat-label>
					    <input matInput placeholder="Ex. 100" value="" [(ngModel)]="trigqty" name="trigqty">
					  </mat-form-field>				
					</section>
					
					<app-itemset *ngIf="trigset==='ITEM' "></app-itemset>			
					
				</mat-tab>
				
				<mat-tab label="Conséquences">
					<section class="cb-section">
						<mat-radio-group [(ngModel)]="conskind" name="conskind">
							<mat-radio-button class="cb-margin" value="FORCED_PRICE">Prix fixe</mat-radio-button>
							<mat-radio-button class="cb-margin" value="AMT_DISCOUNT">Remise en montant</mat-radio-button>
							<mat-radio-button class="cb-margin" value="PCT_DISCOUNT">Remise en %</mat-radio-button>
						</mat-radio-group>
					</section>
					<section class="cb-section" *ngIf="trigkind=='AMT'">
						<mat-radio-group [(ngModel)]="trigset" name="trigset">
							<mat-radio-button class="cb-margin" value="">Articles déclencheurs</mat-radio-button>
							<mat-radio-button class="cb-margin" value="">Ensemble défini</mat-radio-button>
							<mat-radio-button class="cb-margin" value="">Montant du ticket</mat-radio-button>
						</mat-radio-group>
					</section>
					<app-outcome></app-outcome>
				</mat-tab>
			</mat-tab-group>
			
			</div>
		</form>
	</div>
</div>

<div *ngIf="id===-1" style="padding: 20px; font-size: x-large;">ERREUR : Offre inexistante</div>