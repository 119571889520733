import { Component, Input, OnInit } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormatCurrencyPipe } from '@app/shared/pipes/format-currency.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProductService } from '@app/sales/product/product.service';
import { ProductsService } from '@app/sales/products/products.service';
import { QuotationService } from '../quotation.service';


@Component({
	selector: 'app-salehistory',
	standalone: true,
	imports: [MatTabGroup, MatTab, DatePipe, NgFor, NgIf, RouterLink, FormatCurrencyPipe, MatButtonModule, MatIconModule, NgClass],
	templateUrl: './salehistory.component.html',
	styleUrl: './salehistory.component.scss'
})
export class SalehistoryComponent implements OnInit {

	@Input() customer: any;
	@Input() id: number = 0;

	orders: Array<any> = [];

	constructor(private quotationservice: QuotationService) { }

	ngOnInit(): void {
		if (this.customer && this.customer.id) {
			let $obs = this.quotationservice.getCustomerHistory(this.customer.id, this.id).subscribe((orders: any) => {
				// console.log('orders',orders);
				this.orders = orders;
				$obs.unsubscribe();
			});
		}
	}
	
	addproduct(product: any, qty: number = 1): void {
		let ppv = {
			product: product.product,
			barcode: product.product_barcode,
			lnk_product: {
				template: product.template,
				name: product.product_name,
				price_taxi: product.uprice_taxi,
				price_taxx: product.uprice_taxx,
				lnk_template: { vat: +product.vat, vat_rate: product.vat_rate }
			},
			lnk_packaging: { qty: product.packaging_qty }
		}
		// product.lnk_product.template = product.template
		this.quotationservice.sendAddproductpackagingfromHistory(ppv, qty);

	}

	addAllproductOfOrder(lines: Array<any>): void {
		lines.forEach(line => {
			this.addproduct(line, line.qty)
		})

	}


}
