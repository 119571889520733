import { Component, OnInit } from '@angular/core';

import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule } from '@angular/forms';
import { NgFor, NgIf, DatePipe, KeyValuePipe } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { FseService } from '../fse.service';
import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';

import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
	selector: 'app-fsestobill',
	standalone: true,
	imports: [MenuCommonComponent, MatButtonModule, MatTableModule, NgIf],
	templateUrl: './fsestobill.component.html',
	styleUrl: './fsestobill.component.scss'
})
export class FsestobillComponent {
	public displayedColumns: string[] = ['id_order', 'assure', 'benef'];
	private fses:any;
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();

	constructor(private FseService: FseService, private spinner: SpinnerService, private alert: AlertService) { }

	ngOnInit(): void {
		this.FseService.getFsestobill$().subscribe({
			next: (fses: any) => {
				this.fses=fses;
				this.dataSource = new MatTableDataSource(this.fses);
			},
			error: (e) => {
				console.log("ERREUR Serveur : ", e.error);
				this.spinner.hide();
				this.alert.error("ERREUR Serveur : " + e.error);
			}
		});
	}

	onInvoiceClick(row:any, event:Event) {
		console.log("onInvoiceClick");
	}
	
	// Invoice all FSE orders
	onInvoiceAllClick() {

	}
}
