<button
	mat-flat-button
	aria-label="Variantes"
	color="primary"
	style="margin:8px;"
	routerLink="/sales/variant/{{template_id}}/{{id}}"
	type="button"
>
	<mat-icon>visibility</mat-icon>
	Produit
</button>
