import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterLink } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SpinnerService } from '@app/shared/spinner.service';
import { AlertService } from '@app/shared/alert.service';
import { PrintService } from '@app/shared/print.service';

import { PartnersComponent } from '@app/common/partners/partners.component';

import { QuotationService } from '@app/sales/quotation/quotation.service';
import { PartnersService } from '@app/common/partners/partners.service';
import { Subject, BehaviorSubject } from 'rxjs';

import { environment } from '@app/../environments/environment';
import * as constants from "@app/shared/constants";
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { QuotationActionComponent } from '../../shared/action-bar/quotation-action/quotation-action.component';
import { ActionBarComponent } from '../../shared/action-bar/action-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, DatePipe, KeyValuePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MenuCommonComponent } from '../../shared/menu-common/menu-common.component';

@Component({
  selector: 'app-offers',
  standalone: true,
  imports: [MenuCommonComponent, FormsModule, MatInputModule, MatTableModule, MatIconModule, MatButtonModule, MatFormFieldModule, RouterLink, KeyValuePipe],
  templateUrl: './offers.component.html',
  styleUrl: './offers.component.scss'
})
export class OffersComponent {
	public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource();
	public displayedColumns: string[] = ['id', 'date', 'status', 'customer', 'amount_taxi'];	
	public searchname: string = "";		// Name Search field
	public searchstate: string = "-1";	// State Search field

	constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private spinner: SpinnerService, private alert: AlertService) { }
	
	ngOnInit(): void {
		console.log(constants.sales_order_state.get(1))
	}
	
	onKeydown(event: any) {
		if (event.key === "Enter") {
			console.log(event);
			this.onSearch();
		}
	}

	onSearch(): void {
		}
		
	onClickRow(row: any, e: Event): void {
		this.router.navigate(['/sales/quotation/' + row.id]);	// Jump to partner only in standalone mode (not in dialog)
	}		

	onCancel(): void {
	}		
}
