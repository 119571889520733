<app-menu-common url="purchase"></app-menu-common>

<div class="pageheader">
	<span class="mat-headline-1 grey">Tarifs Achat</span>
	<span class="spacer"></span>
	<mat-form-field style="height:50px;">
	  <input id="search" matInput [(ngModel)]="search" placeholder="Rechercher" (keydown)="onKeydown($event)" autofocus>
	</mat-form-field>
    <button mat-icon-button color="primary" aria-label="Rechercher" (click)="onSearch()">
	    <mat-icon>search</mat-icon>
    </button>
</div>

<div class="pagecontent" *ngIf="dataSource.length">
	<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
			  <!-- id Column -->
	  <ng-container matColumnDef="id">
	    <th mat-header-cell *matHeaderCellDef> # </th>
	    <td mat-cell *matCellDef="let p"> {{p.id}} </td>
	  </ng-container>
	  
	  <!-- Name column -->
	  <ng-container matColumnDef="name">
	    <th mat-header-cell *matHeaderCellDef> Nom </th>
	    <td mat-cell *matCellDef="let p"> {{p.name}} </td>
	  </ng-container>
	  
	  <!-- Partner Name column -->
	  <ng-container matColumnDef="pname">
	    <th mat-header-cell *matHeaderCellDef> Founisseur </th>
	    <td mat-cell *matCellDef="let p"> {{p.partner.name}} </td>
	  </ng-container>
	  	  
	  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClickRow(row)" ></tr> <!-- [style.height.px]="20" -->	  
	</table>
</div>		