import { FormControl, FormArray, FormGroup } from '@angular/forms';

export class PurchasePriceHist {
	id?: number;
	price?: number;

	static asFormGroup(purchasePriceHist?: PurchasePriceHist): FormGroup {
		const fg = new FormGroup({
			id: new FormControl(purchasePriceHist?.id),
			price: new FormControl(purchasePriceHist?.price),
		});
		// fg.disable();
		return fg;
	}
}