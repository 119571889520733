import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { EMPTY, empty, of } from "rxjs";
import { environment } from '../../../environments/environment';
import { Product, ProductTpl } from '@app/shared/_models/product';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  constructor(private http: HttpClient) { }
  
  getPartnersByFilter(name?:string, address?:string, activity?:string, store?:string, nir?:number) : Observable<any> {
	  let q:String = "";
	  if (name) q += "name=" + name;
	  if (address) q = q + (q===""?"":"&") + "address=" + address;
	  if (activity) q = q + (q===""?"":"&") + "typ=" + activity;
	  if (store) q = q + (q===""?"":"&") + "store=" + store;
	  if (nir) q = q + (q===""?"":"&") + "nir=" + nir;
	  if (q === '') return EMPTY;
	  return this.http.get<void>(environment.server_url + "/common/partners?" + q );
  }
}
