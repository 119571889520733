import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from '@app/shared/_models/alert';
import { AlertService } from '@app/shared/alert.service';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';

@Component({
		standalone: true,
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})
export class AlertComponent implements OnInit, OnDestroy {
    @Input() id = 'default-alert';

    alertSubscription!: Subscription;
    routeSubscription!: Subscription;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    autoHide: number = 5000;

    constructor(private router: Router, private alertService: AlertService, private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.alertSubscription = this.alertService.onAlert(this.id)
            .subscribe(alert => {
                if (alert && alert.message) {
                    this.open(alert);
                }
            });

        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.alertService.clear(this.id);
            }
        });
    }

    open(alert: Alert) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.panelClass = this.cssClass(alert);
        config.duration = this.autoHide;
        if (alert.message) {
            this.snackBar.open(alert.message, "X", config);
        }
    }

    ngOnDestroy() {
        this.alertSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    cssClass(alert: Alert): string[] {
        const classes = ['alert', 'alert-dismissible', 'mt-4', 'container'];

        const alertTypeClass = {
            [AlertType.Success]: 'alert-success',
            [AlertType.Error]: 'alert-danger',
            [AlertType.Info]: 'alert-info',
            [AlertType.Warning]: 'alert-warning'
        };

        if (alert.type !== undefined) {
            classes.push(alertTypeClass[alert.type]);
        }
        return classes;
    }
}
