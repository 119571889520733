
<form id="items" [formGroup]="formGroup">
	<table style="width: 100%; table-layout:auto;" *ngIf="lines?.length">
		<tr>
			<th class="col0">#</th>
			<th class="col1">Id</th>
			<th class="col1">Code barre</th>
			<th class="col2">Produit - Unité de Vente</th>
			<th class="col3">Qté</th>
			<th class="col3">Colisage</th>			
			<th class="col4">Px U. €</th>
			<th class="col5">Mnt TTC €</th>
			<th class="col6">TVA</th>
			<th class="col7">&nbsp;</th>
		</tr>
		
		<ng-container formArrayName="lines">
			<tr *ngFor="let line of lines.controls; let i=index" (keydown)="onLineKeydown($event, i)">
				<td class="col0">
					{{ line.value.seq }}
				</td>				
				<td class="col1">
					{{ line.value.product }}
				</td>
				<td class="col1">
					{{ line.value.product_barcode }}
				</td>				
				<td class="col2"> <!-- name + unit -->
					<a href="#" [routerLink]="['/sales/variant', line.value.template, line.value.product]" target="_blank">{{ line.value.product_name }}</a> <span *ngIf="line.value.packaging_name">-</span> {{ line.value.packaging_name }}
				</td>
				<td class="col3">	<!-- Qty -->
					<ng-container [formGroupName]="i">
						<input formControlName="qty"  (click)="onClickSelectAll($event)"  (blur)="onPriceQtyBlur(i)" [class.userinput] = "line.value.typ == 2" [readonly]="formGroup.value.state > 1">	
					</ng-container>
				</td>	
				<td class="col3" >	<!-- Colisage -->
					{{ line.value.packaging_qty }}
				</td>					
				<td class="col4"  *ngIf="true">	<!-- Unit price -->
					<ng-container [formGroupName]="i">
						<input formControlName="uprice_taxi" (click)="onClickSelectAll($event)" (blur)="onPriceQtyBlur(i)" [class.userinput] = "line.value.typ == 2">
					</ng-container>
				</td>
				<td class="col5">	<!-- Amount -->
					{{ line.value.uprice_taxi * line.value.qty | fc }}
				</td>		
				<td class="col6">	<!-- VAT Code -->
					{{ line.value.vat }}
				</td>				
				<td class="col7">	<!-- delete -->
					<button *ngIf="formGroup.value.state === 1" mat-icon-button color="primary" matTooltip="Supprimer" aria-label="Supprimer" style="padding: 0px; margin-left:6px; max-height: 20px; max-width: 20px;" (click)="deleteLine(i)"><mat-icon style="font-size: 16px; margin-top: -6px; max-height: 20px; max-width: 20px;">delete</mat-icon></button>
				</td>
			</tr>
		</ng-container>
	</table>
</form>

@if(!lines.length) {
<div class="nolines">Document vide</div>
}@else{

<div class="footer bglavander">	<!-- Footer Sub total zone -->
		<div class="nbitemslabel">
			<span class="weight-500">Nb. d'articles :</span> {{ nbitems }}
		</div>
		<div class="subtotallabel">
			<span class="weight-500">Total&nbsp;TTC</span> 
		</div>		
		<div class="subtotal weight-500">
			{{ formGroup.value.amount_taxi | fc }}&nbsp;€
		</div>
		<div class="subtotaltaxxlabel">
			<span>Total&nbsp;HT</span> 
		</div>		
		<div class="subtotaltaxx">
			{{ formGroup.value.amount_taxx | fc}}&nbsp;€
		</div>		
		<div class="totaltaxlabel">
			<span>Total&nbsp;TVA</span> 
		</div>		
		<div class="totaltax">
			{{ formGroup.value.amount_taxi - formGroup.value.amount_taxx | fc}}&nbsp;€
		</div>		
		<ng-container *ngIf="formGroup.value.state == 2">
			<div class="dulabel">
				<span class="weight-500">Reste dû :</span>
			</div>
			<div class="duamount">
				{{ remainer | fc}}&nbsp;€
			</div>		
			<div class="changelabel">
				<span class="weight-500">A rendre :</span>
			</div>
			<div class="changeamount">
				{{ change | fc}}&nbsp;€
			</div>			
		</ng-container>
</div>
}

@if(formGroup.value.state === 1){
<div class="inputzone bglavander">  <!-- Item Line input -->
	<div class="col1">
		<select [formControl]="linetype">
			<option value="1">Article</option>
			<option value="2">Article - Rabais</option>
			<option value="3">Retour Article</option>
		</select>
		<input autofocus [formControl]="searchinput" (keydown)="onItemKeydown($event)" (blur)="onBlurSearchInput()" #search >
		<button type="button" mat-icon-button color="primary" matTooltip="Chercher" aria-label="Chercher" (click)="openProductDialog()"><mat-icon>search</mat-icon></button>
		
		<button type="button" mat-button aria-label="Effacer" (click)="onCancel()"><mat-icon>close</mat-icon></button> 
		<button type="button" mat-flat-button color="primary" matTooltip="Ajouter" aria-label="Ajouter" (click)="search_productpackaging( 'jbc')">Ajouter</button>
	</div>
	<div class="col2">
		<button type="button" mat-flat-button color="primary" matTooltip="Total" aria-label="Total" (click)="onTotal($event)">Total</button>	
	</div>	
</div>
}

<div id="paymentzone">	<!-- Payment zone -->
	@if((formGroup.value.state === 3) || (formGroup.value.state === 4) || (formGroup.value.state === 5)){
	<div id="payment" class=" bglavander">		<!-- Payment Lines Input -->
		<div id="paymenttitle">Règlement</div>
		<div id="paymentadd">
			<select [formControl]="paymenttype">
				<option *ngFor="let pm of map_paymentmethods | keyvalue" [hidden]="pm.key==-1?true:null"  value={{pm.key}}>{{pm.value}}</option>
			</select>		
			<input autofocus [formControl]="paymentinput" (keydown)="onPaymentKeydown($event)">
			<button type="button" mat-mini-fab color="accent" matTooltip="Solder le Bon" aria-label="Solder le Bon" (click)="onSellout()"><mat-icon>done</mat-icon></button>
			<button type="button" mat-flat-button color="primary" matTooltip="Ajouter un règlement" aria-label="Ajouter" (click)="onPayment()">Ajouter</button>
		</div>
	</div>
	}
	
		@if(formGroup.value.state === 6){
	 <form id="payform" [formGroup]="formGroup" >	<!-- Payment table -->
		<table *ngIf="paylines?.length">
			<tr>
				<th class="col1">Mode de règlement</th>
				<th class="col2">Montant</th>
				<th class="col3">&nbsp;</th>
			</tr>
			<ng-container formArrayName="payment">
				<tr *ngFor="let line of paylines.controls; let i=index">
					<td class="col1">
						{{ map_paymentmethods.get(line.value.method) }}
					</td>
					<td class="col2">
						{{ line.value.amount | fc }}
					</td>
					<td class="col3">	<!-- delete -->
						&nbsp;
					</td>						
				</tr>
			<tfoot *ngIf="formGroup.value.state === 2">	<!-- Only visible during payment -->
				<tr>
					<td class="col1 weight-500">
						Total&nbsp;:
					</td>
					<td class="col2">
						{{ payed | fc }}
					</td>
					<td class="col3">	<!-- delete -->
						<button mat-icon-button color="primary" matTooltip="Supprimer" aria-label="Supprimer" style="padding: 0px; margin-left:6px; max-height: 20px; max-width: 20px;" (click)="deletePayments()" *ngIf="formGroup.value.state == 2"><mat-icon style="font-size: 16px; margin-top: -6px; max-height: 20px; max-width: 20px;">delete</mat-icon></button>
					</td>						
				</tr>
			</tfoot>
			</ng-container>						
		</table>
	 </form>
	 }
	 
	 @if(formGroup.value.state >=2){
	<div id="vat">
		<table>
			<tr>
				<th class="col1">Code</th>
				<th class="col2">Taux</th>
				<th class="col2">Base HT</th>
				<th class="col2">Montant TVA</th>
			</tr>
			<tr *ngFor="let t of formGroup.value.tax ">
				<td class="col1">{{ t.tax }}</td>
				<td class="col2">{{ t.rate }}&nbsp;%</td>
				<td class="col2">{{ t.base_amount | fc }}</td>
				<td class="col2">{{ t.tax_amount  | fc }}</td>
			</tr>
		</table>
	</div>	 
	}
</div>

