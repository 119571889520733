import { Component, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { MatOptionModule, DateAdapter, MAT_DATE_FORMATS, provideNativeDateAdapter } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxChange,MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor, JsonPipe } from '@angular/common';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule, FormBuilder, ControlContainer } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, BehaviorSubject, Observable, pipe, Subscription } from 'rxjs';
import * as days from "dayjs";

import * as constants from "@app/shared/constants";
import { QuotationService } from '@app/sales/quotation/quotation.service';
import { SelectIntegerComponent } from '@app/shared/select-integer/select-integer.component';
import { ValuesService } from '@app/common/values/values.service';

@Component({
	selector: 'app-quotationdelivery',
	templateUrl: './quotationdelivery.component.html',
	styleUrls: ['./quotationdelivery.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, JsonPipe, MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, NgIf, MatSelectModule, NgFor, MatOptionModule,MatButtonToggleModule,MatCheckboxModule, SelectIntegerComponent],
	providers: [provideNativeDateAdapter()],
})
export class QuotationdeliveryComponent {

	@Input() loadOrderEventsSubject$!: Observable<number>;								// Parent order was loaded
	private loadOrderEventsSubscription!: Subscription;
	@Input() submitEventSubject$!: Observable<number>;										// Parent was submitted
	
	private ChangeformAskslotoperatorSubscription !: Subscription | null;

	// public slots: any[] = [];	// Slot listbox content
	public slotsDay: any[] = []; // Slot listbox content
	public slotsMorning: any[] = []; // Slot listbox content
	public slotsAfternoon: any[] = []; // Slot listbox content
	public operatorsL1: any[] = []; // Slot listbox content
	public operatorsL2: any[] = []; // Slot listbox content
	public deliverymodes: any[] = []; 		// Delivery modes (available from c_values 10)
	
	public formGroup: FormGroup = new FormGroup({});
	private submitEventsSubscription!: Subscription;
	public mindate:Date = new Date (Date.now());							// TODO change it for existing deliveries, defaults to today
	
	public constants = constants;															// To expose constants to template

	public precisionControl1: FormControl = new FormControl();
	public precisionControl2: FormControl = new FormControl();
	public niveauPrecisionhoraire: number = 0;
	public askslotDayValue: string = '';
	public errorMessage : string='';
	public isValidable: boolean = false;
	public eventtyp: number = constants.EVENTTYP_INSERT;
	// public id_order: number = 0;															// Parent order id. Useless

	constructor(private valuesService: ValuesService, private quotationService: QuotationService, private fb: FormBuilder, public controlContainer: ControlContainer, private router: Router, private dateAdapter: DateAdapter<Date> ) { }

	ngOnInit(): void {
		this.dateAdapter.setLocale('fr-FR'); //dd/MM/yyyy											// Set locale to French for date picker
		this.formGroup = this.controlContainer.control as FormGroup;					// formgroup is inherited from parent
		
		this.submitEventsSubscription = this.submitEventSubject$.subscribe((tabindex) => { 	// Triggered on submit. Parameter is tab index, starting w/ 0
			console.log("Received submit from parent. tabindex:" + tabindex);
			if (tabindex === 1) this.onSubmit();	// 1 is Delivery tab
		});
		
		this.valuesService.getValues$(constants.C_VALUES_DELIVERY_OPERATOR).subscribe((data: any) => {  // get slot operators
			data.forEach((operator: any) => {
				if (operator.data.level === 1) {					// 1st precision level: Start, End, Middle
					this.operatorsL1.push(operator);
				}
				if (operator.data.level === 2) {					// 2nd precision level: After, Before, Fixed hour
					this.operatorsL2.push(operator);
				}
			});
		
			this.loadOrderEventsSubscription = this.loadOrderEventsSubject$.subscribe((id_order) => { 	// Triggered on Order loading. FIXME subscribe in a subscribe is a terrible implementation 
				this.formGroup.get("lnk_delivery")?.get("id_order")?.patchValue(id_order);								
				if (this.formGroup.get("lnk_delivery")?.get("askdatdel")?.value) {												// Set local askdatdel binding to formgroup value 
					console.log("Got askdatdel value: " + this.formGroup.get("lnk_delivery")?.get("askdatdel")?.value);
				}
				else {
						console.log("No askdatdel. setting to today");	// FIXME delivery could be already delivered => Do not set to today
						this.formGroup.get("lnk_delivery")?.get('askdatdel')?.patchValue(new Date());			
				}
				let slot = this.formGroup.get("lnk_delivery")?.get("askslot")?.value;
				if (!slot) {			// askslot defaults to 0: irrelevant
					this.formGroup.get("lnk_delivery")?.get("askslot")?.setValue(0);
				} else {
					if (this.slotsMorning.find((e) => e.id == slot) != undefined) {	// Morning slot but we can't activate AM button (not a plain morning slot but a slot IN the morning..)
						this.askslotDayValue = "1";
					} else {
						this.askslotDayValue = "2";
					}
				}
				// Cast to string a bunch of formGroup values
				this.formGroup.get("lnk_delivery")?.get("askslot")?.patchValue((this.formGroup.get("lnk_delivery")?.get("askslot")?.value).toString());
				this.formGroup.get("lnk_delivery")?.get("mode")?.patchValue((this.formGroup.get("lnk_delivery")?.get("mode")?.value ??1 ).toString());	
				this.formGroup.get("lnk_delivery")?.get("askop")?.patchValue((this.formGroup.get("lnk_delivery")?.get("askop")?.value).toString());
				console.log("AFTER LOADING formGroup.lnk_delivery: " + JSON.stringify(this.formGroup.get("lnk_delivery")?.value));
				
				let op = this.formGroup.get("lnk_delivery")?.get("askop")?.value ??0;		// Set niveauPrecisionhoraire given askop	(terrible conception too)
				if (this.operatorsL1.find((e) => e.id == op) != undefined) { 
					this.niveauPrecisionhoraire = 1; 
					this.precisionControl1.patchValue(true);
					this.precisionControl2.patchValue(false);
				}
				else if (this.operatorsL2.find((e) => e.id == op) != undefined) { 
					this.niveauPrecisionhoraire = 2;
					this.precisionControl1.patchValue(true);
					this.precisionControl2.patchValue(true); 
					}
				// console.log("op - level: " + op + " - " + this.niveauPrecisionhoraire);
				
				if (this.formGroup.get("lnk_delivery")?.get("id")?.value !== 0) {	// Found a delivery for this order => UPDATE mode (on delivery)
					this.eventtyp = constants.EVENTTYP_UPDATE;
				}
							
				this.is_valid(); 
			});		
		});

		this.valuesService.getValues$(constants.C_VALUES_DELIVERY_SLOT) // Get active delivery slots & add default value
			.subscribe((data: any) => {
				data.forEach((slot: any) => {
					if (!slot.is_active) { return }				// Get only active slots
					if (slot.data.parent === 0) {					// AM / PM
						this.slotsDay.push(slot);
					} else if (slot.data.parent === 1) {	// Morning hours
						this.slotsMorning.push(slot);
					} else if (slot.data.parent === 2) {	// Afternoon hours
						this.slotsAfternoon.push(slot);
					}
				});
			});

		this.valuesService.getValues$(constants.C_VALUES_DELIVERY_MODE).subscribe((data: any) => {  // get delivery modes
			// data.pop();										// WTF? We suppose last value is to be dropped?
			this.deliverymodes = data;
		});

	  this.ChangeformAskslotoperatorSubscription =  this.formGroup .get('lnk_delivery') ?.get('askop') ?.valueChanges.subscribe((v) => {
        this.is_valid()
      })?? null
	}

	onSubmit(): void {
		if (this.formGroup.get("lnk_delivery")?.get('askslot')?.value == "0") {
			this.formGroup.get("lnk_delivery")?.get('askslot')?.patchValue(null);
		} else {
			this.formGroup.get("lnk_delivery")?.get('askslot')?.patchValue(parseInt(this.formGroup.get("lnk_delivery")?.get('askslot')?.value));			// Select options need casting to int			
		};
		if (this.formGroup.get('lnk_delivery')?.get('askop')?.value == '0') {
			this.formGroup.get('lnk_delivery')?.get('askop')?.patchValue(null);
		} else {
			this.formGroup.get('lnk_delivery')?.get('askop')?.patchValue(parseInt(this.formGroup.get('lnk_delivery')?.get('askop')?.value)); // Select options need casting to int
		};
		this.formGroup.get("lnk_delivery")?.get("mode")?.patchValue((+this.formGroup.get("lnk_delivery")?.get("mode")?.value ??1 ));	// Cast lnk_delivery.mode back to int
		this.formGroup.get("lnk_delivery")?.get("askslot")?.patchValue((+this.formGroup.get("lnk_delivery")?.get("askslot")?.value ??0 ));	// Cast lnk_delivery.askslot back to int
		let isodate = days(new Date(this.formGroup.get("lnk_delivery")?.get('askdatdel')?.value)).format(constants.iso8601format);
		console.log("formgroup.askdatdel.value: " + isodate);
		//this.formGroup.get("lnk_delivery")?.get('askdatdel')?.patchValue(days(this.askdatdel.value).format(constants.iso8601format));															// Put local control back to formgroup w/ right date format 		
		this.formGroup.get("lnk_delivery")?.get('askdatdel')?.patchValue(isodate);															// Put local control back to formgroup w/ right date format		
		console.log("formGroup.lnk_delivery: " + JSON.stringify(this.formGroup.get("lnk_delivery")?.value));
		this.quotationService.postDeliveryEvent(this.eventtyp, this.formGroup?.get("lnk_delivery") as FormGroup).subscribe((response: any) => {
			console.log("Server response: " + JSON.stringify(response));
			this.eventtyp = constants.EVENTTYP_UPDATE;
			this.router.navigate(['/sales/quotation/' + response.data.id_order]);	// Reload Order. Order id is in server response
		});
	}

	onDateChange() {	// FIXME remove it
		this.formGroup.get('lnk_delivery')?.get('mode')?.setValue('4');
		this.is_valid()
		// console.log("askdatdel: " + JSON.stringify(this.askdatdel.value));
		// // let d:Date = new Date(this.askdatdel.value);
		// console.log("date: " + Intl.DateTimeFormat('fr-FR').format(this.askdatdel.value));		
	}

	get validaskdatdel():boolean {
		//return JSON.stringify(this.askdatdel.value) != '{}';	// Silly way to check formgroup value
		// CNO return this.askdatdel.value != null;
		return true;
	}

	onChangePrecision(event: MatCheckboxChange, i: number) {	// Check / uncheck precision 1 / precision 2
		this.niveauPrecisionhoraire = event.checked ? i : i - 1;
		console.log ("this.niveauPrecisionhoraire: " + this.niveauPrecisionhoraire);
		console.log ("this.precisionControl1.value: " + this.precisionControl1.value);
		if (!event.checked) {	// Uncheck box
			if (i === 1) {			// Uncheck precision 1 => back to Take away => force date to today + disable - disable 
				this.formGroup.get('lnk_delivery')?.get('askop')?.setValue(null);
				this.formGroup.get('lnk_delivery')?.get('askslot')?.setValue(this.askslotDayValue);
				this.precisionControl2.setValue(false);	// Uncheck precision 2
			} else {
				if ((this.formGroup.get('lnk_delivery')?.get('askop')?.value ?? 0) > 3) {
					this.formGroup.get('lnk_delivery')?.get('askslotoperator')?.setValue(null);
				}
				if ((this.formGroup.get('lnk_delivery')?.get('askslot')?.value ?? 0) > 2) {
					this.formGroup.get('lnk_delivery')?.get('askslot')?.setValue(this.askslotDayValue);
				}
			}
		}
		this.is_valid()
	}

	onChangeDeliveryMode(event: MatButtonToggleChange) {
		this.formGroup.get('lnk_delivery')?.get('mode')?.setValue(event.value);
/*		if ((this.formGroup.get("lnk_delivery")?.get("mode")?.value == 1) || (this.formGroup.get("lnk_delivery")?.get("mode")?.value == 3)) {	// Take away OR ASAP => date = today
			this.formGroup.get("lnk_delivery")?.get('askdatdel')?.patchValue(new Date());	// Set askdatdel to today
		}*/
		this.formGroup.get("lnk_delivery")?.get('askdatdel')?.patchValue(new Date());	// Set askdatdel to today
		this.formGroup.get('lnk_delivery')?.get('askslot')?.setValue(null);
		this.formGroup.get('lnk_delivery')?.get('askop')?.setValue(null);
		this.niveauPrecisionhoraire = 0;
		this.is_valid();
	}

	onChangeSlotDay(event: MatButtonToggleChange) {		// Main slot: AM / PM => no precision / no operator
		this.askslotDayValue = event.value;
		this.niveauPrecisionhoraire = 0
		this.precisionControl1.setValue(false);
		this.precisionControl2.setValue(false);
		this.formGroup.get('lnk_delivery')?.get('askop')?.setValue(null);
		this.is_valid()
	}
	
	is_valid(): boolean {
		this.errorMessage = '';
		if (this.formGroup.get("lnk_delivery")?.get("mode")?.value ??1 == 1) {		// Mode 1 (take away) => always valid
			this.errorMessage = '';
		} else if (!this.formGroup.get('lnk_delivery')?.get('askdatdel')?.value) {
			this.errorMessage = 'Veuillez choisir une date';
		} else if ((this.formGroup.get('lnk_delivery')?.get('askslot')?.value ?? 0) < 1) {
			this.errorMessage = 'Veuillez sélectionner Matin ou Après Midi';
		} else if (this.precisionControl1.value && (this.formGroup.get('lnk_delivery')?.get('askop')?.value ?? 0) < 1) {
			this.errorMessage = 'Veuillez sélectionner Début, milieu, ou fin ou décocher horaire plus précis';
		} else if (this.precisionControl2.value) {
			if ((this.formGroup.get('lnk_delivery')?.get('askop')?.value ?? 0) < 4) {
				this.errorMessage = 'Veuillez sélectionner Avant, Après ou Horaire Fixe'
			} else if ((this.formGroup.get('lnk_delivery')?.get('askslot')?.value ?? 0) < 3) {
				this.errorMessage = 'Veuillez sélectionner un crénaux horaires'
			}
		}
		this.isValidable = this.errorMessage === '';
		return this.errorMessage === '';
	}
	
	ngOnDestroy() {
		this.submitEventsSubscription.unsubscribe();		// This prevents an event triggering on futur page loading
		this.loadOrderEventsSubscription.unsubscribe();
		this.ChangeformAskslotoperatorSubscription?.unsubscribe();
	}
	
	showDatedel() {
		if ((this.formGroup.get("lnk_delivery")?.get("mode")?.value == 1) || (this.formGroup.get("lnk_delivery")?.get("mode")?.value == 3)) {
			return false;	// FIXME false
		}
		return true;
	}
}


