import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, throwError, merge, EMPTY } from 'rxjs';
import { map, distinct, mapTo, catchError, retry } from 'rxjs/operators';
import { Event, Partner } from '@app/shared/_models/common';
import { Product, ProductTpl, TplPackaging, Packaging, ProductPackagingVal, ProductPackaging } from '@app/shared/_models/product';
import * as constants from "@app/shared/constants";

import { environment } from '@app/../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class InvoiceCreationService {

	constructor(private http: HttpClient) { }

	getInvoices(): Observable<any> {
		return this.http.get<any>(environment.server_url + "/sales/orderstoinvoice");
	}
	

/*
	getInvoicesAsFormGroup(): Observable<FormGroup> {
		return this.getInvoices().pipe(map((productTpl: ProductTpl) => {
			const fg = ProductTpl.asFormGroup(productTpl);
			return fg;
		}));
	}
	*/
}
