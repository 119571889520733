import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { NavItem } from './shared/nav-item';
import { MenuSalesComponent } from './shared/menu-sales/menu-sales.component';

import { environment } from '@app/../environments/environment';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { AlertComponent } from './shared/alert/alert.component';
import { MenuCommonComponent } from './shared/menu-common/menu-common.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MenuCommonComponent,
        AlertComponent,
        SpinnerComponent,
        RouterOutlet,
    ],
})

export class AppComponent implements OnInit {
	public showNavbar = true;
	public title: string;
	public currentUrl: string="/";
	public appVersion = environment.appVersion;

	constructor(private router: Router) {
		this.title = 'MercaLink';
		// router.events.subscribe((url: any) => console.log(url));
		/* ??
		router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				// this.currentUrl = e.url;									
				// this.showNavbar = (e.url === "/");
			}
		});
		*/
	}

	ngOnInit(): void {
	}
}
