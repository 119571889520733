<app-menu-common url="stock"></app-menu-common>

<ng-container *ngIf="formGroup">
	<div class="flexcolumn">
		<div class="pageheader flexcolumn" [formGroup]="formGroup">
			<div class="flexrow">
				<span class="darker">{{ getTitle() }}</span> <span class="spacer"></span> <span>#{{pinvc_id}}</span>
			</div>
	
			<div class="left">
				<div>
					<span class="label">Nom</span>&nbsp;<span>{{ formGroup.value.name }}</span>
				</div>
				<div>
					<span class="label">Magasin</span>&nbsp;<span>{{ formGroup.value.store }}</span>
				</div>
				<div class="flexrow">
					<span class="label">Date d'ouverture</span>&nbsp;<span>{{ formGroup.value.datstart | date:'dd/MM/yy':'Local' }}</span><span class="spacer"></span> <span>{{dataSource.data.length}}&nbsp;lignes</span>
				</div>
				<div class="flexrow" *ngIf="zones">
					<span class="label">Zone comptée</span>&nbsp;						
						<select [formControl]="currentzone" (change)="onZoneChange($event)">
							<option value="0">-</option>
							<option *ngFor="let z of zones" [value]="z.id">{{z.name}}</option>
						</select>
				</div>				
			</div>
		</div>
	
			<div class="inputdiv bglavander inputzone" *ngIf="currentzone && +currentzone.value !== 0">
				<input  autofocus [formControl]="fc_bc" (keydown)="onKeydownBC($event)"  [cdkTrapFocusAutoCapture]="!qtyfocus" [cdkTrapFocus]="!qtyfocus" #barcode> 
				<input [formControl]="fc_note" (keydown)="onKeydownNote($event)" *ngIf="(state==2)" (blur)="onBlurNote()" placeholder="Désignation libre" [cdkTrapFocusAutoCapture]="!qtyfocus" [cdkTrapFocus]="false">
				<input id="qty" [formControl]="fc_qty" (keydown)="onKeydownQty($event)" *ngIf="(state==1) || (state==2)" placeholder="Qté" [cdkTrapFocusAutoCapture]="qtyfocus" [cdkTrapFocus]="qtyfocus"> <!-- (blur)="onBlurQty()" -->
				<ng-container *ngIf="state==1">
					<button id="addbc" type="button" mat-flat-button color="primary" matTooltip="Ajouter" aria-label="Add" (click)="addPincl()">Ajouter</button>
					<span class="productname">{{ product_current?.name }}</span>					
				</ng-container>
				<span class="productname bcerror" *ngIf="state==2">Code barre inconnu. Saisir une désignation libre</span>
			</div>
	</div>
</ng-container>		

	<div class="flexcolumn lightborder" *ngIf="+currentzone.value !== 0">
	
		<div class="pagecontent" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
				
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef>id</th>
					<td mat-cell *matCellDef="let p"><span *ngIf="p.product!=0" class="productid">#{{ p.product }}</span><span *ngIf="p.product==0" class="noid">Inexistant</span></td>
				</ng-container>
				
				<ng-container matColumnDef="barcode">
					<th mat-header-cell *matHeaderCellDef >[code barre]</th>
					<td mat-cell *matCellDef="let p"><span class="darker" [class.noid]="p.product==0">[{{ p.barcode }}]</span></td>
					<td mat-cell *matCellDef="let p">[{{ p.barcode }}]</td>
				</ng-container>												
				
				<ng-container matColumnDef="productname">
					<th mat-header-cell *matHeaderCellDef>Produit</th>
					<td mat-cell *matCellDef="let p"><span *ngIf="p.product!=0">{{ p.lnk_product?.name}}</span><span *ngIf="p.product==0" class="noid">{{ p.note }}</span></td>
				</ng-container>
	
				<ng-container matColumnDef="amount">
					<th mat-header-cell *matHeaderCellDef>Qté</th>
					<td mat-cell *matCellDef="let p" class="amount">{{ p.amount}}</td>
				</ng-container>
				
				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef>Px. TTC</th>
					<td mat-cell *matCellDef="let p" [class.noprice]="!p.lnk_product?.price_taxi" [class.price]="p.lnk_product?.price_taxi">{{ p.lnk_product?.price_taxi}} <mat-icon *ngIf="!p.lnk_product?.price_taxi">warning</mat-icon></td>
				</ng-container>				
	
				<ng-container matColumnDef="datscan">
					<th mat-header-cell *matHeaderCellDef>Date/Heure scan</th>
					<td mat-cell *matCellDef="let p">{{ p.datscan | date:'dd/MM/yy HH:mm:ss':'Local'}}&nbsp;{{ p.lnk_user.firstname }}&nbsp;{{ p.lnk_user.lastname }}</td>
				</ng-container>
				
				<ng-container matColumnDef="user">
					<th mat-header-cell *matHeaderCellDef>Util.</th>
					<td mat-cell *matCellDef="let p">{{ p.user }}</td>
				</ng-container>
											
				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef>Action.</th>
					<td mat-cell *matCellDef="let p">
						<button mat-button color="primary" aria-label="Supprimer un code barre" (click)="deletePinvcl(p)"> <mat-icon>delete</mat-icon> </button>
	      	</td>
				</ng-container>		
	
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" [class.newrow]="row.indnew"></tr>
			</table>
			  <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons aria-label="Page">
  </mat-paginator>
		</div>
	</div>
