import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-variants-button',
    templateUrl: './variants-button.component.html',
    styleUrls: ['./variants-button.component.scss'],
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatIconModule]
})
export class VariantsButtonComponent {
  @Input() id: number | undefined = undefined;
}
