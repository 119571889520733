import { Component } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValuesService } from '@app/common/values/values.service';
import { CommonService } from '@app/common/common.service';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import * as constants from "@app/shared/constants";

@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCardModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule]
})
export class GeneralComponent {
	public salesUnit:any;
	public vat:any;
	public pformGroup!: FormGroup;	// Inherited from parent
	
	constructor(private valuesService: ValuesService, private commonService: CommonService, public controlContainer: ControlContainer) { }

	ngOnInit(): void {
		this.pformGroup = this.controlContainer.control as FormGroup;
		this.valuesService.getValues$(constants.C_VALUES_UOM)	// Get Sales Unit
			.subscribe((data: any) => {
				this.salesUnit = data;
			});

		this.commonService.getTaxes()	// Get Sales Unit
			.subscribe((data: any) => {
				this.vat = data;
			});
			
		console.log();
	}
}
